// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.round {
  position: relative;
  width: 25px;
  aspect-ratio: 1/1;
  margin: 2px 4px;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 25px;
  aspect-ratio: 1/1;
  left: 0;
  position: absolute;
  top: 0;
}

.round label.checkmark:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 6px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #d9b974;
  border-color: #d9b974;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/CheckBox/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,OAAO;EACP,kBAAkB;EAClB,MAAM;AACR;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,SAAS;EACT,UAAU;EACV,kBAAkB;EAClB,QAAQ;EACR,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".round {\n  position: relative;\n  width: 25px;\n  aspect-ratio: 1/1;\n  margin: 2px 4px;\n}\n\n.round label {\n  background-color: #fff;\n  border: 1px solid #ccc;\n  border-radius: 50%;\n  cursor: pointer;\n  height: 25px;\n  aspect-ratio: 1/1;\n  left: 0;\n  position: absolute;\n  top: 0;\n}\n\n.round label.checkmark:after {\n  border: 2px solid #fff;\n  border-top: none;\n  border-right: none;\n  content: \"\";\n  height: 6px;\n  left: 6px;\n  opacity: 0;\n  position: absolute;\n  top: 7px;\n  transform: rotate(-45deg);\n  width: 12px;\n}\n\n.round input[type=\"checkbox\"] {\n  visibility: hidden;\n}\n\n.round input[type=\"checkbox\"]:checked + label {\n  background-color: #d9b974;\n  border-color: #d9b974;\n}\n\n.round input[type=\"checkbox\"]:checked + label:after {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
