import "./style.css";

export default function AmountTag(props) {
  return (
    <div className="amount-tag space-between">
      <div className="name no-wrap absolute-center">{props.name}</div>
      <div className="amount no-wrap absolute-center">x {props.amount}</div>
    </div>
  );
}
