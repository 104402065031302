import React, { useState, useEffect, useRef } from "react";
import {
  SearchField,
  DatePickerSmall,
  ToggleRadioWithText,
  LoadingDiv,
  ReloadOption,
  ToReadableDate,
  CalcTimeDifference,
  TableRowSeparator,
  shorten_product_list,
  SummaryTable,
  toName,
  replaceCharacter,
  ButtonWithIcon,
  ButtonFlat,
  LabeledCheckBox,
} from "../components";
import "bootstrap/dist/css/bootstrap.css";
import * as IconsBS from "react-icons/bs";

import axios from "axios";
import moment from "moment";
import randomColor from "randomcolor";

import { BASE_SERVER_URL } from "../sampleData";
import { FaBoxOpen, FaFlag, FaUser } from "react-icons/fa";
import { AiFillPrinter } from "react-icons/ai";
import { MdAdd, MdPeople, MdTask } from "react-icons/md";
import { GrTools } from "react-icons/gr";
import AmountTag from "../components/AmountTag";
import StaffDisplay from "../components/StaffDisplay";
import { isArraySubset, removeFromArray } from "../utils";
import { PopUpAlert } from "../components/movableDragDropArea";
import RoundCheckBox from "../components/CheckBox";
import FlowerContentList from "../components/FlowerContentList";
import { useOutsideAlerter } from "../script";
import { IoMdFlower } from "react-icons/io";

export function OrderedProducts(props) {
  document.title = "Ordered Products";
  const [orders, setOrders] = React.useState({ Funeral: [], Retail: [] });
  const [counts, setCounts] = React.useState({});
  const [typeCounts, setTypeCounts] = React.useState({
    "Casket cover": 0,
    Tributes: 0,
    Bowl: 0,
  });

  //filters
  const [listDate, setListDate] = React.useState(moment().format("DD/MM/YYYY"));

  //loadings

  const [assignTask, setAssignTask] = useState(false);
  const [checkProgress, setCheckProgress] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [allocatedTasks, setAllocatedTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState({});
  const [listIsLoading, setListIsLoading] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [showFlowers, setShowFlowers] = useState(false);
  const [flowers, setFlowers] = useState({});

  const [colSpan, setColSpan] = useState(8);
  const [alert, setAlert] = useState({});

  const elementRef = useRef();

  React.useEffect(() => {
    refreshList();
  }, [listDate]);

  React.useEffect(() => {
    if (checkProgress) {
      setAssignTask(false);
    }
    setColSpan(checkProgress ? 11 : 8);
  }, [checkProgress]);

  React.useEffect(() => {
    if (assignTask) {
      setCheckProgress(false);
    }
    setColSpan(assignTask ? 9 : 8);
  }, [assignTask]);

  useOutsideAlerter(elementRef, () => {
    setShowFlowers(false);
  });
  React.useEffect(() => {
    axios
      .get("https://app.raysflorist.work/ladjcauenackdnland")
      .then((doc) => {});

    let url = BASE_SERVER_URL + "/users/getAll";
    setLoadingUsers(true);
    axios
      .get(url)
      .then((response) => {
        setUsers(response.data.response);
        setLoadingUsers(false);
      })
      .catch(() => {});
  }, []);

  function AssignTask2() {
    setAllocatedTasks((prev) => {
      let newObj = [...prev];
      newObj.push({
        assignedUser: selectedStaff,
        selected_tasks: selectedTasks,
      });
      return newObj;
    });
  }

  function AssignTask(remove = false, callback) {
    let data = {
      assigning: !remove,
      user: remove
        ? {}
        : {
            assigned_to: selectedStaff,
            start: String(Date.now()),
            ended: "",
            checked_1: "",
            checked_2: "",
            checked_3: "",
          },
      ordered_product_ids: selectedTasks,
    };
    let url = BASE_SERVER_URL + "/wo/assignOrderedProducts";
    setAssignLoading(true);
    axios.post(url, data).then((doc) => {
      if (doc.data.status === "success") {
        setAssignLoading(false);
        setSelectedStaff({});
        setSelectedTasks([]);
        refreshList();
        // setAssignTask(false);
      }
      callback();
    });
  }

  function refreshList() {
    setListIsLoading(true);
    // let url = BASE_SERVER_URL + "/wo/getOrderedProducts";
    let url = BASE_SERVER_URL + "/ordered-products/by-date-range";

    axios
      .post(url, {
        dateRange: { startDate: listDate, endDate: listDate },
        mergeOrders: true,
      })
      // .get(url)
      .then((doc) => {
        if (doc.data.status === "success") {
          setListIsLoading(false);
          setOrders(doc.data.body.products);
          setFlowers(doc.data.body.flowerCount);
          setCounts(doc.data.body.OrderCountByType.list);
          setTypeCounts(doc.data.body.TypeCount);
        }
      })
      .catch(function (error) {});
  }

  return (
    <div className="data-input center-ver-col">
      <FlowerContentList
        date={listDate}
        referece={elementRef}
        flowers={flowers}
        show={showFlowers}
      />
      {Object.keys(alert).length > 0 && (
        <PopUpAlert
          info={alert}
          onClose={() => {
            setAlert({});
          }}
        />
      )}
      <div className="width-95">
        <div className="width-100 margin-10 center-hor">
          <DatePickerSmall
            value={listDate}
            onChageSetter={(a) => {
              setListDate(ToReadableDate(a.value, [], "/"));
            }}
            name="funeral_date"
            label="Date"
            addClass="margin-left-10"
            icon={<IconsBS.BsCalendarFill className="icon grey" />}
            placeHolder="Date"
            light={true}
            noClearButton={true}
          />
          <ButtonWithIcon
            addClass="width-auto margin-0 stretch-height font-small thin margin-left-10"
            value="Content Flowers"
            iconWidth="40px"
            borderRad="3px"
            icon={<IoMdFlower className="icon XL white width-100" />}
            clickTo={() => {
              setShowFlowers((prev) => !prev);
            }}
          />
          <ButtonWithIcon
            addClass="width-auto margin-0 stretch-height font-small thin margin-left-10"
            value="Print List"
            iconWidth="40px"
            borderRad="3px"
            icon={<AiFillPrinter className="icon XL white width-100" />}
            clickTo={() => {
              window.open(
                "/ordered-products/print/" +
                  replaceCharacter(listDate || "", "/", "-"),
                "_blank"
              );
            }}
          />
          <ButtonWithIcon
            addClass={
              "width-auto margin-0 stretch-height font-small thin margin-left-10 " +
              (assignTask ? "green" : "")
            }
            value="Assign Task"
            iconWidth="40px"
            borderRad="3px"
            icon={<MdTask className="icon XL white width-100" />}
            clickTo={() => {
              setAssignTask((prev) => !prev);
            }}
          />
          <ButtonWithIcon
            addClass={
              "width-auto margin-0 stretch-height font-small thin margin-left-10 " +
              (checkProgress ? "tile" : "")
            }
            value="Check Task Progresses"
            iconWidth="40px"
            borderRad="3px"
            icon={<MdTask className="icon XL white width-100" />}
            clickTo={() => {
              setCheckProgress((prev) => !prev);
            }}
          />
          <span className="bold-1 font-big padding-10">Ordered Products</span>
        </div>
        <div className="width-100 space-between flex-start-ver">
          <div
            className="width-80 xlight-box-shadow "
            style={{ minHeight: "700px" }}
          >
            <table
              style={{ borderRadius: "8px", overflow: "" }}
              className="table table-striped"
            >
              <thead>
                <tr className="bt_table ">
                  <th scope="col" style={{ borderTopLeftRadius: "8px" }}>
                    #
                  </th>
                  <th scope="col">Type</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Size</th>
                  <th scope="col">Amount</th>
                  {!checkProgress
                    ? [
                        <th scope="col">Price</th>,
                        <th scope="col" style={{ minWidth: "40%" }}>
                          Content
                        </th>,
                      ]
                    : ""}
                  <th
                    scope="col"
                    className="no-wrap"
                    style={
                      !assignTask && !checkProgress
                        ? { borderTopRightRadius: "8px" }
                        : {}
                    }
                  >
                    Assigned To
                  </th>
                  {checkProgress
                    ? [
                        <th scope="col" className="progress-fields">
                          Start Time
                        </th>,
                        <th scope="col" className="progress-fields">
                          Check 1
                        </th>,
                        <th scope="col" className="progress-fields">
                          Check 2
                        </th>,
                        <th scope="col" className="progress-fields">
                          Check 3
                        </th>,
                        <th scope="col" className="progress-fields">
                          End Time
                        </th>,
                      ]
                    : ""}
                  {assignTask ? (
                    <th
                      scope="col"
                      style={{ borderTopRightRadius: "8px" }}
                    ></th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td
                    colSpan={20}
                    className="font-large text-center bold-1 color-gold"
                  >
                    Funeral
                  </td>
                </tr>

                {listIsLoading ? (
                  <tr>
                    <td colSpan={20} className="text-center">
                      <LoadingDiv
                        amount={0}
                        speed={2}
                        height="300px"
                        style={{ width: "100%", margin: "0px" }}
                        innerStyle={{ margin: "0px", width: "100%" }}
                      />
                    </td>
                  </tr>
                ) : (
                  Object.keys(orders.Funeral || {}).map((funeral_ID, at) => {
                    // let randomC = randomColor();
                    let products = orders.Funeral[funeral_ID];
                    let funeralIds = products.map((one) => {
                      return one._id;
                    });
                    return products.map((each, index) => {
                      return [
                        index === 0 ? (
                          <tr>
                            <td
                              className="font-small bold-1 color-gold"
                              colspan={colSpan - 1}
                            >
                              {at + 1}{" "}
                              {each.parent_work_order.late_person_name || ""}
                            </td>
                            <td>
                              {assignTask ? (
                                <LabeledCheckBox
                                  label="All in this Funeral"
                                  checked={isArraySubset(
                                    funeralIds,
                                    selectedTasks
                                  )}
                                  setter={() => {
                                    setSelectedTasks((prev) => {
                                      if (
                                        isArraySubset(funeralIds, selectedTasks)
                                      ) {
                                        let selected = [...prev];
                                        funeralIds.forEach((a) => {
                                          selected = removeFromArray(
                                            a,
                                            selected
                                          );
                                        });
                                        return selected;
                                      } else {
                                        let selected = [...prev, ...funeralIds];

                                        return selected;
                                      }
                                    });
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ) : (
                          ""
                        ),
                        <tr key={index} className="relative">
                          <th scope="row">{index + 1}</th>
                          <td className="no-wrap">{each.product_type}</td>
                          <td className="">
                            <span className="width-100 block-display">
                              {each.product_name}
                            </span>
                            {each.comment ? (
                              <span className="width-100 font-small color-gold">
                                Comment: {each.comment || ""}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="no-wrap">{toName(each.size)}</td>
                          <td>{each.amount}</td>
                          {!checkProgress
                            ? [
                                <td>
                                  $
                                  {each.customPrice
                                    ? each.customPrice
                                    : each.price}
                                </td>,
                                <td className="font-small">
                                  {Object.keys(
                                    JSON.parse(each.content || "{}")[
                                      each.size
                                    ] || {}
                                  ).map((roses, a) => {
                                    return (
                                      <AmountTag
                                        name={roses}
                                        amount={
                                          JSON.parse(each.content || "{}")[
                                            each.size
                                          ][roses]
                                        }
                                      />
                                    );
                                  })}
                                </td>,
                              ]
                            : ""}
                          <td>
                            {(each.orderProgress || []).length ? (
                              <span className="center-ver-col">
                                <div className="" style={{ marginTop: "3px" }}>
                                  <FaUser className="icon grey large border-grey circle" />
                                </div>
                                <span className="width-100 text-center font-small bold-1">
                                  {
                                    (each.orderProgress || [])[0].assigned_to
                                      .full_name
                                  }
                                </span>
                              </span>
                            ) : (
                              <span
                                className="font-small color-red center-hor no-wrap touchable"
                                onClick={() => {
                                  setAssignTask(true);
                                  setSelectedTasks([each._id]);
                                }}
                              >
                                <FaFlag className="icon small red" />
                                Not Assigned
                              </span>
                            )}
                          </td>
                          {checkProgress
                            ? [
                                <td className="font-small">
                                  {((each.orderProgress || [])[0] || {}).start
                                    ? [
                                        <span className="width-100 flex">
                                          {moment(
                                            Number(
                                              (
                                                (each.orderProgress || [])[0] ||
                                                {}
                                              ).start
                                            )
                                          ).format("MMM Do")}
                                        </span>,
                                        <span className="width-100 flex">
                                          {moment(
                                            Number(
                                              (
                                                (each.orderProgress || [])[0] ||
                                                {}
                                              ).start
                                            )
                                          ).format("h:mm a")}
                                        </span>,
                                      ]
                                    : ""}
                                </td>,
                                <td className="font-small">
                                  {
                                    ((each.orderProgress || [])[0] || {})
                                      .checked_1
                                  }
                                </td>,
                                <td className="font-small">
                                  {
                                    ((each.orderProgress || [])[0] || {})
                                      .checked_2
                                  }
                                </td>,
                                <td className="font-small">
                                  {
                                    ((each.orderProgress || [])[0] || {})
                                      .checked_3
                                  }
                                </td>,
                                <td className="font-small">
                                  {((each.orderProgress || [])[0] || {}).ended
                                    ? [
                                        <span className="width-100">
                                          {moment(
                                            Number(
                                              (
                                                (each.orderProgress || [])[0] ||
                                                {}
                                              ).ended
                                            )
                                          ).format("MMMM Do")}
                                        </span>,
                                        <span className="width-100">
                                          {moment(
                                            Number(
                                              (
                                                (each.orderProgress || [])[0] ||
                                                {}
                                              ).ended
                                            )
                                          ).format("h:mm a")}
                                        </span>,
                                      ]
                                    : ""}
                                </td>,
                              ]
                            : ""}
                          {assignTask ? (
                            <td>
                              <LabeledCheckBox
                                checked={selectedTasks.includes(each._id)}
                                setter={() => {
                                  setSelectedTasks((prev) => {
                                    if (selectedTasks.includes(each._id)) {
                                      let selected = [...prev];
                                      return removeFromArray(
                                        each._id,
                                        selected
                                      );
                                    } else {
                                      let selected = [...prev];
                                      selected.push(each._id);
                                      return selected;
                                    }
                                  });
                                }}
                              />
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>,
                      ];
                    });
                  })
                )}
                <tr>
                  <td
                    colSpan={20}
                    className="font-large text-center bold-1 color-gold"
                  >
                    Retail
                  </td>
                </tr>
                {listIsLoading ? (
                  <tr>
                    <td colSpan={20} className="text-center">
                      <LoadingDiv
                        amount={0}
                        speed={2}
                        height="300px"
                        style={{ width: "100%", margin: "0px" }}
                        innerStyle={{ margin: "0px", width: "100%" }}
                      />
                    </td>
                  </tr>
                ) : (
                  (orders.Retail || {}).map((each, index) => {
                    return each.day_count === 0 ? (
                      ""
                    ) : (
                      <tr key={index} className="relative">
                        <th scope="row">{index + 1}</th>
                        <td className="no-wrap">{each.product_type}</td>
                        <td className="">
                          <span className="width-100 block-display">
                            {each.product_name}
                          </span>
                          {each.comment ? (
                            <span className="width-100 font-small color-gold">
                              Comment: {each.comment || ""}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="no-wrap">{toName(each.size)}</td>
                        {/* <td>{each.frequency} {each.delivery_day }</td> */}
                        <td>{each.amount}</td>
                        {!checkProgress
                          ? [
                              <td>
                                $
                                {each.customPrice
                                  ? each.customPrice
                                  : each.price}
                              </td>,
                              <td
                                className={
                                  Object.keys(
                                    JSON.parse(each.content || "{}")[
                                      each.size
                                    ] || {}
                                  ).length
                                    ? "flex"
                                    : ""
                                }
                              >
                                {Object.keys(
                                  JSON.parse(each.content || "{}")[each.size] ||
                                    {}
                                ).map((roses, a) => {
                                  return (
                                    <AmountTag
                                      name={roses}
                                      amount={
                                        JSON.parse(each.content || "{}")[
                                          each.size
                                        ][roses]
                                      }
                                    />
                                  );
                                })}
                              </td>,
                            ]
                          : ""}
                        <td>
                          {(each.orderProgress || []).length ? (
                            <span className="center-ver-col">
                              <div className="" style={{ marginTop: "3px" }}>
                                <FaUser className="icon grey large border-grey circle" />
                              </div>
                              <span className="width-100 text-center font-small bold-1">
                                {
                                  (each.orderProgress || [])[0].assigned_to
                                    .full_name
                                }
                              </span>
                            </span>
                          ) : (
                            <span className="font-small color-red center-hor no-wrap">
                              <FaFlag className="icon small red" />
                              Not Assigned
                            </span>
                          )}
                        </td>
                        {assignTask ? (
                          <td>
                            <LabeledCheckBox
                              // checkBoxIcon={<MdAdd className="icon small" />}
                              checked={selectedTasks.includes(each._id)}
                              setter={() => {
                                setSelectedTasks((prev) => {
                                  if (selectedTasks.includes(each._id)) {
                                    let selected = [...prev];
                                    return removeFromArray(each._id, selected);
                                  } else {
                                    let selected = [...prev];
                                    selected.push(each._id);
                                    return selected;
                                  }
                                });
                              }}
                            />
                          </td>
                        ) : (
                          ""
                        )}
                        {checkProgress
                          ? [
                              <td className="font-small">
                                {((each.orderProgress || [])[0] || {}).start
                                  ? [
                                      <span className="width-100 flex">
                                        {moment(
                                          Number(
                                            (
                                              (each.orderProgress || [])[0] ||
                                              {}
                                            ).start
                                          )
                                        ).format("MMM Do")}
                                      </span>,
                                      <span className="width-100 flex">
                                        {moment(
                                          Number(
                                            (
                                              (each.orderProgress || [])[0] ||
                                              {}
                                            ).start
                                          )
                                        ).format("h:mm a")}
                                      </span>,
                                    ]
                                  : ""}
                              </td>,
                              <td className="font-small">
                                {
                                  ((each.orderProgress || [])[0] || {})
                                    .checked_1
                                }
                              </td>,
                              <td className="font-small">
                                {
                                  ((each.orderProgress || [])[0] || {})
                                    .checked_2
                                }
                              </td>,
                              <td className="font-small">
                                {
                                  ((each.orderProgress || [])[0] || {})
                                    .checked_3
                                }
                              </td>,
                              <td className="font-small">
                                {((each.orderProgress || [])[0] || {}).ended
                                  ? [
                                      <span className="width-100">
                                        {moment(
                                          Number(
                                            (
                                              (each.orderProgress || [])[0] ||
                                              {}
                                            ).ended
                                          )
                                        ).format("MMMM Do")}
                                      </span>,
                                      <span className="width-100">
                                        {moment(
                                          Number(
                                            (
                                              (each.orderProgress || [])[0] ||
                                              {}
                                            ).ended
                                          )
                                        ).format("h:mm a")}
                                      </span>,
                                    ]
                                  : "WIP"}
                              </td>,
                            ]
                          : ""}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <div className="width-20 light-card" style={{ marginLeft: "25px" }}>
            {assignTask ? (
              loadingUsers ? (
                <LoadingDiv height="400px" />
              ) : (
                <div
                  className="width-100 xlight-box-shadow "
                  style={{ marginBottom: "5px" }}
                >
                  <div className="bold-1 width-100 wedget-header">
                    Allocate Task
                  </div>
                  <div style={{ minHeight: "300px" }} className="relative">
                    <div
                      hidden={true}
                      className="width-100 font-small header-small"
                    >
                      Allocated Tasks
                    </div>
                    <div hidden={true} className="width-100">
                      {allocatedTasks.length === 0 ? (
                        <p className="width-100 text-center">
                          No task allocated
                        </p>
                      ) : (
                        allocatedTasks.map((each) => {
                          return (
                            <div
                              className="width-100 space-between"
                              style={{ height: "30px" }}
                            >
                              <span className="width-80 center-hor">
                                <span
                                  className="bg-gold absolute-center"
                                  style={{
                                    width: "30px",
                                    marginRight: "3px",
                                    height: "30px",
                                    background: "#61c26d",
                                  }}
                                >
                                  <GrTools className="icon mid white" />
                                </span>
                                {(each.assignedUser || {}).full_name}
                              </span>
                              <span className="width-10">
                                {(each.selected_tasks || []).length}
                              </span>
                            </div>
                          );
                        })
                      )}
                    </div>
                    <div className="width-100 font-small header-small">
                      Staff List
                    </div>
                    <div
                      className="width-100 thin-red-scrollbar"
                      style={{ height: "250px", overflowY: "scroll" }}
                    >
                      {(users || []).map((each) => {
                        return (
                          <StaffDisplay
                            user={each}
                            selectedStaff={selectedStaff}
                            onSet={(a) => {
                              setSelectedStaff(a);
                            }}
                          />
                        );
                      })}
                    </div>
                    <div
                      className="width-100 center-hor float-bottoms bg-white"
                      style={{
                        padding: "3px 0px 5px 0px",
                        boxShadow: "0px -3px 7px rgba(0,0,0,0.05)",
                      }}
                    >
                      <div className="width-50">
                        <ButtonFlat
                          dotColor="#eaeaea"
                          addClass="font-small thin-flat width-100 light"
                          disabled={
                            selectedTasks.length === 0 ||
                            Object.keys(selectedStaff).length === 0
                              ? "disabled"
                              : ""
                          }
                          style={{
                            background: "#eaeaea",
                            width: "100%",
                            height: "25px",
                          }}
                          loading={false}
                          value="Allocate Task"
                          clickTo={() => {
                            setAlert({
                              title: "Confirm Task Allocation",
                              message:
                                "Are you sure you want to allocate " +
                                selectedTasks.length +
                                " tasks to " +
                                selectedStaff.full_name +
                                "?",
                              confirmPrompt: true,
                              confirmLoading: assignLoading,
                              type: "success",
                              callback: () => {
                                AssignTask(false, () => {
                                  setAlert({});
                                });
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="width-50">
                        <ButtonFlat
                          dotColor="#eaeaea"
                          disabled={
                            selectedTasks.length === 0 ? "disabled" : ""
                          }
                          addClass="font-small thin-flat width-100"
                          style={{
                            background: "#f03e4e",
                            color: "#fff",
                            width: "100%",
                            height: "25px",
                          }}
                          loading={false}
                          value="Deallocate Task"
                          clickTo={() => {
                            setAlert({
                              title: "Confirm Task Unallocation",
                              message:
                                "Are you sure you want to unallocate " +
                                selectedTasks.length +
                                " tasks?",
                              confirmPrompt: true,
                              confirmLoading: assignLoading,
                              type: "error",
                              callback: () => {
                                AssignTask(true, () => {
                                  setAlert({});
                                });
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            ) : (
              ""
            )}
            <div className="width-100 xlight-box-shadow">
              <div className="bold-1 width-100 wedget-header">
                Count Summary
              </div>
              <div className="width-100 font-small header-small">
                Product Type
              </div>
              {listIsLoading ? (
                <LoadingDiv amount={0} speed={2} height="300px" />
              ) : (
                <SummaryTable
                  data={counts || {}}
                  addClass={"flat font-mid"}
                  outOfNameList={true}
                />
              )}
              <div className="width-100 font-small header-small">
                Order Type
              </div>
              {listIsLoading ? (
                <LoadingDiv amount={0} speed={2} height="330px" />
              ) : (
                <SummaryTable
                  data={typeCounts || {}}
                  addClass={"flat font-mid"}
                  outOfNameList={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
