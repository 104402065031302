// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.amount-tag > .amount {
  background: rgb(59, 153, 230);
  color: white;
  margin-left: 5px;
}
.amount-tag > div {
  padding: 2px 8px;
  text-align: center;
}
.amount-tag {
  padding: 0px;
  /* width: 150px; */
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgb(223, 223, 223);
  font-size: var(--font-small);
  margin: 0px 3px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 3px;
}
.flex {
  display: flex;
}
.block-display {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/AmountTag/style.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,oCAAoC;EACpC,4BAA4B;EAC5B,eAAe;EACf,uBAAkB;EAAlB,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,aAAa;AACf;AACA;EACE,cAAc;AAChB","sourcesContent":[".amount-tag > .amount {\n  background: rgb(59, 153, 230);\n  color: white;\n  margin-left: 5px;\n}\n.amount-tag > div {\n  padding: 2px 8px;\n  text-align: center;\n}\n.amount-tag {\n  padding: 0px;\n  /* width: 150px; */\n  border-radius: 10px;\n  overflow: hidden;\n  border: 1px solid rgb(223, 223, 223);\n  font-size: var(--font-small);\n  margin: 0px 3px;\n  width: fit-content;\n  margin-bottom: 3px;\n}\n.flex {\n  display: flex;\n}\n.block-display {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
