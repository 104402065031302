import { MdTableView } from "react-icons/md";
import { exportToExcel } from "react-json-to-excel";
import moment from "moment";
export default function ExportTools(props) {
  let date = moment(new Date()).format("DD-MM-YYYY");
  return (
    <div
      className="center-hor"
      onClick={() => {
        exportToExcel(
          props.jsonExport || [],
          props.exportName || "raysflorist_ordered_products_" + date
        );
      }}
    >
      <span className="touchable padding-10 center-hor">
        <MdTableView
          className={"icon mid " + props.addIconClass}
          onClick={() => {}}
        />
        <span className={"no-wrap font-small bold-1 " + props.addLableClass}>
          Export Excel
        </span>
      </span>
    </div>
  );
}
