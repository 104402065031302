import { useEffect, useState } from "react";
import "./style.css";
import ExportTools from "../ExportTools";
import { LoadingDiv, TextInputField } from "../../components";
import { BASE_SERVER_URL } from "../../sampleData";
import axios from "axios";
import { addCommasToNumber } from "../../utils";

const grouper = {
  product_type: "Product Type",
  code: "Product Code",
  funeral_director: "Funeral Director",
};
export default function ProductReveneueDetailCard(props) {
  const [active, setActive] = useState("product_type");
  const [loading, setLoading] = useState(false);
  const [groupedList, setGroupedList] = useState(props.groupedlist || []);

  function refreshList() {
    setGroupedList([]);
    setLoading(true);
    let url = BASE_SERVER_URL + "/ordered-products/by-group";
    let data = {
      ...props.dateRange,
      group_by: active,
    };
    axios.post(url, data).then((response) => {
      setGroupedList(response.data.data);
      setLoading(false);
    });
  }

  useEffect(() => {
    // refreshList();
  }, [active]);

  return (
    <div
      className="date-range-picker width-100"
      // hidden="true"
    >
      <div className="width-100 space-between center-hor">
        <h5>Group Revenue</h5>
        <ExportTools exportName={"grouped-revenue"} jsonExport={groupedList} />
      </div>
      <div className="width-100">
        <div className="hor-list-selector center-hor">
          {["product_type", "code"].map((each, index) => {
            return (
              <span
                className={"flex-grow " + (each === active ? "active" : "")}
                key={index}
                onClick={() => {
                  setActive(each);
                }}
              >
                {grouper[each]}
              </span>
            );
          })}
        </div>
        {/* <TextInputField placeHolder="Type" /> */}

        <div className="width-100" style={{ marginTop: "10px" }}>
          {loading ? (
            <LoadingDiv
              amount={0}
              speed={2}
              height={"300px"}
              style={{ width: "100%" }}
            />
          ) : (
            ""
          )}
          <table
            hidden={loading}
            style={{ borderRadius: "8px", overflow: "" }}
            className={"table " + (loading ? "table-striped" : "")}
          >
            <thead>
              <tr className="relative font-small small-table-row">
                <th scope="col" className="text-left">
                  #
                </th>
                <th scope="col" className="text-left">
                  Product Type
                </th>
                <th scope="col" className="text-left">
                  Count
                </th>
                <th scope="col" className="text-left">
                  Profit
                </th>
              </tr>
            </thead>

            <tbody>
              {(groupedList || []).map((each, index) => {
                return (
                  <tr className="relative font-small small-table-row">
                    <td scope="col" className="text-left">
                      {index + 1}
                    </td>
                    <td scope="col" className="text-left">
                      {each._id}
                    </td>
                    <td scope="col" className="text-left">
                      {each.count}
                    </td>
                    <td scope="col" className="text-left">
                      ${addCommasToNumber(each.totalProfit)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
