import { useState, useEffect } from "react";
import {
  ProductTypeSelectorList,
  TextInputField,
  DropDown,
  FD_DropDown,
  TextInputFieldWithRelatedResult,
  DatePicker,
  LabeledCheckBox,
  ButtonWithIcon,
  OrdersPreSummaryTable,
  PriceTag,
  OverLayInputSummary,
  orderObject,
  PageLoading,
  LocationInputField,
} from "./components";
import "bootstrap/dist/css/bootstrap.css";
import { BsCalendarFill } from "react-icons/bs";
import {
  AiOutlinePlus,
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
} from "react-icons/ai";
import axios from "axios";
import { BASE_SERVER_URL } from "./sampleData";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { useParams } from "react-router-dom";
import { PopUpAlert } from "./components/movableDragDropArea";

export function FuneralInputPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  document.title = props.title || "Ray`s Florist App";
  // const [products, setProducts] = useState([])

  const [orderDetailJSON, setOrderDetailJSON] = useState("");
  const [orderDetail, setOrderDetail] = useState({
    order: {
      late_person_name: "",
    },
    price_detail: {},
    datetime: {
      funeral_date: "",
      funeral_time: "",
      funeral_location: "",
    },
    fd_code: { ["FD Code"]: { code: "" } },
  });
  const [selectedFD, setSelectedFD] = useState({});
  const [settings, setSettings] = useState({});
  const [pageLoading, setPageLoading] = useState(props.editWo ? true : false);
  const [order, setOrder] = useState({});
  const [deliveryFee, setDeliveryFee] = useState({});

  useEffect(() => {
    if (props.editWo) {
      populate(id);
    }
    if (Object.keys(settings).length === 0) {
      let url = BASE_SERVER_URL + "/settings/getAll";
      axios
        .get(url)
        .then((doc) => {
          if (doc.data.status === "success") {
            setSettings(doc.data.response[0]);
          }
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    setProperty(
      { name: "gst", value: (Number(settings.gst) || 0) / 100 },
      "price_detail"
    );
  }, [settings]);

  useEffect(() => {
    console.log("delivery changed ", deliveryFee);
    setProperty(
      { name: "delivery_fee", value: deliveryFee.delivery_fee || "" },
      "price_detail"
    );
    setProperty(
      {
        name: "custom_delivery_fee",
        value: deliveryFee.custom_delivery_fee || "",
      },
      "price_detail"
    );
  }, [deliveryFee]);

  function populate(id) {
    let url = BASE_SERVER_URL + "/wo/getBy";

    axios
      .post(url, { filter: { _id: id } })
      .then((doc) => {
        let dataRaw = doc.data.response[0];
        let data = orderObject(doc.data.response[0], "Funeral");
        data.fd_code = {
          ["FD Code"]: JSON.parse(doc.data.response[0].fd_code),
        };
        setDeliveryFee({
          delivery_fee: data.price_detail.delivery_fee,
          custom_delivery_fee: data.price_detail.custom_delivery_fee,
        });
        setOrderDetail(data);
        setOrder(data.order);
        if (doc.data.response[0].parent_funeral_id !== "") {
          setSelectedParentOrder({
            fd_code: doc.data.response[0].fd_code,
            late_person_name: doc.data.response[0].late_person_name,
            funeral_location: doc.data.response[0].funeral_location,
            funeral_date: doc.data.response[0].funeral_date,
            funeral_time: doc.data.response[0].funeral_time,
          });
        }

        let cc_loc = "";
        let tri_loc = "";

        if (
          dataRaw.casket_cover_delivery_location === dataRaw.funeral_location
        ) {
          // set_cc_delivery_loc_choice("Funeral Location");
          cc_loc = data.datetime.funeral_location;
        } else if (
          dataRaw.casket_cover_delivery_location ===
          JSON.parse(dataRaw.fd_code || "{}").cc_delivery_location
        ) {
          // set_cc_delivery_loc_choice("Funeral Director");
          cc_loc = data.fd_code["FD Code"].cc_delivery_location || "";
        }

        if (dataRaw.tribuite_delivery_location === dataRaw.funeral_location) {
          // set_tri_delivery_loc_choice("Funeral Location");
          tri_loc = data.datetime.funeral_location;
        } else if (
          dataRaw.tribuite_delivery_location ===
          JSON.parse(dataRaw.fd_code || "{}").cc_delivery_location
        ) {
          // set_tri_delivery_loc_choice("Funeral Director");
          tri_loc = data.fd_code["FD Code"].cc_delivery_location;
        }
        // setProperty(
        //   {
        //     name: "delivery_fee",
        //     value: Number(
        //       doc.data.response[0].price_detail.delivery_fee || ""
        //     ).toFixed(2),
        //   },
        //   "price_detail"
        // );
        setProperty(
          {
            name: "casket_cover_delivery_time",
            value: data.datetime.casket_cover_delivery_time,
          },
          "datetime"
        );
        setProperty(
          { name: "casket_cover_delivery_location", value: cc_loc },
          "datetime"
        );
        setProperty(
          { name: "tribuite_delivery_location", value: tri_loc },
          "datetime"
        );
      })
      .finally(() => {
        setPageLoading(false);
        // let url =  BASE_SERVER_URL+'/wo/getProductsByOrderId/'+id
        // axios.get(url).then((res)=>{
        //     // console.log('response products - first',url,res.data.response)
        //     setSelectedProducts((res.data.response || []).filter((each)=>{ return each.product_type === 'Casket cover'}))
        //     setSelectedTribuites((res.data.response || []).filter((each)=>{ return each.product_type !== 'Casket cover'}))
        //     setPageLoading(false)
        // })
      });
  }

  useEffect(() => {
    setProperty({ name: "FD Code", value: selectedFD }, "fd_code");
  }, [selectedFD]);

  const [orderType, setOrderType] = useState(props.orderType);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductPreView, setSelectedProductPreView] = useState({});
  const [selectedTribuites, setSelectedTribuites] = useState([]);
  const [selectedParentOrder, setSelectedParentOrder] = useState([]);
  const [selectedParentOrderID, setSelectedParentOrderID] = useState("");

  const gst = 0.1;

  const [cc_delivery_loc_choice, set_cc_delivery_loc_choice] = useState("");
  const [tri_delivery_loc_choice, set_tri_delivery_loc_choice] = useState("");

  useEffect(() => {
    let sum = 0;
    [...selectedProducts, ...selectedTribuites]
      .filter((val) => {
        return !val.deleted;
      })
      .map((x) => {
        let price = x.customPrice ? x.customPrice : x.price;
        let sashPrice = x.sashPrice ? x.sashPrice : 0;
        sum += Number(price * x.amount) + Number(sashPrice);
      });
    setProperty({ name: "total_price", value: sum }, "price_detail");

    setOrderDetail((prev) => {
      let newObj = JSON.parse(JSON.stringify(prev));
      newObj.selectedProducts = selectedProducts;
      newObj.selectedTribuites = selectedTribuites;
      newObj.totalPrice = sum;
      // console.log("order detail state change asd dasdfasdfae", prev, newObj);
      return newObj;
    });
    setOrderDetailJSON((prev) => {
      let newObj = {};
      newObj.type = orderType;
      newObj.received_by = JSON.stringify(props.current_user);
      newObj.orderDetail = JSON.stringify(orderDetail);
      newObj.selectedProducts = JSON.stringify(selectedProducts);
      newObj.selectedTribuites = JSON.stringify(selectedTribuites);
      newObj.selectedParentOrderID = selectedParentOrderID;
      newObj.totalPrice = sum;
      newObj.totalPriceGST = (sum * (1 + gst)).toFixed(2);
      newObj.price_detail = { ...orderDetail.price_detail };

      return newObj;
    });
  }, [selectedProducts, selectedTribuites, selectedParentOrder]);

  useEffect(() => {
    let sum = 0;
    [...selectedProducts, ...selectedTribuites].map((x) => {
      let price = x.customPrice ? x.customPrice : x.price;
      let sashPrice = x.sashPrice ? x.sashPrice : 0;
      sum += price * x.amount + sashPrice;
    });
    setOrderDetailJSON((prev) => {
      let newObj = {};
      newObj.type = orderType;
      newObj.received_by = JSON.stringify(props.current_user);
      newObj.orderDetail = JSON.stringify(orderDetail);
      newObj.selectedProducts = JSON.stringify(selectedProducts);
      newObj.selectedTribuites = JSON.stringify(selectedTribuites);
      newObj.selectedParentOrderID = selectedParentOrderID;
      newObj.totalPrice = sum;
      newObj.totalPriceGST = (sum * (1 + gst)).toFixed(2);
      newObj.price_detail = { ...orderDetail.price_detail };
      return newObj;
    });
    console.log("order detail state change main ", orderDetail);
  }, [orderDetail]);

  // Update order detail state when Parent Fuernal ID is Changed
  useEffect(() => {
    // console.log(
    //   "order detail state change from parent order change ",
    //   orderDetail
    // );
    setOrderDetail((prev) => {
      let newObj = JSON.parse(JSON.stringify(prev));
      newObj.fd_code["FD Code"] = JSON.parse(
        selectedParentOrder.fd_code || "{}"
      );
      newObj.order = {
        // ...prev.order,
        ...order,
        late_person_name: selectedParentOrder.late_person_name,
      };
      newObj.datetime = {
        ...prev.datetime,
        funeral_date: selectedParentOrder.funeral_date,
        funeral_location: selectedParentOrder.funeral_location,
        funeral_time: selectedParentOrder.funeral_time,
      };
      return selectedParentOrder.length === 0 ? prev : newObj;
    });
  }, [selectedParentOrder]);
  useEffect(() => {
    if (orderDetail) {
    }
    let cc_loc = "";
    let tri_loc = "";
    if (cc_delivery_loc_choice === "Funeral Director") {
      cc_loc = orderDetail.fd_code["FD Code"].cc_delivery_location || "";
    } else if (cc_delivery_loc_choice === "Funeral Location") {
      cc_loc = orderDetail.datetime.funeral_location;
    }
    if (tri_delivery_loc_choice === "Funeral Director") {
      tri_loc = orderDetail.fd_code["FD Code"].cc_delivery_location;
    } else if (tri_delivery_loc_choice === "Funeral Location") {
      tri_loc = orderDetail.datetime.funeral_location;
    }

    setProperty(
      { name: "casket_cover_delivery_location", value: cc_loc },
      "datetime"
    );
    setProperty(
      { name: "tribuite_delivery_location", value: tri_loc },
      "datetime"
    );
  }, [cc_delivery_loc_choice, tri_delivery_loc_choice]);

  const [selectProduct, setSelectProduct] = useState(false);
  const [selectTribuite, setSelectTribuite] = useState(false);
  const [linkProduct, setLinkProduct] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});

  function validateData(data) {
    // console.log('Validating ',data)
    let detail = JSON.parse(data.orderDetail);
    if (!detail.datetime.funeral_date) {
      return "Please enter funeral date";
    }
    if (
      JSON.parse(data.selectedProducts || "[]").length === 0 &&
      JSON.parse(data.selectedTribuites || "[]").length === 0
    ) {
      return "Please select at least one product";
    }
    return "valid";
    // if(JSON.parse(data.selectedProducts || '[]') || JSON.parse(data.selectedTribuites || '[]')){
    //     return false
    // }
  }
  function isOrderValid() {
    if (validateData(orderDetailJSON) !== "valid") {
      toast.error(
        "Incomplete Data Submition : " + validateData(orderDetailJSON),
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        }
      );
      return false;
    } else {
      return true;
    }
  }

  function submitHandler() {
    const url = props.editWo
      ? BASE_SERVER_URL + "/wo/updateWO"
      : BASE_SERVER_URL + "/wo/addNew";
    setLoading(true);
    // return false
    let postData = props.editWo
      ? {
          id: id,
          updateData: {
            _id: orderDetail.order._id,
            type: orderType,
            price_detail: { ...orderDetail.price_detail },
            ...orderDetail.order,
            ...orderDetail.datetime,
            fd_code: JSON.stringify(orderDetail.fd_code["FD Code"] || {}),
            selectedProducts: JSON.stringify(orderDetail.selectedProducts),
            selectedTribuites: JSON.stringify(orderDetail.selectedTribuites),
          },
        }
      : orderDetailJSON;
    axios
      .post(url, postData)
      .then(function (response) {
        if (response.data.status === "success") {
          toast.success(
            props.editWo ? "Work Order Updated" : "New Work Order Added",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          navigate("/WorkOrderList");
        } else {
          toast.error("Error : " + response.data.error, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setShowSummary(false);
        setLoading(false);
      })
      .catch(function (error) {
        setShowSummary(false);
        setLoading(false);
      })
      .finally(() => {
        setShowSummary(false);
        setLoading(false);
      });
  }

  function setProperty2(a, b) {
    if (!b) {
      let newObj = JSON.parse(JSON.stringify(orderDetail));
      newObj[a.name] = a.value;
      setOrderDetail(newObj);
      // console.log("order detail state change setting b false", newObj);
    } else {
      if (!((orderDetail[b] || {})[a.name] === a.value)) {
        setOrderDetail((prev) => {
          let newObj = JSON.parse(JSON.stringify(orderDetail));
          newObj[b] = prev[b] || {};
          newObj[b][a.name] = a.value;
          // console.log(
          //   "order detail state change main change setting b true ",
          //   newObj
          // );
          return newObj;
        });
      } else {
      }
    }
  }
  function setProperty(a, b) {
    setOrderDetail((prev) => {
      let newObj = { ...orderDetail };
      newObj[b] = prev[b] || {};
      newObj[b][a.name] = a.value;
      // console.log('order detail changed 3 : setting property for : ',a.name,' value ', newObj)
      return newObj;
    });
  }
  function removeProduct(index) {
    setSelectedProducts((pre) => {
      let arr = [...selectedProducts];
      if (props.editWo && !arr[index]["newPrdt"]) {
        arr[index]["deleted"] = true;
      } else {
        arr.splice(index, 1);
      }
      return arr;
    });
  }
  function removeTribiute(index) {
    setSelectedTribuites((pre) => {
      let arr = [...selectedTribuites];
      if (props.editWo && props.fromDB && !(arr[index] || {})["newPrdt"]) {
        arr[index]["deleted"] = true;
      } else {
        arr.splice(index, 1);
      }
      return arr;
    });
  }

  if (pageLoading) {
    return <PageLoading noLogo={true} spinning={true} />;
  }

  return (
    <div className="data-input center-ver-col">
      {Object.keys(alert).length > 0 && (
        <PopUpAlert
          info={alert}
          onClose={() => {
            setAlert({});
          }}
        />
      )}
      <header className="large">Funeral Work Order Input Page</header>
      <div className="row center-ver space-around datas">
        <div className="col-lg-3 col-md-6 col-sm-12 data-row">
          <header>Ordered By</header>
          <TextInputField
            value={orderDetail.order.late_person_name || ""}
            selectedParent={selectedParentOrder}
            listRelated={true}
            selectResultVal={(a) => {
              setSelectedParentOrder(a);
              setSelectedParentOrderID(a._id);
            }}
            onChageSetter={(a) => {
              setProperty(a, "order");
            }}
            name="late_person_name"
            addClass="stretch"
            placeHolder="Late Person`s Name"
          />
          <TextInputField
            value={orderDetail.order.ordered_by}
            onChageSetter={(a) => {
              setProperty(a, "order");
            }}
            name="ordered_by"
            addClass="stretch"
            placeHolder="Ordered By"
          />
          <TextInputField
            value={orderDetail.order.orderer_phone}
            onChageSetter={(a) => {
              setProperty(a, "order");
            }}
            name="orderer_phone"
            addClass="stretch"
            placeHolder="Contact Phone Number"
          />
          <DropDown
            value={orderDetail.order.payment_option}
            fieldValue={orderDetail.order.payment_email}
            conditionalInputField={{
              on: ["Account", "Invoice Family Direct"],
              property: "payment_email",
              placeHolder: "Invoice Email",
            }}
            addClass="stretch"
            onChageSetter={(a) => {
              setProperty(a, "order");
            }}
            options={[
              "Eftpos/Credit Card",
              "Invoice Family Direct",
              "Account",
              "**UNPAID**",
            ]}
            placeHolder="Payment Method"
          />

          <TextInputFieldWithRelatedResult
            value={orderDetail.fd_code["FD Code"].code || ""}
            onChageSetter={(a) => {
              setSelectedFD(a || { code: a });
            }}
            disabled={Boolean(selectedParentOrderID)}
            name="fd_code"
            addClass="stretch"
            placeHolder="FD Code"
          />

          {orderDetail.fd_code["FD Code"].unique_info
            ? [
                <WidgetTitle title="Funeral Director Information" />,
                <p className="font-medium">
                  {orderDetail.fd_code["FD Code"].unique_info || "N/A"}
                </p>,
              ]
            : ""}
        </div>
        {/* <div className='col-md-1'></div> */}
        <div className="col-lg-3 col-md-6 col-sm-12 data-row">
          <header>Date, Time and Location</header>
          <DatePicker
            value={orderDetail.datetime.funeral_date || null}
            disabled={Boolean(selectedParentOrderID)}
            onChageSetter={(a) => {
              setProperty(a, "datetime");
            }}
            name="funeral_date"
            label="Funeral Date"
            addClass="stretch"
            icon={<BsCalendarFill className="icon" />}
            placeHolder="Funeral Date"
          />
          <LocationInputField
            onChageSetter={(a) => {
              setProperty(a, "datetime");
            }}
            value={
              JSON.parse(orderDetail.datetime.funeral_location || "{}").name ||
              ""
            }
            name="funeral_location"
            disabled={Boolean(selectedParentOrderID)}
            addClass="stretch font-medium"
            placeHolder="Funeral Location"
          />

          {/* <TextInputField value={orderDetail.datetime.funeral_location || ''} onChageSetter={(a)=>{setProperty(a,'datetime')}} name='funeral_location' addClass='stretch' placeHolder='Funeral Location'/>     */}
          <TextInputField
            value={orderDetail.datetime.funeral_time || ""}
            disabled={Boolean(selectedParentOrderID)}
            onChageSetter={(a) => {
              setProperty(a, "datetime");
            }}
            name="funeral_time"
            addClass="stretch"
            placeHolder="Funeral Time"
          />

          <LocationInputField
            onChageSetter={(a) => {
              setProperty(a, "datetime");
            }}
            value={
              JSON.parse(
                orderDetail.datetime.tribuite_delivery_location || "{}"
              ).name || ""
            }
            name="tribuite_delivery_location"
            addClass="stretch font-medium"
            placeHolder="Tributes Delivery Location"
          />

          <div className="row">
            <div className="col-md-6 col-sm-6 center-hor">
              <p className="font-medium bold-1">Deliver Tributes to</p>
            </div>
            <div className="col-md-6 col-sm-6">
              <LabeledCheckBox
                setter={() => {
                  setProperty(
                    {
                      name: "tribuite_delivery_location",
                      value:
                        orderDetail.fd_code["FD Code"]["cc_delivery_location"],
                    },
                    "datetime"
                  );
                }}
                label="Funeral Director"
                checked={
                  (orderDetail.datetime || {}).tribuite_delivery_location ===
                  (orderDetail.fd_code || {})["FD Code"]["cc_delivery_location"]
                }
              />
              <LabeledCheckBox
                setter={() => {
                  setProperty(
                    {
                      name: "tribuite_delivery_location",
                      value: orderDetail.datetime.funeral_location,
                    },
                    "datetime"
                  );
                }}
                label="Funeral Location"
                checked={
                  (orderDetail.datetime || {}).tribuite_delivery_location ===
                  (orderDetail.datetime || {}).funeral_location
                }
              />
            </div>
          </div>
          <LocationInputField
            onChageSetter={(a) => {
              setProperty(a, "datetime");
            }}
            value={
              JSON.parse(
                orderDetail.datetime.casket_cover_delivery_location || "{}"
              ).name || ""
            }
            name="casket_cover_delivery_location"
            addClass="stretch font-medium"
            placeHolder="Casket Cover Delivery Location"
          />
          <div className="row">
            <div className="col-md-6 col-sm-6 center-hor">
              <p className="font-medium bold-1">Deliver Casket Cover to</p>
            </div>
            <div className="col-md-6 col-sm-6">
              <LabeledCheckBox
                setter={() => {
                  setProperty(
                    {
                      name: "casket_cover_delivery_location",
                      value:
                        orderDetail.fd_code["FD Code"]["cc_delivery_location"],
                    },
                    "datetime"
                  );
                }}
                label="Funeral Director"
                checked={
                  (orderDetail.datetime || {})
                    .casket_cover_delivery_location ===
                  (orderDetail.fd_code || {})["FD Code"]["cc_delivery_location"]
                }
              />
              <LabeledCheckBox
                setter={() => {
                  setProperty(
                    {
                      name: "casket_cover_delivery_location",
                      value: (orderDetail.datetime || {}).funeral_location,
                    },
                    "datetime"
                  );
                }}
                label="Funeral Location"
                checked={
                  (orderDetail.datetime || {})
                    .casket_cover_delivery_location ===
                  (orderDetail.datetime || {}).funeral_location
                }
              />
            </div>
          </div>
          <TextInputField
            value={orderDetail.datetime.casket_cover_delivery_time}
            onChageSetter={(a) => {
              setProperty(a, "datetime");
            }}
            name="casket_cover_delivery_time"
            addClass="stretch"
            placeHolder="Casket Cover Delivery Time"
          />
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 data-row">
          <header>Select Products</header>
          <ButtonWithIcon
            clickTo={() => {
              setSelectProduct((prev) => !prev);
            }}
            value="Casket Cover"
            icon={<AiOutlinePlus className="icon large width-100" />}
          />
          <OrdersPreSummaryTable
            data={[]}
            wo_id={id}
            settings={settings}
            selectProduct={selectProduct}
            setSelectProduct={(a) => {
              setSelectProduct(a);
            }}
            setAlertDetails={(a) => {
              setAlert(a);
            }}
            selectType={"Casket cover"}
            product_category={"Casket cover"}
            edittingWO={props.editWo}
            removeProduct={removeProduct}
            syncOrderedProducts={(prod) => {
              setSelectedProducts(prod);
            }}
            selectedProductEdit={(a) => {
              setSelectProduct(true);
              setSelectedProductPreView(a);
            }}
          />
          <ButtonWithIcon
            value="Tribute"
            clickTo={() => {
              setSelectTribuite((prev) => !prev);
            }}
            hidder={() => {
              setLinkProduct((prev) => !prev);
            }}
            addproduct={(prod) => {
              setSelectedParentOrder((prev) => {
                return [...prev, prod];
              });
            }}
            icon={<AiOutlinePlus className="icon large width-100" />}
          />
          <OrdersPreSummaryTable
            data={[]}
            wo_id={id}
            settings={settings}
            selectProduct={selectTribuite}
            selectType={"tribuites"}
            product_category={"Tribute"}
            edittingWO={props.editWo}
            removeProduct={removeTribiute}
            setSelectProduct={(a) => {
              setSelectTribuite(a);
            }}
            setAlertDetails={(a) => {
              setAlert(a);
            }}
            syncOrderedProducts={(prod) => {
              setSelectedTribuites(prod);
            }}
            selectedProductEdit={(a) => {
              setSelectTribuite(true);
              setSelectedProductPreView(a);
            }}
          />
          <DropDown
            // value={(orderDetail.price_detail || {}).delivery_fee}
            value={deliveryFee.delivery_fee}
            fieldValue={deliveryFee.custom_delivery_fee}
            // fieldValue={deliveryFee.custom_delivery_fee}
            conditionalInputField={{
              on: ["Custom Delivery Fee"],
              property: "custom_delivery_fee",
              placeHolder: "Custom Delivery Fee ($)",
            }}
            addClass="stretch"
            name="delivery_fee"
            onChageSetter={(a) => {
              setDeliveryFee((prev) => {
                console.log("giving deliver cos", a, prev);
                let obj = { ...prev };
                obj[a.name] = a.value;
                return obj;
              });
            }}
            options={(settings.delivery_fee || "")
              .split(",")
              .map((each) => Number(each).toFixed(2))}
            displayUnit={"$"}
            usePropertyNameAsValue={true}
            placeHolder="Delivery Fee"
          />
          {selectedProducts.length || selectedTribuites.length ? (
            <PriceTag
              delivery_fee={
                (orderDetail.price_detail || {})[
                  (orderDetail.price_detail || {}).delivery_fee ===
                  "Custom Delivery Fee"
                    ? "custom_delivery_fee"
                    : "delivery_fee"
                ]
              }
              gts={true}
              gstVal={(orderDetail.price_detail || {}).gst || 0.1}
              price={{
                message: "Total Price",
                value: (orderDetail.price_detail || {}).total_price,
              }}
            />
          ) : (
            ""
          )}
        </div>

        <div className="actions row">
          <div className="col-md-2 col-sm-5">
            <ButtonWithIcon
              value="Cancel"
              clickTo={() => {
                navigate("/WorkOrderList");
              }}
              icon={<AiOutlineArrowLeft className="icon XL width-100" />}
            />
          </div>
          <div className="col-md-8 col-sm-2"></div>
          <div className="col-md-2 col-sm-5">
            <ButtonWithIcon
              iconSideRight={true}
              value="Next"
              clickTo={() => {
                if (isOrderValid()) {
                  setShowSummary((prev) => !prev);
                }
              }}
              icon={<AiOutlineArrowRight className="icon XL width-100" />}
            />
          </div>
        </div>
      </div>
      {/* {linkProduct && <Comp.ProductParentSelectorList  hidder={()=>{setLinkProduct((prev)=>!prev)}}  addParent={(parent)=>{console.log('the parent ',parent); setSelectedParentOrder((prev)=>{ return [...prev, parent] }) }}/>} */}
      <OverLayInputSummary
        toggleDisplay={() => {
          setShowSummary((prev) => !prev);
        }}
        addClass={showSummary ? "active" : ""}
        orderDetail={orderDetail}
        submitHandler={() => {
          submitHandler();
        }}
        isEdit={props.editWo}
        gts={true}
        loading={loading}
      />
    </div>
  );
}

function WidgetTitle(props) {
  return (
    <div className="title-separator relative center-hor ">
      <div className="line"></div>
      <div className="text">{props.title}</div>
    </div>
  );
}
