import React from "react";
import { Doughnut, Line } from "react-chartjs-2";
import { useRef, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register the necessary components
ChartJS.register(ArcElement, Tooltip, Legend);

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function sortObjectByValues(obj, topN, order = "asc") {
  // Convert object to array of [key, value] pairs
  const entries = Object.entries(obj);

  // Sort the array by numeric values
  entries.sort(([keyA, valueA], [keyB, valueB]) => {
    if (order === "asc") {
      return valueA - valueB;
    } else if (order === "desc") {
      return valueB - valueA;
    } else {
      throw new Error(
        "Invalid order value. Use 'asc' for ascending or 'desc' for descending."
      );
    }
  });

  // If topN is specified, limit the number of entries
  if (topN !== undefined) {
    entries.splice(topN);
  }

  // Convert sorted array back to object
  return Object.fromEntries(entries);
}

const LineChart = (props) => {
  const chartRef = useRef(null);
  let stat = sortObjectByValues(props.data || {}, 4, "desc");
  // let x =
  // const labels = Utils.months({ count: 7 });
  const data = {
    labels: ["1", "2", "3", "4", "5"],
    datasets: [
      {
        label: "My First Dataset",
        data: [80, 81, 56, 55, 40],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const config = {
    type: "line",
    data: data,
  };

  useEffect(() => {
    return () => {
      if (chartRef.current && chartRef.current.chartInstance) {
        chartRef.current.chartInstance.destroy();
      }
    };
  }, []);

  return (
    <div style={{ height: "420px" }} className="absolute-center">
      <Line ref={chartRef} data={config} />;
    </div>
  );
};

export default LineChart;
