import { FaUser, FaUserTie } from "react-icons/fa";
import "./style.css";
import { useEffect, useState } from "react";

export default function StaffDisplay(props) {
  const [active, setActive] = useState(
    Boolean(
      (props.user || {}).full_name === (props.selectedStaff || {}).full_name &&
        (props.selectedStaff || {}).full_name
    )
  );
  useEffect(() => {
    setActive(
      Boolean(
        (props.user || {}).full_name ===
          (props.selectedStaff || {}).full_name &&
          (props.selectedStaff || {}).full_name
      )
    );
  }, [props.selectedStaff]);

  return (
    <div
      onClick={() => {
        if (!active) {
          props.onSet(props.user);
        } else {
          props.onSet({});
        }
      }}
      className={
        (active ? "light-gold" : "") +
        " center-hor width-100 xlight-box-shadow space-between touchable"
      }
      style={{ marginTop: "3px", padding: "2px 3px" }}
    >
      <span style={active ? { marginLeft: "10px" } : {}}>
        {(props.detail || {}).access === "Super Admin" ? (
          <FaUserTie className="icon large padding-5 grey circle " />
        ) : (
          <FaUser className="icon large padding-5 grey circle border-grey" />
        )}
      </span>
      <span
        style={{ marginLeft: "20px", paddingRight: "10px" }}
        className="font-mid text-right"
      >
        {props.user.full_name}
      </span>
    </div>
  );
}
