import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useRef, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register the necessary components
ChartJS.register(ArcElement, Tooltip, Legend);

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function sortObjectByValues(obj, topN, order = "asc") {
  // Convert object to array of [key, value] pairs
  const entries = Object.entries(obj);

  // Sort the array by numeric values
  entries.sort(([keyA, valueA], [keyB, valueB]) => {
    if (order === "asc") {
      return valueA - valueB;
    } else if (order === "desc") {
      return valueB - valueA;
    } else {
      throw new Error(
        "Invalid order value. Use 'asc' for ascending or 'desc' for descending."
      );
    }
  });

  // If topN is specified, limit the number of entries
  if (topN !== undefined) {
    entries.splice(topN);
  }

  // Convert sorted array back to object
  return Object.fromEntries(entries);
}

const DoughnutChart = (props) => {
  const chartRef = useRef(null);
  let stat = sortObjectByValues(props.data || {}, 4, "desc");

  const data = {
    labels: Object.keys(stat || {}).map((each) => {
      return each + " $" + numberWithCommas(stat[each]);
    }),
    datasets: [
      {
        label: "Total Revenue",
        data: Object.values(stat || {}),
        backgroundColor: [
          "#e8d36e",
          "#dedbf2",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  useEffect(() => {
    return () => {
      if (chartRef.current && chartRef.current.chartInstance) {
        chartRef.current.chartInstance.destroy();
      }
    };
  }, []);

  return (
    <div style={{ height: "420px" }} className="absolute-center">
      <Doughnut ref={chartRef} data={data} />;
    </div>
  );
};

export default DoughnutChart;
