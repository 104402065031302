import React, { useEffect, createRef, useRef } from "react";
import {
  RunSheetPrintPage,
  DatePicker,
  DatePickerSmall,
  ToReadableDate,
  TableRowSeparator,
  LoadingDiv,
  CalcTimeDifference,
  replaceCharacter,
  shorten_string,
} from "../components";
import logo from "../images/logo.png";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import moment from "moment";
import { BsCalendarFill } from "react-icons/bs";
import { MdPrint, MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
// import ReactToPdf from 'react-to-pdf'

import { Pagenation } from "../components/pagenation";
import {
  DriverRunListDetail,
  DriverRunListMaking,
} from "../components/movableDragDropArea";

import { BASE_SERVER_URL } from "../sampleData";
const ReactToPdf = "react-to-pdf";

const thead = ["Type", "DASd"];

export function DelieryRunList(props) {
  document.title = props.title || "Ray`s Florist App";
  const navigate = useNavigate();
  const [selectedRun, setSelectedRun] = React.useState({});
  const [runs, setRuns] = React.useState([]);
  const [editRun, setEditRun] = React.useState(false);
  const [listIsLoading, setListIsLoading] = React.useState(false);
  const [printPreview, setPrintPreview] = React.useState(false);
  const [allRunPrintPreview, setAllRunPrintPreview] = React.useState(false);
  //filters
  const [listDate, setListDate] = React.useState("");
  const [orderType, setOrderType] = React.useState("Funeral");
  const [allPage, setAllPage] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({});

  const pageLimit = 15;

  React.useEffect(() => {
    fetchRuns();
  }, [listDate, orderType, page, query]);

  function fetchRuns() {
    setListIsLoading(true);
    let url = BASE_SERVER_URL + "/run/getAllBy";
    let filter = {
      page: page,
      byQuery: query,
      limit: pageLimit,
      sortBy: "delivery_date",
      find: listDate ? { delivery_date: listDate } : {},
    };
    setFilters({
      page: page,
      byQuery: query,
      limit: pageLimit,
      sortBy: "delivery_date",
      find: { delivery_date: replaceCharacter(listDate, "/", "-") },
    });
    axios
      .post(url, filter)
      .then((doc) => {
        if (doc.data.status === "success") {
          setRuns(doc.data.response);
          setListIsLoading(false);
          setAllPage(Math.ceil(doc.data.responseLength / pageLimit));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function refreshList() {
    setEditRun(false);
    fetchRuns();
    setSelectedRun({});
  }

  return (
    <div className="data-input center-ver-col">
      {printPreview && (
        <RunSheetPrintPage
          date={runs[0].delivery_date}
          hidder={() => {
            setPrintPreview(false);
          }}
          detail={selectedRun}
        />
      )}
      <div
        className={
          (!Object.keys(selectedRun).length ? "absolute-center " : "") +
          "row width-100 relative"
        }
      >
        <div
          className={
            !Object.keys(selectedRun).length
              ? "col-lg-10 col-md-10 col-sm-12 relative no-print"
              : "no-print col-lg-6 col-md-4 col-sm-12 relative"
          }
        >
          <header className="large padding-10 center-hor">
            List Of Delivery Runs
          </header>
          <div className="row width-100 center-hor space-between">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <span className="font-medium">Filter by Date :</span>
              <DatePickerSmall
                value={listDate}
                onChageSetter={(a) => {
                  setListDate(ToReadableDate(a.value, [], "/"));
                }}
                name="delivery_date"
                label="Delivery Date"
                addClass=""
                icon={<BsCalendarFill className="icon" />}
                placeHolder="Delivery Date"
                light={true}
                clearDate={() => {
                  setListDate("");
                }}
              />
            </div>
            <div
              className="col-lg-3 col-md-4 col-sm-12"
              style={{ textAlign: "right" }}
            >
              {listDate !== "" && (
                <MdPrint
                  onClick={() => {
                    // setAllRunPrintPreview(true)
                    window.open(
                      "/deliveries/print/" + JSON.stringify(filters),
                      "_blank"
                    );
                  }}
                  className="icon large width-auto margin-LR-15"
                />
              )}
            </div>
          </div>
          {allRunPrintPreview ? (
            <RunsPrintPriview
              date={runs.length ? runs[0].delivery_date : ""}
              data={runs}
              hidder={() => {
                setAllRunPrintPreview(false);
              }}
            />
          ) : (
            ""
          )}
          <TableListRun
            data={runs}
            loading={listIsLoading}
            listStart={(page - 1) * pageLimit}
            toggleSelected={(a) => {
              setSelectedRun(a);
            }}
          />
          <div className="row width-100 absolute-center">
            <div className="col-lg-4 col-md-8 col-sm-12">
              <Pagenation
                pages={allPage}
                setActivePage={(a) => {
                  setPage(a);
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={
            (Object.keys(selectedRun).length ? "active " : "") +
            " run-detail col-lg-8 col-md-8 col-sm-12"
          }
        >
          <DriverRunListDetail
            onDeleteSuccess={refreshList}
            togglePrintPreview={() => {
              setPrintPreview(true);
            }}
            toggleEdit={() => {
              // setEditRun(true)
              localStorage.setItem("selected-products", "{}");
              localStorage.setItem("selected-run-orders", "[]");
              localStorage.setItem("run-sheet-state", "active");
              localStorage.setItem("run-details", "{}");
              window.open("/EditRunSheet", "_blank");
              navigate(
                "/editRunSheet/" +
                  selectedRun._id +
                  "/" +
                  replaceCharacter(selectedRun.delivery_date, "/", "-")
              );
            }}
            hidder={() => {
              setSelectedRun({});
            }}
            orders={selectedRun}
            edit={false}
          />
        </div>
      </div>
    </div>
  );
}

export function RunsPrintPriview(props) {
  const ref = createRef();
  return (
    <div className="the-print-page print-page display-flex">
      <div
        className="no-print float-right center-hor"
        style={{ zIndex: "10", marginTop: "-50px" }}
      >
        <MdPrint
          onClick={() => {
            window.print();
          }}
          className="icon large "
        />
      </div>
      <div className="width-100 flex-grow " ref={ref}>
        <div className="width-100 center-hor">
          <div className="absolute-center" style={{ width: "150px" }}>
            <img src={logo} style={{ width: "100px" }} />
          </div>
          <div className="width-100">
            <div className="head page center-hor relative ">
              <span className="font-medium absolute-center date light-box-shadow">
                {props.date}
              </span>
              <span
                className="font-big absolute-center width-50"
                style={{ marginLeft: "0px" }}
              >
                Delivery Runs
              </span>
            </div>
          </div>
        </div>

        <TableListRun
          maxDetail={false}
          noSeparator={true}
          data={props.data}
          listStart={0}
        />
      </div>
    </div>
  );
}

function TableListRun(props) {
  let date = "";
  let runs = props.data || [];
  console.log("passed runs ", runs);
  const head = (
    <div className="delivery-run-row head center-hor width-100 font-medium">
      <span>#</span>
      <span style={{ width: "10%" }}>Driver's Name</span>
      <span>Vehicle No. Plate</span>
      <span style={{ width: "20%" }}>Description</span>
      <span style={{ width: "20%" }}>Comment</span>
      {props.maxDetail ? <span>Product Count</span> : ""}
      {/* <span style={{width:'10%'}}>Delivery Date</span> */}
      {/* <span>Cross Checked</span> */}
    </div>
  );
  // return (<LoadingDiv amount={10} />)
  return (
    <div className="work-order-list width-100">
      {head}
      {props.loading ? (
        <LoadingDiv amount={10} />
      ) : runs.length === 0 ? (
        <p
          className="gold-themed font-medium width-100 absolute-center"
          style={{ marginTop: "10px" }}
        >
          No Run Sheet found
        </p>
      ) : (
        runs.map((each, index) => {
          let num = index;
          const timeDir = { funeral_date: true, date: false };
          let d = CalcTimeDifference(each["delivery_date"], null, true);
          if (d !== date && !props.noSeparator) {
            date = d;
            return [
              <TableRowSeparator
                key={num}
                message={d}
                onClick={() => {
                  props.setDateFilter(each["delivery_date"]);
                }}
              />,
              <RunListRow
                maxDetail={props.maxDetail}
                listStart={props.listStart}
                key={index}
                num={index + 1}
                onSelect={() => {
                  props.toggleSelected(each);
                }}
                data={each}
              />,
            ];
          } else {
            return (
              <RunListRow
                maxDetail={props.maxDetail}
                listStart={props.listStart}
                key={index}
                num={index + 1}
                onSelect={() => {
                  props.toggleSelected(each);
                }}
                data={each}
              />
            );
          }
        })
      )}
    </div>
  );
}
function RunListRow(props) {
  return (
    <div
      onClick={() => {
        props.onSelect();
      }}
      className="delivery-run-row touchable center-hor width-100 xlight-box-shadow font-small"
    >
      <span>{props.num + props.listStart}</span>
      <span style={{ width: "10%" }}>{props.data.drivers_name}</span>
      <span>{props.data.vehicle_number}</span>
      <span style={{ width: "20%" }}>
        {shorten_string(props.data.description, 40)}
      </span>
      <span style={{ width: "20%" }}>
        {shorten_string(props.data.comment, 40)}
      </span>
      {/* {props.maxDetail?<span>{CountProducts(props.data)}</span>:''} */}
      {/* <span style={{width:'10%'}}>{props.data.delivery_date || ''}</span> */}
      {/* <span><IconsMD.MdCheck className='icon mid' /></span> */}
    </div>
  );
}
