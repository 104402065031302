import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

export const DateRangePickerer = ({ onRangeSelect }) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const handleSelect = (ranges) => {
    setState([ranges.selection]);
    if (onRangeSelect) {
      onRangeSelect(ranges.selection);
    }
  };

  return (
    <div className={"xlight-box-shadow"}>
      <h5>Pick Date Range</h5>
      <DateRangePicker
        ranges={state}
        color={"red"}
        rangeColors={["#b79800"]}
        onChange={handleSelect}
      />
    </div>
  );
};
