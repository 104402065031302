import { MdAdd } from "react-icons/md";
import "./style.css";
export default function RoundCheckBox(props) {
  return (
    <div class={"round " + props.addClass}>
      <input type="checkbox" checked={props.condition || false} id="checkbox" />
      <label
        style={{ height: props.size || "" }}
        className={(props.labelMark ? "" : "checkmark") + " absolute-center"}
        onClick={() => {
          props.checkChange();
        }}
        for="checkbox"
      >
        {props.labelMark ? (
          <MdAdd className={(props.condition ? "white" : "") + " icon mid"} />
        ) : (
          ""
        )}
      </label>
    </div>
  );
}
