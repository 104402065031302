import { useState,useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import axios from 'axios'
import {CalcTimeDifference,TableRowSeparator,TextInputField,LocationInputField,ButtonFlat,LoadingDiv,ButtonIcon} from '../components'
import {Pagenation} from '../components/pagenation'
import { MdEdit, MdDelete} from 'react-icons/md'
import {BASE_SERVER_URL} from '../sampleData'


export function FDs(props){
const fd_reset = {
    business_name: '',
    code: '',
    address: '',
    email_address: '',
    contact_number: '',
    owner_name: '',
    manager_name: '',
    cc_delivery_location: '',
    unique_info: ''

}

const [FdDetail, setFdDetail] = useState(fd_reset)
const [FDs, setFDs] = useState([])
const [page,setPage] = useState(1)
const [allPage,setAllPage] = useState(0)
const [loading,setLoading] = useState(false)
const [editing,setEditing] = useState(false)
const pageLimit = 10;

useEffect(()=>{
    refresh()
},[])

function refresh(){
    let url = BASE_SERVER_URL+'/fd/getAll'
    setLoading(false)
    axios.get(url,FdDetail).then((response)=>{        
        setFDs(response.data.response)
        setAllPage(response.data.response)
        // console.log('response fds ',response.data.response)
        setFdDetail(fd_reset)
        setEditing(false)
    }).catch((err)=>{
        
    })
}

function submit(){
    let url = editing? BASE_SERVER_URL+'/fd/updateFD' : BASE_SERVER_URL+'/fd/addNew' 
    setLoading(true)
    axios.post(url,FdDetail).then((response)=>{        
        setLoading(false)
        refresh()
    }).catch((err)=>{
        setLoading(false)
    })
}

function setDetail(a){
    console.log('setting detail : ',a.value,a.name)
    setFdDetail((prev)=>{
        let newObj = {...prev}
        newObj[a.name] = a.value
        return newObj
    })
}
    const [addNew, setAddNew] = useState(false)
    return <div className='data-input center-ver-col'>
                <div className={'row width-100 relative'}>
                        <header className='large padding-10 absolute-center relative'>
                            List of Funeral Directors 
                            {/* <ButtonFlat value='Add New' addClass='float-right width-auto font-medium' clickTo={()=>{setAddNew(true)}} /> */}
                            </header>
                    <div className={(false? 'col-lg-12 col-md-12 relative': 'col-lg-10 col-md-10 col-sm-12 relative')}>
                        <TableListFDS loading={loading} refresh={()=>{refresh()}} data={FDs} listStart={(page-1) * pageLimit} select={(a)=>{setFdDetail(a); setEditing(true)}}/>
                            <div className='row width-100 absolute-center'>
                                <div className='col-lg-10 col-md-10 col-sm-12'>
                                    {/* <Pagenation pages={allPage} setActivePage={(a)=>{setPage(a)}}/> */}
                                </div>
                        </div>
                    </div>
                    <div className={'col-lg-2 col-md-2 col-sm-12 relative'} hidden={false}>
                        <div className='width-100 work-order-list ' style={{display:'flex',flexDirection:'column',paddingBottom:'10px',alignItems:'center',justifyContent:'space-between'}}>
                            <div className='width-100 center-ver-col'>
                                <header className={(editing? "light-gold-themed":"grey-themed")+" padding-10 margin-0"}>{editing?'Edit FD':'Add New FD'}</header>
                                <div className='width-90' style={{paddingTop:'20px'}}>
                                    <TextInputField onChageSetter={(a)=>{setDetail(a)}} value={FdDetail.business_name} name='business_name' addClass='stretch font-medium' placeHolder='Business Name'/>    
                                    <TextInputField onChageSetter={(a)=>{setDetail(a)}} value={FdDetail.code} name='code' addClass='stretch font-medium' placeHolder='Code'/>    
                                    <LocationInputField onChageSetter={(a)=>{setDetail(a)}} value={JSON.parse(FdDetail.address || '{}').name || ''} name='address' addClass='stretch font-medium' placeHolder='Address'/>    
                                    {/* <TextInputField onChageSetter={(a)=>{setDetail(a)}} value={FdDetail.address} name='address' addClass='stretch font-medium' placeHolder='Address'/>     */}
                                    <TextInputField onChageSetter={(a)=>{setDetail(a)}} value={FdDetail.email_address} name='email_address' addClass='stretch font-medium' placeHolder='Email Address'/>    
                                    <TextInputField onChageSetter={(a)=>{setDetail(a)}} value={FdDetail.contact_number} name='contact_number' addClass='stretch font-medium' placeHolder='Contact Number'/>    
                                    <TextInputField onChageSetter={(a)=>{setDetail(a)}} value={FdDetail.owner_name} name='owner_name' addClass='stretch font-medium' placeHolder='Owner`s Name'/>    
                                    <TextInputField onChageSetter={(a)=>{setDetail(a)}} value={FdDetail.manager_name} name='manager_name' addClass='stretch font-medium' placeHolder='Manager`s Name'/>    
                                    <LocationInputField onChageSetter={(a)=>{setDetail(a)}} value={JSON.parse(FdDetail.cc_delivery_location || '{}').name || ''} name='cc_delivery_location' addClass='stretch font-medium' placeHolder='C/C Delivery Location'/>    
                                    <TextInputField textArea={true} onChageSetter={(a)=>{setDetail(a)}} value={FdDetail.unique_info} name='unique_info' addClass='stretch font-medium' placeHolder='Special Information'/>    
                                </div>
                            </div>
                            <div className='width-90 center-hor'>
                                <div className='width-50 margin-5'>
                                      <ButtonFlat dotColor='#b79956' addClass='font-medium cancel thin-flat width-100' 
                                                  style={{background: '#eaeaea', width:'100%',height:'35px'}}  value='Cancel' clickTo={()=>{setEditing(false);setFdDetail(fd_reset)}}/>
                                </div>
                                <div className='width-50'>
                                      <ButtonFlat dotColor='#b79956' addClass='font-medium thin-flat width-100' 
                                                  style={{background: '#eaeaea', width:'100%',height:'35px'}} loading={loading} value='Save' clickTo={submit}/>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
}

export function TableListFDS(props){
    let date = ''
    let runs = props.data || []
    const head =  (<div className='delivery-run-row fd-list head center-hor width-100 font-medium margin-0'>
                    <span>#</span>
                    <span>Business Name</span>
                    <span>Code</span>
                    <span>Address</span>
                    <span>Email Address</span>
                    <span>Contact Number</span>
                    <span>Owner Name</span>
                    <span>Manager Name</span>
                    <span>Casket Cover Delivery Location</span>
                    <span></span>
                </div>)
    // return (<LoadingDiv amount={10} />)
    return (
        <div className='work-order-list width-100'>
        {head}
        {
        props.loading?<LoadingDiv amount={10} />
        :runs.map((each, index)=>{
                        let num = index
                        const timeDir = {funeral_date: true, date: false}
                        let d = CalcTimeDifference(each['delivery_date'], null,true)
                        if(d !== date && !props.noSeparator){
                            date=d
                            return [<TableRowSeparator key={num} message={d} />,
                                    <FdsListRow refresh={()=>{props.refresh()}} selectForEdit={()=>{props.select(each)}} maxDetail={props.maxDetail} listStart={props.listStart} key={index} num={index+1} onSelect={()=>{props.toggleSelected(each)}} data={each}/>]
                        }else{
                            return <FdsListRow refresh={()=>{props.refresh()}} selectForEdit={()=>{props.select(each)}} maxDetail={props.maxDetail} listStart={props.listStart} key={index} num={index+1} onSelect={()=>{props.toggleSelected(each)}} data={each}/>
                        }
                        })
        }
      </div> 
    )
}
function FdsListRow(props){
    const [deleteLoading, setDeleteLoading] = useState(false)
    function deleteFD(x){
        let url = BASE_SERVER_URL+'/fd/delete'
        setDeleteLoading(true)
        axios.post(url,{id:x}).then((response)=>{        
            props.refresh()
            setDeleteLoading(false)
        }).catch((err)=>{  
            setDeleteLoading(false)
        })
    }
    console.log('address ',
                props.data.address,  
                // isValidJSON(props.data.address) , 
                props.data.address !== '', 
                // JSON.parse(props.data.address || '{}')['coordinates']
                )
    return (
            <div className='delivery-run-row fd-list center-hor width-100 xlight-box-shadow font-small'>
                    <span>{(props.num + props.listStart)}</span>
                    <span>{props.data.business_name}</span>
                    <span>{props.data.code}</span>
                    {/* <span>{props.data.address}</span> */}
                    <span>{
                    isValidJSON(props.data.address) && props.data.address !== '' ?
                         JSON.parse(props.data.address || '{}')['coordinates']? 
                           <a href={'https://www.google.com/maps/search/?api=1&query='+JSON.parse(props.data.address || '{}')['coordinates']['lat']+','+JSON.parse(props.data.address || '{}')['coordinates']['lng']} target='blank' className='touchable'>
                               {
                                JSON.parse(props.data.address || '{}')['name']
                                }
                           </a> 
                           :props.data.address : 'N/A'}</span>
                    <span>{props.data.email_address}</span>
                    <span>{props.data.contact_number}</span>
                    <span>{props.data.owner_name}</span>
                    <span>{props.data.manager_name}</span>
                    <span>{
                    isValidJSON(props.data.cc_delivery_location) && props.data.cc_delivery_location !== '' && JSON.parse(props.data.cc_delivery_location || '{}')['coordinates']? 
                           <a href={'https://www.google.com/maps/search/?api=1&query='+JSON.parse(props.data.cc_delivery_location || '{}')['coordinates']['lat']+','+JSON.parse(props.data.cc_delivery_location || '{}')['coordinates']['lng']} target='blank' className='touchable'>
                               {JSON.parse(props.data.cc_delivery_location || '{}')['name']}
                           </a> 
                            :'N/A'}</span>
                    <span className='center-hor' >
                            <MdEdit className='icon mid' onClick={()=>{props.selectForEdit()}}/> 
                            <ButtonIcon addClass='small' loading={deleteLoading} 
                            icon={<MdDelete onClick={()=>{deleteFD(props.data._id)}} className='icon mid'/>}
                            /> 
                    </span>
            </div>)
}

function isValidJSON(text){
    // console.log('checking json : ',text,JSON.parse(text))
    
if(text === ''){return false}

    if (/^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').
        replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
        replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                //the json is ok
                return true
                
                }else{

                    //the json is not ok
                    return false
                    
        }
}