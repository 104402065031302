import { useState, useEffect } from "react"




function ReportContainer(props){


    return(
        <div className='report-container'>
                <span className="report-header width-100 bold-1"> 
                            <span className="gold-themed-2">Data Modified</span>
                            <span>Sat, Mar 12, 2023</span>  
                </span>
                
                <div className="report-body">
                    <span>History</span>
                    <span>History</span>
                    <span>History</span>
                    <span>History</span>
                </div>
        </div>
    )
}


export function BUpage(){

    return (
        <div className="width-100 padding-10"> 
                <header>Back Up History</header>
                <ReportContainer />
        </div>
    )
}