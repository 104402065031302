import { useState } from 'react'
import {TextInputField,LabeledCheckBox,ButtonMid,ButtonFlat} from './components'
import logo from './images/logo.png'
import { BASE_SERVER_URL } from './sampleData'
import axios from 'axios'
import { toast } from 'react-toastify'

export function LogInPage(props){
    const [user_name, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [remember, setRemember] = useState(false)
    const [errorLogin, setErrorLogin] = useState(false)
    const [loading, setLoading] = useState(false)

    function submit(){
        setLoading(true)
        let url = BASE_SERVER_URL+'/login'
        axios.post(url,{
            userName:user_name,
            password:password
        }).then((response)=>{
            setLoading(false)
            console.log('data user',response.data)
            if(response.data.status === 'success' && response.data.auth){
                localStorage.setItem('RF-session-tocken',response.data.access_tocken)
                setErrorLogin(false)
                props.setLogOut(false)
                props.setCurrent({
                    id: response.data.id,
                    userName: response.data.userName,
                    full_name: response.data.full_name,
                    access: response.data.access,
                })
            }else{
                setErrorLogin(true)
            }
        }).catch((err)=>{
            toast.error('Error with Request ', {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: false,
                progress: false,
                theme: "light",
            });
                setLoading(false)
        })
      }

    return (
        <div className='app-container center-ver'>
            <form onSubmit={submit} >
            <div className='login-form center-ver-col'>
                    <img src={logo} />
                    {errorLogin && <p style={{color:'red',}} className="error-label-message" >Incorrect Username or Password</p>}
                    <TextInputField placeHolder="Username"  onChageSetter={(a)=>{setUsername(a.value)}} name='username' value={user_name} addClass='width-100'/>
                    <TextInputField type="password" placeHolder="Password" onChageSetter={(a)=>{setPassword(a.value)}} name='password'  value={password} addClass='width-100'/>
                    <div className='width-100 center-hor space-between'>
                        <LabeledCheckBox label="Remember me" setter={()=>{setRemember(prev =>! prev)}} checked={remember}/>
                        {/* <span> Forgot password? </span> */}
                    </div>
                    <ButtonFlat dotColor='#b79956' addClass='font-medium thin-flat' dotColor='#e9d6ad' style={{background: '#eaeaea', width:'100%',height:'30px'}} loading={loading} value='Login' clickTo={submit}/>
            </div>
            </form>
        </div>
    )
}