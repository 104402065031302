import { useEffect, useState, useRef } from "react";
import {
  OverLayInputSummary,
  ProductTypeSelectorList,
  ButtonWithIcon,
  TextInputField,
  OrdersPreSummaryTable,
  DatePicker,
  DropDown,
  PriceTag,
  orderObject,
  PageLoading,
  LabeledCheckBox,
  LocationInputField,
} from "../components";
import "bootstrap/dist/css/bootstrap.css";
import { PopUpAlert } from "../components/movableDragDropArea";
import * as IconsAI from "react-icons/ai";
import { BsCalendarFill } from "react-icons/bs";
import axios from "axios";
import { BASE_SERVER_URL, weekDays } from "../sampleData";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import moment from "moment";

function getNextDayTimestamp(dayName) {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const inputDayIndex = daysOfWeek.indexOf(dayName);
  if (dayName === -1) {
    return "Invalid day name";
  }

  const today = new Date();
  let currentDayIndex = today.getDay();
  let daysToAdd = dayName - currentDayIndex;

  // If the input day is the same as today, add 7 days to get the next occurrence
  if (daysToAdd <= 0) {
    daysToAdd += 7;
  }

  const nextDay = new Date();
  nextDay.setDate(today.getDate() + daysToAdd);
  nextDay.setHours(0, 0, 0, 0);

  return nextDay.getTime();
}

export function RecurringInputPage(props) {
  const { id } = useParams();
  document.title = props.title || "Ray`s Florist App";
  const navigate = useNavigate();
  const [orderDetailJSON, setOrderDetailJSON] = useState("");
  const [orderDetail, setOrderDetail] = useState({
    order: {},
    price_detail: {},
    recipient: {},
  });

  const [orderType, setOrderType] = useState(props.orderType);
  const [addOns, setAddOns] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductPreView, setSelectedProductPreView] = useState({});
  const [pageLoading, setPageLoading] = useState(props.editWo ? true : false);
  const [settings, setSettings] = useState({});
  const [oneTimeDelivery, setOneTimeDelivery] = useState(false);

  function populate(id) {
    // console.log('Populating Edit')
    let url = BASE_SERVER_URL + "/wo/getBy";

    axios
      .post(url, { filter: { _id: id } })
      .then((doc) => {
        let data = orderObject(doc.data.response[0], "Retail");
        setOrderDetail((prev) => {
          let dat = {
            ...data,
            products_selected: JSON.parse(
              doc.data.response[0].products_selected || "[]"
            ),
          };
          // console.log('order detail changed 1 : populating ', dat)
          return dat;
        });
        setOneTimeDelivery(doc.data.response[0].frequency === "Once");
      })
      .finally(() => {
        setPageLoading(false);
        // return false
        let url = BASE_SERVER_URL + "/wo/getProductsByOrderId/" + id;
        axios.get(url).then((res) => {
          // console.log('response products - first',url,res.data.response)
          setSelectedProducts(res.data.response || []);
          // if((res.data.response || []).stringify() !== selectedProducts.stringify()){
          // }
          setPageLoading(false);
        });
      });
  }

  useEffect(() => {
    // one time for populating, set setting and setting orderDetail gst value
    if (props.editWo && !Object.keys(orderDetail.recipient).length) {
      populate(id);
    }
    if (Object.keys(settings).length === 0) {
      let url = BASE_SERVER_URL + "/settings/getAll";
      axios
        .get(url)
        .then((doc) => {
          if (doc.data.status === "success") {
            setSettings(doc.data.response[0]);
          }
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    setProperty(
      { name: "gst", value: (Number(settings.gst) || 0) / 100 },
      "price_detail"
    );
  }, [settings]);

  useEffect(() => {
    //on selected products change
    let sum = 0;
    [...selectedProducts]
      .filter((val) => {
        return !val.deleted;
      })
      .map((x) => {
        let price = x.customPrice ? x.customPrice : x.price;
        let sashPrice = x.sashPrice ? x.sashPrice : 0;
        sum += Number(price * x.amount) + Number(sashPrice);
      });
    setProperty({ name: "total_price", value: sum }, "price_detail");
    // if (sum !== (orderDetail.price_detail || {}).total_price) {
    // }

    if (selectedProducts) {
      setOrderDetail((prev) => {
        prev.selectedProducts = selectedProducts;
        return prev;
      });
    }
    // console.log("selected products state change ", selectedProducts, sum);
  }, [selectedProducts]);

  const [deliveryDay, setDeliveryDay] = useState(null);
  const [orderStartDate, setOrderStartDate] = useState("");

  useEffect(() => {
    if (orderStartDate !== "") {
      let start_to_date = orderStartDate
        ? new Date(
            moment(orderStartDate, "DD/MM/YYYY").format("MM/DD/YYYY")
          ).getDay()
        : undefined;
      setProperty({ value: start_to_date, name: "delivery_day" }, "recipient");
    }
  }, [orderStartDate]);

  useEffect(() => {
    if (deliveryDay !== null) {
      const nextDayTimestamp = getNextDayTimestamp(deliveryDay);
      setProperty(
        {
          value: moment(nextDayTimestamp).format("DD/MM/YYYY"),
          name: "order_start_date",
        },
        "recipient"
      );
    }
  }, [deliveryDay]);

  useEffect(() => {
    let sum = 0;
    [...selectedProducts].map((x) => {
      let price = x.customPrice ? x.customPrice : x.price;
      let sashPrice = x.sashPrice ? x.sashPrice : 0;
      sum += price * x.amount + sashPrice;
    });
    setOrderDetailJSON((prev) => {
      let newObj = {};
      newObj.type = orderType;
      newObj.received_by = JSON.stringify(props.current_user);
      newObj.add_ons = JSON.stringify(addOns);
      newObj.orderDetail = JSON.stringify(orderDetail);
      newObj.selectedProducts = JSON.stringify(selectedProducts);
      newObj.price_detail = { ...orderDetail.price_detail };
      newObj.totalPrice = (sum * (1 + settings.gst)).toFixed(2);
      return newObj;
    });
    // console.log("order detail state change ", orderDetail);
  }, [orderDetail]);

  const [selectProduct, setSelectProduct] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});

  function validateData(data) {
    let recip = JSON.parse(data.orderDetail);
    if (
      recip.recipient.frequency !== "Once" &&
      (!Boolean(recip.recipient.frequency) ||
        (!Boolean(recip.recipient.delivery_day) &&
          recip.recipient.delivery_day !== 0))
    ) {
      setAlert({
        title: "Incomplete Work Order Data Submition",
        message: "Please provide delivery day and frequency for the Work Order",
        type: "error",
      });
      return false;
    }
    if (
      recip.recipient.frequency === "Once" &&
      !Boolean(recip.recipient.delivery_date)
    ) {
      setAlert({
        title: "Incomplete Work Order Data Submition",
        message: "Please provide delivery date for the Work Order",
        type: "error",
      });
      return false;
    }
    if (JSON.parse(data.selectedProducts || "[]").length === 0) {
      setAlert({
        title: "Incomplete Work Order Data Submition",
        message: "Please select at least one product",
        type: "error",
      });
      return false;
    }
    return "valid";
  }
  function isOrderValid() {
    if (validateData(orderDetailJSON) !== "valid") {
      return false;
    } else {
      return true;
    }
  }

  function submitHandler() {
    // return false
    setLoading(true);
    const url = props.editWo
      ? BASE_SERVER_URL + "/wo/updateWO"
      : BASE_SERVER_URL + "/wo/addNew";
    let postData = props.editWo
      ? {
          id: id,
          updateData: {
            price_detail: { ...orderDetail.price_detail },
            ...orderDetail.order,
            ...orderDetail.recipient,
            products_selected: JSON.stringify(selectedProducts),
          },
        }
      : orderDetailJSON;

    axios
      .post(url, postData)
      .then(function (response) {
        // console.log(response);
        if (response.data.status === "success") {
          navigate("/WorkOrderList/retail");
          toast.success(
            "Work Order " +
              (props.editWo ? "Edited " : "Added") +
              "successfully",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          toast.error("Error Adding New Retail Work Order", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setShowSummary(false);
        setLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
        setShowSummary(false);
        setLoading(false);
      })
      .finally(() => {
        setShowSummary(false);
        setLoading(false);
      });
  }

  function setProperty(a, b) {
    setOrderDetail((prev) => {
      let newObj = { ...orderDetail };
      newObj[b] = prev[b] || {};
      newObj[b][a.name] = a.value;
      // console.log('order detail changed 3 : setting property for : ',a.name,' value ', newObj)
      return newObj;
    });
  }
  function removeProduct(index) {
    setSelectedProducts((pre) => {
      // let arr = [...selectedProducts];

      // if (props.editWo && !arr[index]["newPrdt"]) {
      //   arr[index]["deleted"] = true;
      // } else {
      //   arr.splice(index, 1);
      // }
      // return arr;

      let arr = [...selectedProducts];
      if (props.editWo && props.fromDB && !(arr[index] || {})["newPrdt"]) {
        arr[index]["deleted"] = true;
      } else {
        arr.splice(index, 1);
      }
      return arr;
    });
  }

  if (pageLoading) {
    return <PageLoading noLogo={true} spinning={true} />;
  }
  return (
    <div className="data-input center-ver-col">
      {Object.keys(alert).length > 0 && (
        <PopUpAlert
          info={alert}
          onClose={() => {
            setAlert({});
          }}
        />
      )}
      <header className="main large">Retail Work Order Input Page</header>
      <div className="row center-ver space-around datas">
        <div className="col-lg-3 col-md-4 col-sm-12 data-row">
          <header>Ordered By</header>
          <TextInputField
            value={orderDetail.order.company_name}
            onChageSetter={(a) => {
              setProperty(a, "order");
            }}
            name="company_name"
            addClass="stretch"
            placeHolder="Company Name"
          />
          <TextInputField
            value={orderDetail.order.ordered_by}
            onChageSetter={(a) => {
              setProperty(a, "order");
            }}
            name="ordered_by"
            addClass="stretch"
            placeHolder="Ordered By"
          />
          <TextInputField
            value={orderDetail.order.orderer_phone}
            onChageSetter={(a) => {
              setProperty(a, "order");
            }}
            name="orderer_phone"
            addClass="stretch"
            placeHolder="Contact Phone Number"
          />
          <DropDown
            value={orderDetail.order.payment_option}
            fieldValue={orderDetail.order.payment_email}
            conditionalInputField={{
              on: ["Account"],
              property: "payment_email",
              placeHolder: "Invoice Email",
            }}
            addClass="stretch"
            onChageSetter={(a) => {
              setProperty(a, "order");
            }}
            options={["Eftpos/Credit Card", "Account", "**UNPAID**"]}
            placeHolder="Payment Method"
          />

          <LabeledCheckBox
            checked={oneTimeDelivery}
            font="font-xmedium"
            setter={() => {
              setOneTimeDelivery((prev) => {
                setProperty(
                  { name: "frequency", value: !prev ? "Once" : "" },
                  "recipient"
                );
                setProperty({ name: "delivery_day", value: "" }, "recipient");
                setProperty({ name: "delivery_date", value: "" }, "recipient");
                return !prev;
              });
            }}
            addClass="margin-TB-10"
            label="One Time Delivery"
          />
          {oneTimeDelivery ? (
            <DatePicker
              value={orderDetail.recipient.delivery_date || null}
              onChageSetter={(a) => {
                setProperty(a, "recipient");
              }}
              name="delivery_date"
              label="Delivery Date"
              addClass="stretch margin-top-10"
              icon={<BsCalendarFill className="icon" />}
              placeHolder="Delivery Date"
            />
          ) : (
            [
              <div className="width-100" style={{ marginTop: "10px" }}>
                <div
                  className="width-100 center-hor space-between"
                  style={{ marginBottom: "10px" }}
                >
                  <div className="width-45">
                    <DropDown
                      value={orderDetail.recipient.frequency}
                      name="frequency"
                      addClass="stretch"
                      onChageSetter={(a) => {
                        setProperty(a, "recipient");
                      }}
                      options={["Weekly", "Fortnightly"]}
                      placeHolder="Order Frequency"
                    />
                  </div>
                  <div className="width-45">
                    <DropDown
                      value={orderDetail.recipient.delivery_day}
                      addClass="stretch"
                      name="delivery_day"
                      onChageSetter={(a) => {
                        setDeliveryDay(a.value);
                        setProperty(a, "recipient");
                      }}
                      options={weekDays}
                      makeIndexValue={true}
                      valueType="Number"
                      placeHolder="Delivery Day"
                    />
                  </div>
                </div>
                <DatePicker
                  value={orderDetail.recipient.order_start_date || null}
                  disabled={false}
                  onChageSetter={(a) => {
                    setOrderStartDate(a.value);
                    setProperty(a, "recipient");
                  }}
                  name="order_start_date"
                  label="Order Start Date"
                  addClass="stretch"
                  icon={<BsCalendarFill className="icon" />}
                  placeHolder="Order Start Date"
                />
              </div>,
            ]
          )}
        </div>
        <div className="col-md-1"></div>
        <div className="col-lg-3 col-md-4 col-sm-12 data-row">
          <header>Recipient</header>
          <TextInputField
            value={orderDetail.recipient.recipient_name}
            onChageSetter={(a) => {
              setProperty(a, "recipient");
            }}
            name="recipient_name"
            addClass="stretch"
            placeHolder="Recipient`s Name"
          />
          <TextInputField
            value={orderDetail.recipient.recipient_phone}
            onChageSetter={(a) => {
              setProperty(a, "recipient");
            }}
            name="recipient_phone"
            addClass="stretch"
            placeHolder="Recipient`s Phone Number"
          />
          <LocationInputField
            onChageSetter={(a) => {
              setProperty(a, "recipient");
            }}
            value={
              JSON.parse(orderDetail.recipient.delivery_location || "{}")
                .name || ""
            }
            name="delivery_location"
            addClass="stretch font-medium"
            placeHolder="Delivery Location"
          />
          <DropDown
            value={orderDetail.recipient.delivery_area}
            addClass="stretch"
            name="delivery_area"
            onChageSetter={(a) => {
              setProperty(a, "order");
            }}
            options={["North", "City", "South", "West"]}
            placeHolder="Delivery Area"
          />
          <TextInputField
            value={orderDetail.recipient.time_of_delivery}
            onChageSetter={(a) => {
              setProperty(a, "recipient");
            }}
            name="time_of_delivery"
            addClass="stretch"
            placeHolder="Delivery Time"
          />
          <TextInputField
            value={orderDetail.order.order_comment}
            onChageSetter={(a) => {
              setProperty(a, "order");
            }}
            name="order_comment"
            addClass="stretch"
            placeHolder="Comment"
          />
          <TextInputField
            value={orderDetail.order.card_message}
            textArea={true}
            rows={3}
            onChageSetter={(a) => {
              setProperty(a, "order");
            }}
            name="card_message"
            addClass="stretch"
            placeHolder="Card Message"
          />
          <LabeledCheckBox
            font="font-xmedium"
            setter={() => {
              setProperty(
                {
                  name: "autority_to_leave",
                  value: !orderDetail.recipient.autority_to_leave,
                },
                "recipient"
              );
            }}
            label="Authority to leave"
            checked={orderDetail.recipient.autority_to_leave}
          />
        </div>
        <div className="col-md-1"></div>
        <div className="col-lg-3 col-md-2 col-sm-12 data-row">
          <header>Select Products</header>
          <ButtonWithIcon
            clickTo={() => {
              setSelectProduct((prev) => !prev);
            }}
            value="Select Flower Arrangment"
            icon={<IconsAI.AiOutlinePlus className="icon XL width-100" />}
          />
          <OrdersPreSummaryTable
            data={[]}
            wo_id={id}
            settings={settings}
            selectProduct={selectProduct}
            selectType={"products"}
            product_category={"products"}
            edittingWO={props.editWo}
            removeProduct={removeProduct}
            preSelected={selectedProductPreView}
            edit={Boolean(Object.keys(selectedProductPreView).length)}
            setSelectProduct={(a) => {
              setSelectProduct(a);
            }}
            setAlertDetails={(a) => {
              setAlert(a);
            }}
            syncOrderedProducts={(prod) => {
              setSelectedProducts(prod);
            }}
            selectedProductEdit={(a) => {
              setSelectProduct(true);
              setSelectedProductPreView(a);
            }}
          />

          <DropDown
            value={(orderDetail.price_detail || {}).delivery_fee}
            fieldValue={(orderDetail.price_detail || {}).custom_delivery_fee}
            conditionalInputField={{
              on: ["Custom Delivery Fee"],
              property: "custom_delivery_fee",
              placeHolder: "Custom Delivery Fee ($)",
            }}
            addClass="stretch"
            name="delivery_fee"
            onChageSetter={(a) => {
              setProperty(a, "price_detail");
            }}
            options={(settings.delivery_fee || "")
              .split(",")
              .map((each) => Number(each).toFixed(2))}
            displayUnit={"$"}
            usePropertyNameAsValue={true}
            placeHolder="Delivery Fee"
          />
          {selectedProducts.length ? (
            <PriceTag
              delivery_fee={
                (orderDetail.price_detail || {})[
                  (orderDetail.price_detail || {}).delivery_fee ===
                  "Custom Delivery Fee"
                    ? "custom_delivery_fee"
                    : "delivery_fee"
                ]
              }
              gts={true}
              gstVal={(orderDetail.price_detail || {}).gst || 0.1}
              price={{
                message: "Total Price",
                value: (orderDetail.price_detail || {}).total_price,
              }}
            />
          ) : (
            ""
          )}
        </div>
        <div className="actions row">
          <div className="col-md-2 col-sm-5">
            <ButtonWithIcon
              value="Cancel"
              clickTo={() => {
                navigate("/WorkOrderList/retail");
              }}
              icon={
                <IconsAI.AiOutlineArrowLeft className="icon XL width-100" />
              }
            />
          </div>
          <div className="col-md-8 col-sm-2"></div>
          <div className="col-md-2 col-sm-5">
            <ButtonWithIcon
              iconSideRight={true}
              value="Next"
              clickTo={() => {
                if (isOrderValid()) {
                  setShowSummary((prev) => !prev);
                }
              }}
              icon={
                <IconsAI.AiOutlineArrowRight className="icon XL width-100" />
              }
            />
          </div>
        </div>
      </div>
      {false && (
        <ProductTypeSelectorList
          settings={settings}
          preSelected={selectedProductPreView}
          edit={Boolean(Object.keys(selectedProductPreView).length)}
          select="products"
          hidder={() => {
            setSelectProduct((prev) => !prev);
            setSelectedProductPreView({});
          }}
          addproduct={(prod, replace = false) => {
            if (props.editWo && !replace) {
              prod.newPrdt = true;
            }
            setSelectedProducts((prev) => {
              if (!replace) {
                return [...prev, prod];
              } else {
                if (!prod.newPrdt) {
                  prod.editProduct = true;
                }
                let arry = [...prev];
                arry[prod.array_index] = prod;
                return arry;
              }
            });
          }}
        />
      )}

      <OverLayInputSummary
        gts={true}
        toggleDisplay={() => {
          setShowSummary((prev) => !prev);
        }}
        addClass={showSummary ? "active" : ""}
        orderDetail={orderDetail}
        loading={loading}
        submitHandler={() => {
          submitHandler();
        }}
      />
    </div>
  );
}
