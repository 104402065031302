import { useState, useEffect, createRef } from "react";
import Draggable from "react-draggable";
import {
  MdCancel,
  MdSaveAs,
  MdEditNote,
  MdDelete,
  MdPrint,
  MdPreview,
} from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import {
  TextInputField,
  LoadingWait,
  RunDeliveriesListRow,
  RunListRowHead,
  shorten_product_list_2,
  ButtonFlat,
  replaceCharacter,
} from "../components";
import { BsCheckCircleFill } from "react-icons/bs";
import { MdOutlineError } from "react-icons/md";
import axios from "axios";
import { BASE_SERVER_URL } from "../sampleData";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

// import ReactToPdf from 'react-to-pdf'
const ReactToPdf = "react-to-pdf";

function DownloadPDFButton(id) {
  //     const handleDownloadPDF = () => {
  // };
  const input = document.getElementById(id);
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(imgData, "PNG", 0, 0, 210, 297); // A4 size: 210mm × 297mm
    pdf.save("downloaded-pdf.pdf");
  });
}

export function DriverRunListDetail(props) {
  const ref = createRef();
  function removeRun(id) {
    let url = BASE_SERVER_URL + "/run/removeRun";
    axios.post(url, { run_id: id }).then((response) => {
      if (response.data.status === "success") {
        props.onDeleteSuccess();
        toast.success("Run Sheet Deleted Successfully", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Error Deleting Run Sheet, Please try again", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  }

  // let orders = props.orders.deliveries ||  []
  let orders = MergeWorkOrders(props.orders.deliveries || []);
  // console.log('orders detail selected ', props.orders)
  return (
    <div className="drag-drop-area detail relative xlight-box-shadow">
      <div className="action float-left center-hor">
        <MdDelete
          onClick={() => {
            // confirm('Confirm Deleting the Run Sheet')?
            removeRun(props.orders._id);
            // :''
          }}
          className="icon mid white width-auto margin-LR-15"
        />
        <MdEditNote
          onClick={props.toggleEdit}
          className="icon mid white width-auto margin-LR-15"
        />
        {/* <MdPreview onClick={props.togglePrintPreview} className='icon mid white width-auto margin-LR-15' /> */}
      </div>
      <div className="action center-hor">
        {/* <MdEditNote onClick={props.toggleEdit} className='icon large white width-auto margin-LR-15' /> */}
        <MdPrint
          onClick={() => {
            window.open("/run-sheet/print/" + props.orders._id, "_blank");
          }}
          className="icon mid white margin-LR-15"
        />
        <MdCancel
          onClick={props.hidder}
          className="icon mid white margin-LR-15"
        />
      </div>
      <div ref={ref} className="print">
        <div className="body width-100">
          <div className="head absolute-center xlight-box-shadow">
            <span className="font-medium">Delivery Run</span>
            <span style={{ marginLeft: "10px" }}>
              <TbTruckDelivery className="icon white large" />
            </span>
          </div>
          <div
            style={{ fontSize: "12px" }}
            className="center-hor space-between padding-TL-4-5"
          >
            <span className="absolute-center width-30">
              <bold>Delivery Date</bold> : {props.orders.delivery_date}
            </span>
            <span className="width-30 center-hor">
              <bold>Vehicel No. Plate</bold> : {props.orders.vehicle_number}{" "}
            </span>
            <span className="width-30 center-hor">
              <bold>Driver's Name</bold> : {props.orders.drivers_name}
            </span>
          </div>

          <div
            style={{
              minHeight: "800px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="width-100">
              <RunListRowHead new={props.new} />
              {orders.map((each, index) => {
                return (
                  <RunDeliveriesListRow
                    num={index + 1}
                    edit={props.edit}
                    new={props.new}
                    remove={() => {
                      let ids =
                        each.order_type === "Funeral"
                          ? each.products.map((a) => a.parent_funeral_id)
                          : each.work_order_id;
                      props.removeFromRun(
                        index,
                        ids,
                        each.products[0].product_type === "Casket cover"
                          ? "casket cover"
                          : "tributes"
                      );
                    }}
                    key={index}
                    data={each}
                  />
                );
              })}
            </div>
            <div className=" width-100" style={{ marginTop: "50px" }}>
              <div
                className="width-100 height-stretch-container"
                style={{ background: "#fafafa", marginBottom: "1px" }}
              >
                <div
                  className="width-20 absolute-center padding-10 bold-1 "
                  style={{
                    background: "#e7e7e7",
                    fontSize: "12px",
                    marginRight: "10px",
                  }}
                >
                  Description
                </div>
                <div className="width-75 font-medium">
                  {props.orders.description}
                </div>
              </div>
              <div
                className="width-100 height-stretch-container"
                style={{ background: "#fafafa" }}
              >
                <div
                  className="width-20 absolute-center bold-1 padding-10 "
                  style={{
                    background: "#e7e7e7",
                    fontSize: "12px",
                    marginRight: "10px",
                  }}
                >
                  Comment
                </div>
                <div className="width-75 font-medium">
                  {props.orders.comment}
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export function DriverRunListMaking(props) {
  const [submitLoading, setSubmitLoading] = useState(false);
  let ordersORGN = props.orders || [];
  let orders = MergeWorkOrders(props.orders || []);

  let obj = !props.edit
    ? {
        drivers_name: "",
        vehicle_number: "",
        delivery_date: props.delivery_date || "",
        description: "",
        comment: "",
        cross_checked: "",
      }
    : props.runDetail;

  const [runDetail, setRunDetail] = useState(obj);
  useEffect(() => {
    setRunDetail((prev) => {
      return {
        ...prev,
        delivery_date: orders.length ? orders[0].delivery_date : "",
      };
    });
  }, [props.orders]);

  function submitRun() {
    let url = props.edit
      ? BASE_SERVER_URL + "/run/editRun"
      : BASE_SERVER_URL + "/run/AddNew";
    let allProducts = ordersORGN.map((each, index) => {
      let {
        product_type,
        work_order_id,
        parent_funeral_id,
        name,
        fd_code,
        time,
        location,
        products,
        late_person_name,
        order_type,
      } = each;
      return {
        product_type,
        work_order_id,
        parent_funeral_id,
        name,
        fd_code,
        time,
        location,
        late_person_name,
        order_type,
        shortened: JSON.stringify(
          shorten_product_list_2(products || "[]", true)
        ),
        products:
          typeof products === "string" ? products : JSON.stringify(products),
      };
    });
    let data = { ...runDetail, deliveries: allProducts };
    if (props.edit) {
      data = { run_id: props.runDetail._id, update_data: data };
    }
    setSubmitLoading(true);

    axios
      .post(url, data)
      .then((response) => {
        setSubmitLoading(false);
        if (response.data.status === "success") {
          props.setSubmited(true);
          toast.success("Run Sheet Successfully Saved", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          props.hidder();
        } else {
          toast.error("Error Saving Run Sheet", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          props.hidder();
        }
      })
      .catch(() => {
        setSubmitLoading(false);
      });
  }

  function updateDetail(a) {
    setRunDetail((prev) => {
      localStorage.setItem(
        "run-details",
        JSON.stringify({ ...prev, [a.name]: a.value })
      );
      return { ...prev, [a.name]: a.value };
    });
  }

  const body = (
    <div
      className={
        !props.edit
          ? "drag-drop-area width-100 light-box-shadow"
          : "drag-drop-area relative  xlight-box-shadow"
      }
    >
      <div className="action center-hor">
        {submitLoading ? (
          <LoadingWait
            addStyle={{
              background: "#AAE49E",
              width: "132px",
              height: "40px",
              marginRight: "30px",
            }}
          />
        ) : (
          <button onClick={submitRun}>
            {props.edit ? "Update Run Sheet" : "Save Run Sheet"}
          </button>
        )}
      </div>
      <div className="">
        <div className="body">
          <div className="head page absolute-center xlight-box-shadow">
            <span className="cursor font-medium touchable">Delivery Run</span>
            <span style={{ marginLeft: "10px" }}>
              <TbTruckDelivery className="icon white mid" />
            </span>
          </div>
          <div
            className="center-hor space-between padding-10"
            style={{ fontSize: "12px" }}
          >
            <span className="width-auto font-medium ">
              <bold>Delivery Date</bold> :{props.delivery_date}
            </span>
            <span className="width-auto center-hor font-medium ">
              <bold>Vehicle No. Plate</bold> :
              <TextInputField
                value={runDetail.vehicle_number}
                name="vehicle_number"
                onChageSetter={updateDetail}
                addClass="flat-white"
              />
            </span>
            <span className="width-auto center-hor font-medium ">
              <bold>Driver's Name</bold> :
              <TextInputField
                value={runDetail.drivers_name}
                name="drivers_name"
                onChageSetter={updateDetail}
                addClass="flat-white"
              />
            </span>
          </div>

          <div className="" style={{ minHeight: "500px" }}>
            <RunListRowHead edit={props.edit} new={props.new} />
            {orders.map((each, index) => {
              return (
                <RunDeliveriesListRow
                  edit={props.edit}
                  new={props.new}
                  remove={() => {
                    props.removeFromRun(
                      index,
                      each.order_type === "Funeral"
                        ? each.parent_funeral_id
                        : each.work_order_id,
                      each.products[0].product_type === "Casket cover"
                        ? "casket cover"
                        : "tributes",
                      each.order_type === "Funeral" ? true : false,
                      each.location
                    );
                  }}
                  num={index + 1}
                  key={index}
                  data={each}
                />
              );
            })}
          </div>
        </div>
        <div className="center-hor font-medium space-around width-100 padding-10">
          <span className="width-100 center-hor font-medium bold-1">
            <bold className="width-10">Description : </bold>
            <div className="width-90">
              <TextInputField
                textArea={true}
                rows={2}
                value={runDetail.description}
                name="description"
                onChageSetter={updateDetail}
                addClass="flat-white width-100"
              />
            </div>
          </span>
        </div>
        <div className="center-hor font-medium space-around width-100 padding-10">
          <span className="width-100 center-hor font-medium bold-1">
            <bold className="width-10">Comment : </bold>
            <div className="width-90">
              <TextInputField
                textArea={true}
                rows={2}
                value={runDetail.comment}
                name="comment"
                onChageSetter={updateDetail}
                addClass="flat-white width-100"
              />
            </div>
          </span>
        </div>
      </div>
    </div>
  );

  if (!props.edit) {
    return <Draggable handle=".cursor">{body}</Draggable>;
  } else {
    return body;
  }
}

export function PopUpAlert(props) {
  const AlertIcons = {
    success: <BsCheckCircleFill className="icon green large" />,
    error: <MdOutlineError className="icon red large" />,
    causion: <BsCheckCircleFill className="icon yellow large" />,
  };
  return (
    <div
      className={
        "popup fullscreen absolute-center " +
        ((props.info || {}).blurBackground ? "blur-bg" : "")
      }
    >
      <div
        className={
          "popup-alert light-box-shadow " + (props.info || {}).cardClass
        }
        style={{ borderRadius: "8px" }}
      >
        <div
          className="width-100 absolute-center relative"
          style={{ padding: "20px 5px 30px 5px" }}
        >
          {!(props.info || {}).noIcon && (
            <div className="width-10 absolute-center">
              {(props.info || {}).displayIcon ||
                AlertIcons[(props.info || {}).type || "error"]}
            </div>
          )}
          <div className="width-90 text-center center-ver-col font-medium">
            <p className={"bold-1 " + (props.info || {}).titleClass}>
              {(props.info || {}).title}
            </p>
            {(props.info || {}).htmlMEssage || (
              <p style={{ fontSize: "11px" }} className="absolute-center">
                {(props.info || {}).message}
              </p>
            )}
          </div>
        </div>
        {(props.info || {}).confirmPrompt ? (
          <div className="width-100 center-hor">
            <ButtonFlat
              dotColor="#eaeaea"
              addClass="font-medium thin-flat border-radius-8 width-50 margin-5"
              style={{ background: "#eaeaea", width: "100%", height: "35px" }}
              loading={false}
              value={(props.info || {}).cancelValue || "Close"}
              clickTo={() => {
                props.onClose();
              }}
            />
            <ButtonFlat
              addClass="font-medium thin-flat warnning-btn border-radius-8 width-50 margin-5"
              style={{
                background: "#eaeaea",
                width: "100%",
                height: "35px",
                borderRadius: "8px",
                marginRight: "4px",
              }}
              dotColor={"#b79855"}
              loading={props.info.confirmLoading || false}
              loadingValue={(props.info || {}).loading}
              value={(props.info || {}).confirmValue || "Confirm"}
              clickTo={() => {
                props.info.callback();
              }}
            />
          </div>
        ) : (
          <div className="width-100 absolute-center">
            <ButtonFlat
              dotColor="#eaeaea"
              addClass="font-medium thin-flat border-radius-8 width-100 margin-5"
              style={{ background: "#eaeaea", width: "100%", height: "35px" }}
              loading={false}
              value={props.btnMessage || "Close"}
              clickTo={() => {
                props.onClose();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function MergeWorkOrders(order) {
  let objs = {};
  order.forEach((each, index) => {
    try {
      if (each.order_type === "Funeral") {
        let locName = JSON.parse(each.location || "{}");
        locName = locName.coordinates
          ? locName.coordinates.lat + "_" + locName.coordinates.lng
          : replaceCharacter(locName.name, " ", "").toLowerCase();
        objs[each.parent_funeral_id + "@" + locName] = objs[
          each.parent_funeral_id + "@" + locName
        ]
          ? [...objs[each.parent_funeral_id + "@" + locName], each]
          : [each];
      } else {
        objs[each.work_order_id] = [each];
      }
    } catch (err) {}
  });

  let value = [];
  Object.keys(objs).forEach((each, index) => {
    let one = { ...objs[each][0] };
    let products = [];
    delete one.products;

    objs[each].forEach((each2, index) => {
      products = [
        ...products,
        ...(typeof each2.products === "string"
          ? JSON.parse(each2.products)
          : each2.products),
      ];
    });

    one.products = products;
    value[index] = one;
  });
  // console.log('order merging out ',value)
  return value;
}

export function RunSheetMakingPage(props) {
  props.hideHeader();
  const [orders, setOrders] = useState(
    props.editRun ? localStorage.getItem("selected-run-orders") : "[]"
  );
  const [submited, setSubmited] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});

  // useEffect(()=>{
  //     refresh()
  // },[])
  let detail;
  useEffect(() => {
    if (props.editRun) {
      detail = JSON.parse(localStorage.getItem("run-details") || "{}");
      setOrderDetail(detail);
    }
    // console.log('run details here   ',detail)
  }, []);
  useEffect(() => {
    if (localStorage.getItem("selected-run-orders") !== orders) {
      localStorage.setItem("selected-run-orders", orders);
    }
  }, [orders]);

  function refresh() {
    if (localStorage.getItem("run-sheet-state") === "cancel") {
      localStorage.setItem("selected-run-orders", "[]");
      localStorage.setItem("selected-products", "{}");
      localStorage.setItem("run-details", "{}");
      localStorage.setItem("old-run-sheets", "[]");
      window.close();
      return false;
    }
    if (localStorage.getItem("selected-run-orders") !== "") {
      setOrders(localStorage.getItem("selected-run-orders"));
    }
  }
  useEffect(() => {
    refresh();
    window.addEventListener("storage", (event) => {
      refresh();
    });
  }, []);
  return (
    <div className="xlight-gold-bg fullscreen relative width-100 absolute-center padding-10">
      {submited ? (
        <PopUpAlert
          info={{
            type: "success",
            title: "Creating New Delivery Run Sheet",
            message: "Delivery Run Sheet Created Successfully",
          }}
          onClose={() => {
            localStorage.setItem("run-sheet-state", "closed");
            window.close();
          }}
        />
      ) : (
        ""
      )}
      <div className="width-80">
        <DriverRunListMaking
          hidder={() => {
            // localStorage.setItem('run-sheet-state','closed')
            // window.close()
          }}
          edit={props.editRun}
          runDetail={JSON.parse(localStorage.getItem("run-details") || "{}")}
          setSubmited={(a) => {
            setSubmited(a);
          }}
          new={!props.editRun}
          orders={orders ? JSON.parse(orders) : []}
          delivery_date={
            props.editRun
              ? orderDetail.delivery_date
              : JSON.parse(orders).length
              ? JSON.parse(orders)[0].delivery_date
              : ""
          }
          removeFromRun={(index, _id, type, funeral = false, deliLoc = "") => {
            // console.log('filtered params ',_id, type)
            let selectes = JSON.parse(
              localStorage.getItem("selected-products")
            );
            let selectesCP = JSON.parse(JSON.stringify(selectes));

            if (funeral) {
              Object.keys(selectes).forEach((each) => {
                if (selectes[each].parent_funeral_id === _id) {
                  delete selectesCP[each][type];
                }
              });
            } else {
              delete selectesCP[_id];
            }
            localStorage.setItem(
              "selected-products",
              JSON.stringify(selectesCP)
            );
            setOrders((prev) => {
              let filtered = JSON.parse(orders).filter((a, b) => {
                return funeral
                  ? a.parent_funeral_id !== _id ||
                      JSON.parse(deliLoc || "{}").name !==
                        JSON.parse(a.location || "{}").name
                  : b !== index;
              });
              localStorage.setItem(
                "selected-run-orders",
                JSON.stringify(filtered)
              );
              // return JSON.stringify(JSON.parse(orders).filter((a,b) => { return b !== index}))
              return JSON.stringify(filtered);
            });
          }}
        />
      </div>
    </div>
  );
}

function ExpandingPropmpt(props) {
  const [active, setActive] = useState(false);
  return (
    <div className="expanding-prompt xlight-box-shadow">
      <div className="msg ">
        <button
          onClick={() => {
            setActive((prev) => !prev);
          }}
        >
          Click
        </button>
      </div>
      <div className={(active ? "active" : "") + " opts center-hor"}>
        <button>Casket Cover</button>
        <button>Tribute</button>
      </div>
    </div>
  );
}

function ToolTipPropmpt(props) {
  return (
    <div className="tooltip-prompt xlight-box-shadow relative">
      <div className="msg ">Message</div>
      <div className="opts">
        <button>Casket Cover</button>
        <button>Tribute</button>
      </div>
    </div>
  );
}
