import { useEffect,useState } from 'react'
import {DatePickerSmall,TableListInventory,ToReadableDate} from '../components'
import 'bootstrap/dist/css/bootstrap.css'
import axios from 'axios'
import moment from 'moment'
import {BsCalendarFill} from 'react-icons/bs'

import {Pagenation} from '../components/pagenation'
import {DriverRunListDetail,DriverRunListMaking} from '../components/movableDragDropArea'

import {BASE_SERVER_URL} from '../sampleData'

const thead = ["Type","DASd",]

export function InventoryList(props){
document.title = props.title || 'Ray`s Florist App'

const [selectedRun,setSelectedRun] = useState({})
const [flowers,setFlowers] = useState({})
const [listIsLoading,setListIsLoading] = useState(false)
const [notFound,setNotFound] = useState(false)
//filters
const [listDate,setListDate] = useState(moment(new Date()).format('DD/MM/YYYY'))
const [orderType,setOrderType] = useState('Funeral')
const [allPage,setAllPage] = useState(0)
const [page,setPage] = useState(1)
const [sortBy,setSortBy] = useState('delivery_date')
const [query,setQuery] = useState('')

const pageLimit = 5; 

useEffect(()=>{
    fetchRuns()
},[listDate,orderType,page,query,sortBy])

function fetchRuns(){
    setListIsLoading(true)
    let url = BASE_SERVER_URL+'/inventory/getAllByDate'
    let filter = {
            page: page,  
            byQuery: query, 
            limit: pageLimit, 
            sortBy : sortBy,
            // date: listDate? moment(listDate).format("MM-DD-YYYY"):''
        }
        setNotFound(false)
    axios.post(url,{date:listDate}).then((doc)=>{
        if(doc.data.status === 'success'){
            setFlowers(doc.data.response) 
            setNotFound(doc.data.notFound) 
            setListIsLoading(false)
            // setSelectedRun(doc.data.response[0])
            setAllPage(Math.ceil(doc.data.responseLength / pageLimit))
        }
    }).catch(function (error) {
        console.log(error);
    })  
}
function refreshList(){ 
    fetchRuns()
    setSelectedRun({})
}
    useEffect(()=>{
        // console.log('use effect on selected run ',selectedRun)
        // console.log('use effect on selected deliveries ',selectedRun.deliveries)
    },[selectedRun])

return <div className='data-input center-ver-col'>
                <div className={(!Object.keys(selectedRun).length?'absolute-center ':'')+'row width-100 relative'}>
                        <header className='large padding-10 center-hor'>
                            Flower Inventory List 
                            </header>
                    <div className={!Object.keys(selectedRun).length?'col-lg-6 col-md-10 col-sm-12 relative':'col-lg-6 col-md-4 col-sm-12 relative'}>
                            <div className='width-100 center-hor'>
                                    <span className='font-medium margin-10'>Delivery Date </span> 
                                    <DatePickerSmall 
                                            value={listDate} 
                                            onChageSetter={(a)=>{setListDate(ToReadableDate(a.value,[],'/'))}} 
                                            name='delivery_date' 
                                            label='Delivery Date' addClass='' 
                                            icon={<BsCalendarFill className='icon'/>} 
                                            placeHolder='Delivery Date'
                                            light={true}
                                            clearDate={()=>{setListDate('')}}
                                            defaultToday={true}
                                            noClearButton={true}
                                             />
                            </div>
                            {notFound && <div className='width-100 xlight-box-shadow padding-10 font-medium color-red'>
                                <bold>Caution</bold> Some ordered products refer to products that do not have respective flower contents registered under them
                            </div>}
                        <TableListInventory data={flowers} loading={listIsLoading} listStart={(page-1) * pageLimit} toggleSelected={(a)=>{setSelectedRun(a)}}/>
                    <div className='row width-100 absolute-center'>
                    <div className='col-lg-10 col-md-10 col-sm-12'>
                        {/* <Pagenation pages={allPage} setActivePage={(a)=>{setPage(a)}}/> */}
                    </div>
                </div>
                    </div>
            </div>
        </div>
}