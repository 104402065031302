import { usestate, useEffect, useState } from "react";
import axios from "axios";
import profile from "../images/profile.jpg";
import { MdCancel } from "react-icons/md";
import { toast } from "react-toastify";

import { CollapsableUserDisplay } from "../components/collapsableUserDisplay";
import {
  ButtonFlat,
  SearchField,
  ButtonWithIcon,
  TextInputField,
  LoadingWait,
  LoadingDiv,
  ImageDisplay,
  DropDown,
} from "../components";

import { AiOutlinePlus } from "react-icons/ai";
import { FaUserTie, FaUser } from "react-icons/fa";

import { BASE_SERVER_URL, BASE_SPACE_URL } from "../sampleData";

export function UsersList(props) {
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState("");
  const [adduser, setAdduser] = useState(false);
  const [editUser, setEditUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  useEffect(() => {
    refreshList();
  }, []);

  function refreshList() {
    let url = BASE_SERVER_URL + "/users/getAll";
    setLoading(true);
    axios
      .get(url)
      .then((response) => {
        setLoading(false);
        setUsers(response.data.response);
      })
      .catch(() => {
        setLoading(false);
      });
  }
  function deleteUser(id) {
    // if(!confirm('Delete User?')){
    // return false
    // }
    let url = BASE_SERVER_URL + "/users/removeUser/" + id;
    setDeleteLoading(true);
    axios
      .get(url)
      .then((response) => {
        refreshList();
        setDeleteLoading(false);
      })
      .catch(() => {
        setDeleteLoading(false);
      });
  }
  return (
    <div className="width-100 absolute-center margin-top-50">
      <div className="width-75">
        <header>Users List</header>
        <div className="row width-100">
          <div className="row width-100 space-between">
            <div className="col-lg-2 col-md-4 col-6-sm">
              <ButtonWithIcon
                addClass="width-auto margin-auto stretch-height font-small thin"
                value="Add User"
                iconWidth="30%"
                borderRad="6px"
                clickTo={() => {
                  setAdduser(true);
                }}
                icon={<AiOutlinePlus className="icon mid white width-100" />}
              />
            </div>
            <div className="col-lg-4 col-md-4 col-6-sm"></div>
          </div>
          {loading ? (
            <LoadingDiv
              amount={3}
              noDelay={true}
              speed={2}
              CoverDivClass={"col-lg-4 col-md-6 col-sm-12 margin-top-10"}
              height={"95px"}
            />
          ) : (
            users.map((each, index) => {
              return (
                <div className="col-md-4 col-sm-12">
                  <CollapsableUserDisplay
                    deleteLoading={deleteLoading}
                    editUser={() => {
                      setEditUser(each);
                    }}
                    deleteUser={() => {
                      deleteUser(each._id);
                    }}
                    key={index}
                    detail={each}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
      {(Object.keys(editUser).length > 0 || adduser) && (
        <AdduserOverlay
          editing={Boolean(Object.keys(editUser).length)}
          editUser={editUser}
          refresh={() => {
            refreshList();
          }}
          hidder={() => {
            setEditUser({});
            setAdduser(false);
          }}
        />
      )}
    </div>
  );
}
function AdduserOverlay(props) {
  // console.log("user detail chage ", props.editUser);
  // const [userDetail,setUserDetail] = useState(Object.keys(props.editUser).length > 0? props.userDetail : {})
  const [userDetail, setUserDetail] = useState(
    props.editing
      ? {
          full_name: props.editUser.full_name,
          userName: props.editUser.userName,
          password: props.editUser.password,
          access: props.editUser.access,
        }
      : {}
  );
  const [loading, setLoading] = useState(false);
  const [imageSavingLoading, setImageSavingLoading] = useState(false);
  const [images, setImages] = useState(null);

  useEffect(() => {
    if (props.editing) {
      setUserDetail({
        full_name: props.editUser.full_name,
        userName: props.editUser.userName,
        password: props.editUser.password,
        access: props.editUser.access,
      });
    }
  }, [props.editUser]);

  useEffect(() => {
    // console.log("user detail chage hook ", userDetail);
  }, [userDetail]);

  const handleFileInput = (event) => {
    setImages(event.target.files[0]);
  };

  const UploadImage = () => {
    setImageSavingLoading(true);
    const formData = new FormData();
    formData.append("image", images);

    axios
      .post(BASE_SERVER_URL + "/upload-image/users", formData)
      .then((data) => {
        setImageSavingLoading(false);
        if (data.data.status === "success") {
          //   console.log("Image upload successful:", data);
          setUserDetail((prev) => {
            let newObj = { ...prev };
            newObj.thumb = data.data.fileName;
            return newObj;
          });
        } else {
          toast.error("Error uploading file", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "light",
          });
        }
        setImageSavingLoading(false);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
        setImageSavingLoading(false);
        toast.error("Error uploading file : " + error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "light",
        });
      });
  };

  function addUser() {
    let url =
      BASE_SERVER_URL + (props.editing ? "/users/editUser" : "/users/addNew");
    let editData;
    if (props.editing) {
      editData = { ...userDetail };
      delete editData._id;
    }
    // console.log('editing check ',userDetail, {id: userDetail._id, updateData: editData}, url, props.editing)
    // return false
    let detail = !props.editing
      ? { userDetail: userDetail }
      : { id: props.editUser._id, updateData: editData };
    setLoading(true);
    axios
      .post(url, detail)
      .then((response) => {
        props.refresh();
        props.hidder();
        setLoading(false);
      })
      .catch((err) => {
        alert(err);
        props.hidder();
        setLoading(false);
      });
  }
  function populateUserName(a) {
    let username = a.value;
    username = username.toLowerCase().replaceAll(" ", "_");
    setUserDetail((prev) => {
      return { ...prev, userName: username, ...{ [a.name]: a.value } };
    });
  }
  return (
    <div
      className="popup fullscreen center-ver "
      style={{ paddingTop: "70px" }}
    >
      <div className="popup-window xlight-box-shadow relative">
        <div className="float-right width-auto" style={{ marginTop: "5px" }}>
          <MdCancel
            onClick={() => {
              props.hidder();
              props.refresh();
            }}
            className="icon white large"
          />
        </div>
        <div class="title thin absolute-center">
          {props.editing ? "Edit User Detail" : "Add New User"}
        </div>
        <div className="width-100 center-ver-col">
          <span style={{ padding: "25px 10px" }}>
            {(userDetail || {}).access === "Super Admin" ? (
              <FaUserTie className="icon XL grey " />
            ) : (
              <FaUser className="icon XL grey" />
            )}
          </span>
          {/* {<img src={(userDetail || {}).thumb ? BASE_SPACE_URL+''+(userDetail || {}).thumb: profile} className='profile-image-xlarge margin-5'/>} */}
          {/* <ImageDisplay src={(userDetail || {}).thumb ? BASE_SPACE_URL+''+(userDetail || {}).thumb: profile} className='profile-image-xlarge margin-5' /> */}
          {/* <ImageDisplay src={BASE_SPACE_URL+''+(userDetail || {}).thumb} className='profile-image-xlarge margin-5' /> */}
          <div className="row width-100">
            {/* {productDescription.image? <img className='' style={{height:'300px'}} src={BASE_SERVER_URL+'/'+productDescription.image}/>:''} */}
            <div className="col-lg-8 col-md-8 col-sm-8">
              {/* <input 
                                className='button flat width-100 inline-hor absolute-center bold-1 form font-medium width-stretch  padding-LR-15 light-box-shadow'
                                type="file" 
                                onChange={(event)=>{handleFileInput(event)}}
                            /> */}
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 center-hor">
              {/* <ButtonFlat 
                                value={'Upload Image'} 
                                loading={imageSavingLoading} 
                                clickTo={()=>{UploadImage()}} 
                                style={{background: '#eaeaea', width:'132px',height:'35px'}}
                                addClass='form font-medium width-stretch padding-LR-15 light-box-shadow'/> */}
            </div>
          </div>
          <div className="width-90">
            <TextInputField
              value={(userDetail || {}).full_name}
              onChageSetter={(a) => {
                populateUserName(a);
              }}
              name="full_name"
              addClass="stretch"
              placeHolder="Full Name"
            />
            <TextInputField
              value={(userDetail || {}).userName}
              onChageSetter={(a) => {
                setUserDetail((prev) => {
                  return { ...prev, ...{ [a.name]: a.value } };
                });
              }}
              name="userName"
              addClass="stretch"
              placeHolder="User Name"
            />
            <TextInputField
              value={(userDetail || {}).password}
              onChageSetter={(a) => {
                setUserDetail((prev) => {
                  return { ...prev, ...{ [a.name]: a.value } };
                });
              }}
              name="password"
              addClass="stretch"
              placeHolder="Password"
            />
            <DropDown
              value={(userDetail || {}).access}
              addClass="stretch"
              name={"access"}
              onChageSetter={(a) => {
                setUserDetail((prev) => {
                  return { ...prev, ...{ [a.name]: a.value } };
                });
              }}
              options={["Super Admin", "Admin", "Staff"]}
              placeHolder="User Access"
            />

            {loading ? (
              <LoadingWait
                addStyle={{
                  width: "100%",
                  height: "35px",
                  background: "#eaeaea",
                }}
              />
            ) : (
              <ButtonFlat
                value={props.editing ? "Save" : "Add User"}
                clickTo={addUser}
                addClass="form font-medium"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
