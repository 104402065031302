import { useState, useEffect } from "react";
import "./App.css";
import "./style.css";
import {
  BasePage,
  ButtonBigWithIcon,
  LoadingWait,
  PageLoading,
} from "./components";
import { AiOutlineShop } from "react-icons/ai";
import { TbCross } from "react-icons/tb";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import logo from "./images/logo.png";

import * as Pages from "./pages";
import DashBoard from "./pages/dashboard";
import { WorkOrderListPage } from "./pages/workOrderListPage";
import { ProductPage } from "./pages/productListPage";
import { RecurringInputPage } from "./pages/funeralOrderInputPage";
import { AddNewProductPage } from "./pages/newProduct";
// import {EditProductPage} from './pages/editProduct'
import { DelieryRunList } from "./pages/deliveryRunList";
import { InventoryList } from "./pages/inventoryList";
import { UsersList } from "./pages/users";
import { WorkHistory } from "./pages/workHistory";
import { CustomersHistory } from "./pages/customersHistory";
import { SetupPage } from "./pages/setupPage";
import { RunSheetMakingPage } from "./components/movableDragDropArea";
import { FDs } from "./pages/funeral_directors";
import { LogInPage } from "./logInPage";
import { Print } from "./pages/Print";
import { DateRangePickerer } from "./test";
import { BUpage } from "./pages/backup.js";
import { BASE_SERVER_URL } from "./sampleData";
import axios from "axios";
import { OrderedProducts } from "./pages/OrderedProducts.js";

function App() {
  // return <LogInApp />

  const [loggedOut, setLoggedOut] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [header, setHeader] = useState(true);
  const [globalState, setGlobalState] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [settings, setSetting] = useState({});

  useEffect(() => {
    console.log("global changed ", globalState);
  }, [globalState]);

  useEffect(() => {
    let url = BASE_SERVER_URL + "/settings/getAll";
    axios.get(url).then((doc) => {
      if (doc.data.status === "success") {
        setSetting(doc.data.response[0] || {});
      }
    });
    window.addEventListener("storage", (event) => {
      if (localStorage.getItem("run-sheet-state") === "closed") {
        setGlobalState(false);
      }
    });
  }, []);

  useEffect(() => {
    setPageLoading(true);
    let url = BASE_SERVER_URL + "/auth";
    axios
      .get(url, {
        headers: {
          "x-access-token": localStorage.getItem("RF-session-tocken"),
        },
      })
      .then((response) => {
        if (response.data.auth) {
          let res = response.data;
          setLoggedOut(false);
          // setCurrentUser({id: res._id, full_name: res.full_name, userName: res.userName, access: res.access})
          setCurrentUser({
            id: res._id,
            ...JSON.parse(response.data.detail || "{}"),
          });
        } else {
          setLoggedOut(true);
        }
        setPageLoading(false);
      })
      .catch((err) => {
        // setLoggedOut(true)
        setPageLoading(false);
      });
  }, []);

  if (pageLoading) {
    return <PageLoading />;
  }
  if (loggedOut) {
    return (
      <LogInPage
        setCurrent={(a) => {
          setCurrentUser(a);
        }}
        setLogOut={(a) => {
          setLoggedOut(a);
        }}
      />
    );
  }

  return (
    <div>
      <div className="app-content absolute-center">
        <BrowserRouter>
          {header && (
            <BasePage
              removeHeaderAndNav={globalState}
              currentUser={currentUser}
              logout={() => {
                localStorage.setItem("RF-session-tocken", "");
                setLoggedOut(true);
              }}
            />
          )}
          <Routes>
            <Route
              path={"/test"}
              element={<DateRangePickerer title="Test" />}
            />
            <Route
              path={"/"}
              element={
                <DashBoard currentUser={currentUser} title="DashBoard" />
              }
            />
            {/* <Route path={'/'} element={<WorkOrderListPage orderType={'Funeral'} title='Work Orders'/>}/> */}
            <Route path={"/settings"} element={<SetupPage title="Setting" />} />
            <Route
              path={"/WorkOrderList"}
              element={
                <WorkOrderListPage
                  ChangeGlobalState={(a) => {
                    setGlobalState(a);
                  }}
                  orderType={"Funeral"}
                  title="Work Orders"
                />
              }
            />
            {/* <Route path={'/WorkOrderList/:listTypeParam/:listDateParam'} element={<WorkOrderListPage orderType={'Retail'} title='Retail Work Orders'/>}/> */}
            <Route
              path={"/work-order/:type/:serial"}
              element={
                <WorkOrderListPage
                  ChangeGlobalState={(a) => {
                    setGlobalState(a);
                  }}
                  showOne={true}
                  title="Work Orders"
                />
              }
            />
            <Route
              path={"/WorkOrderList/funeral"}
              element={
                <WorkOrderListPage
                  ChangeGlobalState={(a) => {
                    setGlobalState(a);
                  }}
                  orderType={"Funeral"}
                  title="Funeral Work Orders"
                />
              }
            />
            <Route
              path={"/WorkOrderList/retail"}
              element={
                <WorkOrderListPage
                  ChangeGlobalState={(a) => {
                    setGlobalState(a);
                  }}
                  orderType={"Retail"}
                  title="Retail Work Orders"
                />
              }
            />
            <Route
              path={"/WorkOrderList/newRunSheet"}
              element={
                <WorkOrderListPage
                  ChangeGlobalState={(a) => {
                    setGlobalState(a);
                  }}
                  orderType={"Funeral"}
                  newRunSheet={true}
                  title="Make Run Sheet"
                />
              }
            />
            <Route
              path={"/editRunSheet/:runId/:runSheetDate"}
              element={
                <WorkOrderListPage
                  ChangeGlobalState={(a) => {
                    setGlobalState(a);
                  }}
                  editRun={true}
                  title="Edit Run Sheet"
                />
              }
            />
            <Route
              path="/ChooseOrderType"
              element={<ChooseOrderType title="Input Order" />}
            />
            <Route
              path="/FuneralOrderInput"
              element={
                <Pages.FuneralInputPage
                  current_user={currentUser}
                  title="Funeral Order Input"
                  orderType="Funeral"
                />
              }
            />
            <Route
              path="/ordered-products"
              element={
                <OrderedProducts
                  fromWO={true}
                  settings={settings || {}}
                  current_user={currentUser}
                  title="Ordered Products"
                  orderType="Funeral"
                />
              }
            />
            <Route
              path={"/RetailOrderInput"}
              element={
                <RecurringInputPage
                  current_user={currentUser}
                  title="Retail Order Input"
                  orderType="Retail"
                />
              }
            />
            <Route
              path="/editworkorder/funeral/:id"
              element={
                <Pages.FuneralInputPage
                  title="Edit Work Order"
                  editWo={true}
                  orderType="Funeral"
                />
              }
            />
            <Route
              path="/editworkorder/retail/:id"
              element={
                <RecurringInputPage
                  title="Edit Work Order"
                  editWo={true}
                  orderType="Retail"
                />
              }
            />
            <Route
              path={"/DeliveryRunList"}
              element={<DelieryRunList title="Delivery Run Sheets" />}
              ChangeGlobalState={(a) => {
                setGlobalState(a);
              }}
            />
            <Route
              path={"/InventoryList"}
              element={<InventoryList title="Inventory List" />}
            />
            <Route
              path={"/products"}
              element={<ProductPage title="Products" />}
            />
            <Route
              path={"/newProducts"}
              element={
                <AddNewProductPage editProduct={false} title="Add Product" />
              }
            />
            <Route
              path={"/newProducts/:id"}
              element={
                <AddNewProductPage editProduct={true} title="Edit Product" />
              }
            />
            {currentUser.access === "Super Admin" && (
              <Route path={"/users"} element={<UsersList />} />
            )}
            <Route path={"/FuneralDirectors"} element={<FDs />} />
            <Route
              path={"/RunSheetMaking"}
              element={
                <RunSheetMakingPage
                  ChangeGlobalState={(a) => {
                    setGlobalState(a);
                  }}
                  hideHeader={() => {
                    setHeader(false);
                  }}
                />
              }
            />
            <Route
              path={"/EditRunSheet"}
              element={
                <RunSheetMakingPage
                  ChangeGlobalState={(a) => {
                    setGlobalState(a);
                  }}
                  editRun={true}
                  hideHeader={() => {
                    setHeader(false);
                  }}
                />
              }
            />
            <Route path={"/work-history"} element={<WorkHistory />} />
            <Route path={"/customer-history"} element={<CustomersHistory />} />

            <Route
              path={"/deliveries/print/:filter"}
              element={
                <Print
                  print="deliveries"
                  hideHeader={() => {
                    setHeader(false);
                  }}
                />
              }
            />
            <Route
              path={"/ordered-products/print/:filter"}
              element={
                <Print
                  print="ordered-products"
                  hideHeader={() => {
                    setHeader(false);
                  }}
                />
              }
            />
            <Route
              path={"/workorder/print/:filter/:date"}
              element={
                <Print
                  print="workorder"
                  hideHeader={() => {
                    setHeader(false);
                  }}
                />
              }
            />
            <Route
              path={"/workorders/print/:filter"}
              element={
                <Print
                  print="workorders"
                  hideHeader={() => {
                    setHeader(false);
                  }}
                />
              }
            />
            <Route
              path={"/run-sheet/print/:filter"}
              element={
                <Print
                  print="runsheet"
                  hideHeader={() => {
                    setHeader(false);
                  }}
                />
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

function ChooseOrderType() {
  const navigate = useNavigate();
  return (
    <div className="order-type-select">
      <h3>Select Type of Order</h3>
      <span className="order-type">
        <ButtonBigWithIcon
          clickTo={() => {
            navigate("/FuneralOrderInput");
          }}
          value="Funeral"
          icon={<TbCross className="icon XL width-100" />}
        />
        <ButtonBigWithIcon
          clickTo={() => {
            navigate("/RetailOrderInput");
          }}
          value="Retail"
          icon={<AiOutlineShop className="icon XL width-100" />}
        />
        {/* <Comp.ButtonBigWithIcon clickTo={()=>{navigate('/RetailOrderInput')}} value='Corporate' icon={<IconsMD.MdCorporateFare className='icon XL width-100'/>}/> */}
      </span>
    </div>
  );
}

function ErrorLogPage() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(BASE_SERVER_URL + "/errorLogs")
      .then((doc) => {
        return doc.json();
      })
      .then((doc) => {
        console.log(doc);
        setData(doc);
      });
  }, []);

  return (
    <div className="container">
      <div className="message header">
        <span className="text">Error Message</span>
        <span className="date">Error date</span>
      </div>
      {data.map((x, i) => {
        // console.log(x)
        return (
          <div key={i} className="message">
            <span className="text">{x.message}</span>
            <span className="date">{x.date}</span>
          </div>
        );
      })}
    </div>
  );
}

export default App;
