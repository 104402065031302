import { useEffect, useState } from "react";
import {
  BiArrowToTop,
  BiArrowToBottom,
  BiNotification,
  BiDetail,
} from "react-icons/bi";
import { MdCancel, MdNotifications } from "react-icons/md";
import { BASE_SERVER_URL } from "../sampleData";
import axios from "axios";
import {
  DatePicker,
  DateTimeRangePicker,
  LoadingDiv,
  LoadingWait,
  PageLoading,
  ToReadableDate,
} from "../components";
import { BsCalendar, BsCalendarFill } from "react-icons/bs";
import moment from "moment";
import DoughnutChart from "../components/DoughnutChart";
import MapComponent from "../components/MapDisplay";
import DateRangePicker from "../components/DateRangePicker";
import LineChart from "../components/LineChart";
import { exportToExcel } from "react-json-to-excel";
import ExportTools from "../components/ExportTools";
import ProductReveneueDetailCard from "../components/ProductRevenueDetailCard";
import { addCommasToNumber } from "../utils";
import { DateRangePickerOverlay } from "../components/DateRangePickerOverlay";

function sortObject(obj) {
  // Convert the object to an array of [key, value] pairs
  const entries = Object.entries(obj);
  // Sort the array by the numeric property values
  entries.sort((a, b) => b[1] - a[1]);

  // Convert the sorted array back to an object
  const sortedObject = {};
  entries.forEach((entry) => {
    const [key, value] = entry;
    sortedObject[key] = value;
  });

  return sortedObject;
}

function extractDeliveryLocations(products) {
  let locs = [];
  Object.keys(products).forEach((keys) => {
    products[keys].forEach((product) => {
      let loc = JSON.parse(product.delivery_location1 || "{}").coordinates;
      if (loc.lat) {
        locs.push(loc);
      }
    });
  });
  return locs;
}

export default function DashBoard(props) {
  const [date, setDate] = useState(moment(new Date()).format("DD/MM/YYYY"));
  const [dateRange, setDateRange] = useState({
    startDate: moment(new Date()).format("DD/MM/YYYY"),
    endDate: moment(new Date()).format("DD/MM/YYYY"),
  });
  const [dateRangeISO, setDateRangeISO] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [ProgressCount, setProgressCount] = useState({});
  const [revenueStrat, setRevenuStrat] = useState({});
  const [workOrderCount, setWorkOrderCount] = useState({});
  const [locations, setLocations] = useState([]);
  const [groupedStat, setGroupedStat] = useState([]);
  const [wage, setWage] = useState(0);
  const [revenueModalToggle, setRevenueModalToggle] = useState(false);
  const [groupedlist, setGroupedList] = useState([]);
  const [ProductCount, setProductCount] = useState({
    // products: products,
    productCount: {},
    generalCount: {},
    areaCount: {},
    orderCount: {},
  });

  const [loading, setLoading] = useState(false);

  const [pickDate, setPickDate] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);

  useEffect(() => {
    axios
      .get("https://app.raysflorist.work/ladjcauenackdnland")
      .then((doc) => {});
  }, []);

  function refresh() {
    setLoading(true);
    setLoadingCount(0);
    let url = BASE_SERVER_URL + "/wo/OrderedProductsCount";
    axios
      .post(url, { date: date })
      .then((doc) => {
        if (doc.data.status === "success") {
          setProgressCount(doc.data.response);
          setLocations(
            extractDeliveryLocations(doc.data.response.products) || []
          );
        }
        setLoadingCount((prev) => prev + 1);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });

    // url = BASE_SERVER_URL + "/TestDate";
    url = BASE_SERVER_URL + "/ordered-products/by-date-range";
    axios
      .post(url, { dateRange: dateRange })
      .then((doc) => {
        if (doc.data.status === "success") {
          let datas = doc.data.response;
          // setData(datas);
          setProductCount(doc.data.body);
        }
        setLoadingCount((prev) => prev + 1);
      })
      .finally(() => {
        setLoading(false);
      });

    url = BASE_SERVER_URL + "/work-orders/get-grouped-count";
    axios
      .post(url, { date: dateRange })
      .then((doc) => {
        if (doc.data.status === "success") {
          let reven = doc.data.response.RevenueStrat;
          reven.estimated_net_profit = reven.estimated_gross_profit - wage;
          setRevenuStrat(reven);
          setWorkOrderCount(doc.data.response);
        }
        setLoadingCount((prev) => prev + 1);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });

    setGroupedList([]);
    url = BASE_SERVER_URL + "/ordered-products/by-group";
    let data = {
      ...dateRange,
      group_by: "product_type",
    };
    axios.post(url, data).then((response) => {
      let donutStat = {};
      (response.data.data || []).forEach((each) => {
        donutStat[each._id] = each.totalProfit;
      });
      // setRevenuStrat(donutStat);
      setGroupedStat(donutStat);
      setGroupedList(response.data.data);
      setLoadingCount((prev) => prev + 1);
    });
  }
  useEffect(() => {
    setDateRange({ startDate: date, endDate: date });
  }, [date]);
  useEffect(() => {
    refresh();
  }, [dateRange]);
  useEffect(() => {
    setDateRange({
      startDate: moment(dateRangeISO.startDate).format("DD/MM/YYYY"),
      endDate: moment(dateRangeISO.endDate).format("DD/MM/YYYY"),
    });
  }, [dateRangeISO]);
  return (
    <div style={{ marginTop: "80px", width: "95%", marginLeft: "2.5%" }}>
      <div
        className="relative center-hor  "
        style={{ marginBottom: "30px", zIndex: 1 }}
      >
        <span
          className=" font-medium space-between xlight-box-shadow center-hor no-wrap"
          style={{
            width: "33%",
            padding: "5px 10px",
            borderLeft: "3px solid #b79800",
          }}
        >
          <span className="center-hor">
            <span className="color-gold bold-1">
              {moment(dateRange.startDate, "DD/MM/YYYY").format("LL")}
            </span>
            {dateRange.startDate !== dateRange.endDate
              ? [
                  <span className="padding-10">to</span>,
                  <span className="color-gold bold-1">
                    {moment(dateRange.endDate, "DD/MM/YYYY").format("LL")}
                  </span>,
                ]
              : ""}
          </span>
          <span style={{ width: "40px" }}>
            <BsCalendarFill
              onClick={() => {
                setPickDate((prev) => !prev);
              }}
              className={"icon mid " + (pickDate ? "" : "grey")}
            />
          </span>
        </span>
      </div>
      {loadingCount === 4 ? (
        <div className="row ">
          <div
            className="col-md-12 absolute-center"
            style={{ height: "600px" }}
          >
            <div className={"loading-spin large"}></div>
          </div>
        </div>
      ) : (
        <div className="row relative">
          <div className="col-md-4 in-animation-to-left bg-white">
            {pickDate ? (
              <DateRangePickerOverlay
                dateRange={dateRangeISO}
                setter={(a) => {
                  setDateRangeISO(a);
                }}
              />
            ) : (
              ""
            )}
            <WidgetTitle title="Today`s Activity" />
            <CountDisplay
              date={date}
              typeCount={ProductCount.TypeCount}
              counts={workOrderCount}
              generalCount={{}}
              areaCount={{}}
            />
            <DetailedProgressBars loading={loading} data={ProgressCount} />
            {/* <WidgetTitle title="Delivery Locations" addClass="push-top" /> */}
            {/* <MapComponent /> */}
          </div>
          <div className="col-md-4 zoom-in bg-white">
            {(props.currentUser || {}).access === "Super Admin" ? (
              <>
                <WidgetTitle
                  title="Expected Revenue"
                  rightActionIcon={
                    <BiDetail
                      className="icon mid"
                      onClick={() => {
                        setRevenueModalToggle((prev) => !prev);
                      }}
                    />
                  }
                />

                <ProfitDisplay
                  groupedlist={groupedlist}
                  WorkOrderCount={workOrderCount}
                  wage={wage}
                  setWage={(a) => {
                    setWage(a);
                  }}
                  revenueModalToggle={revenueModalToggle}
                  date={date}
                  products={(ProductCount || {}).products}
                  dateRange={dateRange}
                  setRevenue={setRevenuStrat}
                  revenueStrat={revenueStrat}
                  collapse={() => {
                    setRevenueModalToggle((prev) => !prev);
                  }}
                  setCounts={(a) => {
                    setProductCount(a);
                  }}
                  rangeSetter={(a, b) => {
                    if (b === "defaults") {
                      setDateRange(a);
                    } else {
                      setDateRange((prev) => {
                        let old = { ...prev };
                        old[b] = a;
                        return old;
                      });
                    }
                  }}
                />
              </>
            ) : (
              ""
            )}
            <WidgetTitle title="Order Count By Type" addClass="push-top" />
            {Object.keys(
              sortObject((ProductCount.OrderCountByType || {}).list || {})
            ).map((each) => {
              return (
                <ProgressBar
                  total={Number(
                    (ProductCount.OrderCountByType || {}).total || 0
                  )}
                  value={
                    ((ProductCount.OrderCountByType || {}).list || {})[each]
                  }
                  type={each}
                />
              );
            })}
            {/* <LineChart /> */}
          </div>
          <div className="col-md-4 in-animation-to-right bg-white">
            <WidgetTitle title="Top Sellers" />
            <DoughnutChart data={groupedStat} />
            <WidgetTitle title="Notifications" addClass="push-top" />
            <NotificationBoard />
          </div>
        </div>
      )}

      <div className="row" style={{ marginTop: "20px" }}>
        <div className="col-md-4"></div>
        <div className="col-md-4"></div>
      </div>
      <div className="row" style={{ marginTop: "20px" }}>
        <div className="col-md-4"></div>
        <div className="col-md-4"></div>
      </div>
    </div>
  );
}

function DetailedProgressBars(props) {
  const [ProgressCount, ProgressCountSet] = useState(props.data);

  useEffect(() => {
    ProgressCountSet(props.data);
  }, [props.data]);

  return props.loading ? (
    <LoadingDiv
      amount={2}
      noDelay={true}
      speed={2}
      height={"40px"}
      style={{ marginBottom: "20px" }}
    />
  ) : (
    <>
      <DetailedProgressBar
        task="Complete"
        outof={ProgressCount.total}
        amount={ProgressCount.complete}
      />
      <DetailedProgressBar
        task="Ready for Delivery"
        outof={ProgressCount.complete}
        amount={ProgressCount.finalCheck}
      />
      <DetailedProgressBar
        task="Delivered"
        outof={ProgressCount.finalCheck}
        amount={0}
      />
    </>
  );
}

function ProfitDisplay(props) {
  const [jsonExcel, setJsonExcel] = useState([]);
  const [activeRange, SetActiveRange] = useState("Today");
  const [data, setData] = useState(props.revenueStrat || {});

  const [loading, setLoading] = useState(false);

  function refresh() {
    setLoading(true);
    // let url = BASE_SERVER_URL + "/wo/revenue";
    let url = BASE_SERVER_URL + "/TestDate";
    axios
      .post(url, props.dateRange)
      .then((doc) => {
        if (doc.data.status === "success") {
          let datas = doc.data.response;
          datas.estimated_net_profit =
            datas.estimated_gross_profit - props.wage;
          props.setRevenue(doc.data.revenueStrat);
          // setData(datas);
          props.setCounts(doc.data.response2);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (props.dateRange.startDate && props.dateRange.endDate) {
      // refresh();
    }
    setData(props.revenueStrat);
  }, [props.revenueStrat]);

  useEffect(() => {
    let allFile = [];
    Object.keys((props.products || {}).Funeral || {}).forEach((ea) => {
      let eachs = (props.products || {}).Funeral[ea];
      eachs.forEach((each, index) => {
        allFile.push({
          "Order Type": "Funeral",
          "Late Person Name": each.late_person_name,
          "Product Type": each.product_type,
          "Product Code": each.code,
          "Sash Price": each.sashPrice,
          Amount: each.amount,
          Price:
            each.amount *
            Number(each.customPrice ? each.customPrice : each.price),
          "Delivery Fee": each.delivery_fee,
        });
      });
    });

    let reduceRetail = [];

    ((props.products || {}).Retail || []).forEach((each, index) => {
      reduceRetail.push({
        "Order Type": "Retail",
        "Product Type": each.product_type,
        "Product Code": each.code,
        Amount: each.amount,
        Price:
          each.amount *
          Number(each.customPrice ? each.customPrice : each.price),
        "Sash Price": each.sashPrice || 0,
        "Delivery Fee": each.delivery_fee || 0,
      });
    });

    setJsonExcel([...allFile, ...reduceRetail]);
  }, [props.products]);

  useEffect(() => {
    let dataC = { ...data };
    dataC.estimated_net_profit = dataC.estimated_gross_profit - props.wage;
    if (dataC.estimated_net_profit > 0) {
      setData(dataC);
    }
  }, [props.wage]);
  return loading ? (
    <LoadingDiv amount={0} noDelay={true} speed={2} height={"250px"} />
  ) : (
    <div
      className={"" + (props.revenueModalToggle ? "profit-modal-open row" : "")}
    >
      {false && props.revenueModalToggle ? (
        <svg
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
          }}
          width="100%"
          height="100%"
          viewBox="0 0 1000 1000"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          overflow="auto"
          shape-rendering="auto"
          fill="#e1b94c"
        >
          <defs>
            <path
              id="wavepath"
              d="M 0 2000 0 500 Q 150 400 300 500 t 300 0 300 0 300 0 300 0 300 0  v1000 z"
            />
          </defs>
          <g>
            <use href="#wavepath" y="-356" fill=""></use>
          </g>
        </svg>
      ) : (
        ""
      )}

      {props.revenueModalToggle ? (
        <span className="float-top-right-most" style={{ width: "40px" }}>
          <MdCancel
            onClick={() => {
              props.collapse();
            }}
            className="icon large red rec"
          />
        </span>
      ) : (
        ""
      )}
      <div
        className={"" + (props.revenueModalToggle ? "col-md-4" : "width-100")}
      >
        <div className="profit-display center-hor light-box-shadow ">
          <div className="color-white" style={{ background: "#f0d499" }}>
            <span className="amount absolute-center">
              $
              {addCommasToNumber(
                Number(data.estimated_gross_profit || 0).toFixed(2)
              )}
            </span>
            <span
              className="text absolute-center"
              style={{ background: "#b79800" }}
            >
              Gross Profit
            </span>
          </div>
          <div className="" style={{ background: "#f4f2f2" }}>
            <span
              className="amount absolute-center"
              style={{ color: "#b79800" }}
            >
              $
              {addCommasToNumber(
                Number(data.estimated_net_profit || 0).toFixed(2)
              )}
            </span>
            <span
              className="text absolute-center"
              style={{ color: "#6a6a6a", background: "#c7c6c6" }}
            >
              Net Profit
            </span>
          </div>
        </div>
        <div className="width-100 font-medium" style={{ marginTop: "20px" }}>
          {/* <DateRangePicker
            SetActiveRange={SetActiveRange}
            activeRange={activeRange}
            dateRange={props.dateRange}
            rangeSetter={(a, b) => {
              props.rangeSetter(a, b);
            }}
          /> */}
          <div
            className="width-100 center-hor space-around bold-1 light-grey-header"
            style={{ padding: "5px 6px" }}
          >
            <span>Revenue Stratification</span>
          </div>
          <div className="width-100 center-hor space-between padding-LR-5">
            <span className="color-gold">Total Revenues</span>
            <span>${addCommasToNumber(data.total_all)}</span>
          </div>
          <div
            className="width-100 center-hor space-between padding-LR-5"
            style={{ backgroundColor: "#f7f7f7" }}
          >
            <span className="color-gold">
              Total Revenue (minus GST and Commision)
            </span>
            <span>${addCommasToNumber(data.total_minus_gst_and_commsion)}</span>
          </div>
          <div className="width-100 center-hor space-between padding-LR-5">
            <span className="color-gold">Total Wage</span>
            {/* <span>${addCommasToNumber(data.estimated_wage)}</span> */}
            <span className="center-hor">
              $
              <input
                type="text"
                className="wage-input"
                onChange={(e) => {
                  props.setWage(e.target.value);
                }}
                value={props.wage}
              />
            </span>
          </div>
          <div className="width-100 center-hor space-between padding-LR-5">
            <span className="color-gold">Cost of Goods Sold</span>
            <span>${addCommasToNumber(data.total_cost_of_goods)}</span>
          </div>
          <div className="width-100 center-hor space-between padding-LR-5">
            <span className="color-gold">Gross Profit</span>
            <span>${addCommasToNumber(data.estimated_gross_profit)}</span>
          </div>
          <div className="width-100 center-hor space-between padding-LR-5">
            <span className="color-gold">Net Profit</span>
            <span>
              $
              {addCommasToNumber(
                Number(data.estimated_net_profit || 0).toFixed(2)
              )}
            </span>
          </div>
        </div>
      </div>
      {props.revenueModalToggle
        ? [
            <div className="col-md-4">
              <div className="width-100 space-between center-hor">
                <h5>List Ordered Products</h5>
                <ExportTools jsonExport={jsonExcel || []} />
              </div>
              <div className="width-100 ordered-list-table thin-red-scrollbar">
                {/* <header>Ordered Products</header> */}
                <table
                  style={{ borderRadius: "8px", overflow: "" }}
                  className="table table-striped"
                >
                  <thead></thead>

                  <tbody>
                    <tr className="relative font-small small-table-row">
                      <td className="font-small color-gold" colspan="4">
                        Funeral
                      </td>
                      <td className="font-small color-gold no-wrap">
                        Total Price
                      </td>
                      <td className="font-small color-gold no-wrap">
                        Sash Price
                      </td>
                      <td className="font-small color-gold no-wrap">
                        Delivery Fee
                      </td>
                    </tr>
                    {Object.keys((props.products || {}).Funeral || {})
                      .length === 0 ? (
                      <tr className="relative font-small small-table-row">
                        <td className="font-small" colspan="5">
                          No Funeral Work Order Found
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {Object.keys((props.products || {}).Funeral || {}).map(
                      (ea, index1) => {
                        let x = 0;
                        let eachs = (props.products || {}).Funeral[ea];
                        return eachs.map((each, index) => {
                          x++;
                          return [
                            index === 0 ? (
                              <tr className="relative font-small small-table-row">
                                <td
                                  className="font-small color-gold"
                                  colspan="7"
                                >
                                  {each.parent_work_order.late_person_name ||
                                    ""}
                                </td>
                              </tr>
                            ) : (
                              ""
                            ),
                            <tr className="relative font-small small-table-row">
                              <th scope="row">{x}</th>
                              <td className="no-wrap">{each.product_type}</td>
                              <td className="no-wrap">{each.code}</td>
                              <td className="no-wrap">x {each.amount}</td>
                              <td className="no-wrap">
                                $
                                {each.amount *
                                  Number(
                                    each.customPrice
                                      ? each.customPrice
                                      : each.price
                                  )}
                              </td>
                              <td className="no-wrap">
                                ${each.sashPrice || 0}
                              </td>
                              <td className="">${each.delivery_fee || 0}</td>
                            </tr>,
                          ];
                        });
                      }
                    )}
                    <tr className="relative font-small small-table-row">
                      <td className="font-small color-gold" colspan="4">
                        Retail
                      </td>
                      <td className="font-small color-gold no-wrap">
                        Total Price
                      </td>
                      <td className="font-small color-gold no-wrap">
                        Sash Price
                      </td>
                      <td className="font-small color-gold no-wrap">
                        Delivery Fee
                      </td>
                    </tr>
                    {((props.products || {}).Retail || []).length === 0 ? (
                      <tr className="relative font-small small-table-row">
                        <td className="font-small" colspan="5">
                          No Retail Work Order Found
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {((props.products || {}).Retail || []).map((each, x) => {
                      return (
                        <tr className="relative font-small small-table-row">
                          <th scope="row">{x + 1}</th>
                          <td className="no-wrap">{each.ordered_by}</td>
                          <td className="no-wrap">{each.code}</td>
                          <td className="no-wrap">
                            x {each.amount * (each.day_count || 1)}
                          </td>
                          <td className="no-wrap">
                            $
                            {each.amount *
                              Number(
                                each.customPrice ? each.customPrice : each.price
                              )}
                          </td>
                          <td className="no-wrap">${each.sashPrice || 0}</td>
                          <td className="no-wrap">${each.delivery_fee || 0}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>,
            <div className="col-md-4">
              <ProductReveneueDetailCard
                groupedlist={props.groupedlist}
                dateRange={props.dateRange}
              />
            </div>,
          ]
        : ""}
    </div>
  );
}

function CountDisplay(props) {
  return props.loading ? (
    <LoadingDiv amount={0} noDelay={true} speed={2} height={"250px"} />
  ) : (
    <div className="">
      <div className="count-display">
        <div className="width-30">
          <NumberCard
            largeFont={true}
            title="Orders Today"
            value={props.counts.Total || 0}
          />
        </div>
        <div className="width-70 center-hor space-evenly">
          <div className="width-30">
            <NumberCard
              addClass="small"
              title="Funeral Orders"
              value={props.counts.Funeral || 0}
            />
          </div>
          <div className="width-30">
            <NumberCard
              addClass="small"
              title="Retail Orders"
              value={props.counts.Retail || 0}
            />
          </div>
        </div>
      </div>
      <div
        className="width-100 "
        style={{ display: "flex", alignItems: "start", marginTop: "20px" }}
      >
        <div className="width-50 font-medium">
          <div className="width-100 absolute-center bold-1 light-gold-header">
            Funeral Orders
          </div>
          <div className="width-100 center-hor space-between padding-LR-5">
            <span className="color-gold">Casket Covers</span>
            <span>{(props.typeCount || {})["Casket cover"] || 0}</span>
          </div>
          <div
            className="width-100 center-hor space-between padding-LR-5"
            style={{ backgroundColor: "#f7f7f7" }}
          >
            <span className="color-gold">Tributes</span>
            <span>{(props.typeCount || {})["Tributes"] || 0}</span>
          </div>
          <div className="width-100 center-hor space-between padding-LR-5">
            <span className="color-gold">Bowls</span>
            <span>{(props.typeCount || {})["Bowls"] || 0}</span>
          </div>
        </div>
        <div className="width-50 font-medium" style={{ marginLeft: "2px" }}>
          <div className="width-100 absolute-center bold-1 light-grey-header">
            Retail Orders
          </div>
          <div className="width-100 center-hor space-between padding-LR-5">
            <span className="color-gold">North</span>
            <span>{(props.counts.AreaCount || {})["North"] || 0}</span>
          </div>
          <div
            className="width-100 center-hor space-between padding-LR-5"
            style={{ backgroundColor: "#f7f7f7" }}
          >
            <span className="color-gold">South</span>
            <span>{(props.counts.AreaCount || {})["South"] || 0}</span>
          </div>
          <div className="width-100 center-hor space-between padding-LR-5">
            <span className="color-gold">West</span>
            <span>{(props.counts.AreaCount || {})["West"] || 0}</span>
          </div>
          <div
            className="width-100 center-hor space-between padding-LR-5"
            style={{ backgroundColor: "#f7f7f7" }}
          >
            <span className="color-gold">City</span>
            <span>{(props.counts.AreaCount || {})["City"] || 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function NumberCard(props) {
  return (
    <div className={"card-number light-box-shadow " + props.addClass}>
      <span
        className={
          "with-100 relative count absolute-center " +
          (props.largeFont ? "large" : "")
        }
      >
        {props.value}
        <div className="side center-ver-col">
          {props.superIcon && (
            <span className="super-icon">{props.superIcon}</span>
          )}
          {props.unit && <span className="unit">{props.unit}</span>}
        </div>
      </span>
      <span className="text width-100 absolute-center">{props.title}</span>
    </div>
  );
}

function ProgressBar(props) {
  let prec =
    Math.floor((props.value * 100) / props.total) > 100
      ? 100
      : Math.floor((props.value * 100) / props.total);
  return (
    <div className="progress-div">
      <div className="bar font-small" style={{ width: prec + "%" }}>
        <span className="no-wrap">{props.type}</span>
        <span>{props.value}</span>
      </div>
    </div>
  );
}

function NotificationBoard(props) {
  return (
    <div className="notification-board">
      <div className="head">Alerts and Notifications</div>
      <NotificationBar />
      <NotificationBar type="red" />
    </div>
  );
}

function NotificationBar(props) {
  return (
    <div className="notif-bar width-100 center-hor">
      <span className={"notif-icon absolute-center " + props.type}>
        <MdNotifications className="icon large white" />
      </span>
      <span className="notif-text font-medium">Notification text here</span>
    </div>
  );
}

export function WidgetTitle(props) {
  return (
    <div
      className={
        (props.addClass || "") +
        " title-separator relative center-hor " +
        props.size
      }
    >
      <div className="line"></div>
      <div className="text">{props.title}</div>
      {props.rightActionIcon ? (
        <div className="icon" style={{ width: "50px" }}>
          {props.rightActionIcon}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

function DetailedProgressBar(props) {
  let prec = Math.floor((props.amount / props.outof) * 100) || 0;
  return (
    <div className="detailed-progress-bar xlight-box-shadow bold-1">
      <div className="bar-count center-hor">
        <span className="width-40">
          <span className="number">{props.amount}</span>
          <span className="txt">out of</span>
        </span>
        <span className="width-60">
          <span className="number">{props.outof}</span>
          <span className="txt">{props.task}</span>
        </span>
      </div>
      <div className="width-60 relative">
        <div
          className={
            "bar bold-1  " + (prec > 75 ? "high" : prec > 50 ? "mid" : "low")
          }
          style={{ width: (prec > 100 ? 100 : prec) + "%" || "0%" }}
        >
          <span className="counter absolute-center">{prec} %</span>
        </div>
      </div>
    </div>
  );
}
