import React, { useState,useEffect } from 'react'
import {SearchField,DatePickerSmall,ToggleRadioWithText,
        LoadingDiv, ReloadOption,ToReadableDate,
        CalcTimeDifference,TableRowSeparator,shorten_product_list} from '../components'
import logo from '../images/logo.png'
import 'bootstrap/dist/css/bootstrap.css'
import * as IconsBS from 'react-icons/bs'
import * as IconsAI from 'react-icons/ai'
import {MdCancel} from 'react-icons/md'
import axios from 'axios'
import moment from 'moment'
import {Pagenation} from '../components/pagenation'
import {DriverRunListMaking} from '../components/movableDragDropArea'

import {BASE_SERVER_URL} from '../sampleData'
import { toast } from 'react-toastify'




export function WorkHistory(props){
document.title = 'Work History'
const [orders,setOrders] = React.useState([])

//filters
const [listDate,setListDate] = React.useState('' && ToReadableDate(new Date(),[],'/'))
const [orderType,setOrderType] = React.useState(props.orderType || 'Funeral')
const [sortBy,setSortBy] = React.useState('funeral_date')
const [query,setQuery] = React.useState('')
const [allPage,setAllPage] = React.useState(0)
const [page,setPage] = React.useState(1)
const pageLimit = 10; 
const sortDirection = {funeral_date: 1, date: -1}
//loadings

const[listIsLoading,setListIsLoading] = useState(false)
const[loadingFailed,setLoadingFailed] = useState(false)

const typeSort = {Funeral: 'date',Retail:'date'}
React.useEffect(()=>{
    // console.log('fetching data ') 
    setSortBy(typeSort[orderType])
    refreshList()
},[listDate,orderType,page,sortBy])

function refreshList(){
    setListIsLoading(true)
let url = BASE_SERVER_URL+'/wo/getAllBy/'
    let filter = {
        filter: {
            ...(orderType?{type:orderType}:{}), 
            ...(listDate? orderType==='Funeral'?{funeral_date: listDate}:{delivery_date: listDate} : {})
        },
            page: page, 
            byQuery: query, 
            limit: pageLimit, 
            sortBy : 'timestamp',
            sortDirection: sortDirection[sortBy] || -1,
        }
        setLoadingFailed(false)
        filter.getAll = true
    axios.post(url,filter).then((doc)=>{
        console.log('fetched data filter list ',doc.data) 
        if(doc.data.status === 'success'){
            setListIsLoading(false)
            setOrders(doc.data.response) 
            setAllPage(Math.ceil(doc.data.responseLength / pageLimit))
        }else{
            setLoadingFailed(true)
        }
    }).catch(function (error) {
        setLoadingFailed(true)
        // console.log(error)
    })
}


return <div className='data-input center-ver-col'>
            <header>Work Order History</header>
            <header className='row large main space-between padding-5 width-100 relative center-hor'>
                <div className='center-hor col-lg-4 col-md-6 col-sm-12 wo-filter-tools padding-0'>
                        <div className=''>
                            <ToggleRadioWithText toggleOptions={['Funeral','Retail']} setToggle={(a)=>{setOrderType(a)}} />
                        </div>
                        <div className=''>
                            {/* <span className='center-hor space-around' style={{width:'130px'}}>Filter By Date</span> */}
                            <DatePickerSmall 
                                    value={listDate} 
                                    onChageSetter={(a)=>{setListDate(ToReadableDate(a.value,[],'/'))}} 
                                    name='funeral_date' label='Date' addClass='margin-left-10' 
                                    icon={<IconsBS.BsCalendarFill className='icon grey'/>} 
                                    placeHolder='Date'
                                    light={true}
                                    clearDate={()=>{setListDate('')}}
                                    />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 center-hor relative padding-0'>
                    <SearchField searchTrigger={()=>{refreshList()}} setQuery={(a)=>{setQuery(a)}} addClass='float-right stretch-height'/>
                </div>
                </header>
                <TableWorkList 
                    setDateFilter={(a)=>{setListDate(a); setSortBy(typeSort[orderType || 'funeral_date'])}}
                    data={orders}
                    orderType={orderType} 
                    listStart={(page-1) * pageLimit}
                    loading={listIsLoading}
                    loadingFailed={loadingFailed}
                    reload={()=>{refreshList()}}
                    sortBy={sortBy}
                    />
                <div className='row width-100 absolute-center'>
                    <div className='col-lg-4 col-md-8 col-sm-12'>
                        <Pagenation pages={allPage} setActivePage={(a)=>{setPage(a)}}/>
                    </div>
                </div>
        </div>

}



function TableWorkList(props){
    let date = ''
    const head = {Funeral: <div className='table-head font-small light-box-shadow table-row ' >
                                <span className='x-small'>#</span>
                                <span style={{width:'20%'}} >Late Person`s Name</span>
                                <span style={{width:'20%'}} >Ordered By</span>
                                <span style={{width:'20%'}}>Order Entry Date</span>
                                <span style={{width:'30%'}}>Selected Casket Cover</span>
                                <span style={{width:'30%'}}>Selected Tributes</span>
                            </div>,
                 Retail: <div className='table-head font-small light-box-shadow table-row' >
                                <span className='x-small'>#</span>
                                <span style={{width:'20%'}}>Recipient`s Name</span>
                                <span style={{width:'20%'}}>Company Name</span>
                                <span style={{width:'20%'}}>Order From</span>
                                <span style={{width:'30%'}}>Order Entry Date</span>
                                <span style={{width:'30%'}}>Selected Flower Arrangement</span>
                        </div>    
            }
    

    return (
        <div className={'work-order-list'} style={{minWidth:'1200px'}}>
        {head[props.orderType]}
        {
        
        props.loading?
            <LoadingDiv amount={10}/>
         :  props.loadingFailed? <ReloadOption reload={props.reload}/>:
            props.data.length === 0?
            <TableRowSeparator message='No Order Found' />:
            props.data.map((each,num)=>{
                // let d = moment(each.date.split('T')[0], "YYYY-MM-DD").fromNow()
                const timeDir = {funeral_date: true, date: false}
                
                return <TableRowWork  key={num} num={eval(props.listStart + num + 1)} data={each} orderType={props.orderType} toggleShow={()=>{props.toggleShow(num);}}/>
                
            })
        }
      </div> 
    )
}

function TableRowWork(props){
    const [isactive,setIsactive] = useState(false)
    const date_format = "DD-MM-YYYY"
    console.log('selected Once ',props.selectedOnces)
    {/* <span>{props.data.fd_code['FD code'].code || 'CODE'}</span> */}
    let table_row = {
            Funeral: [<span hidden={true}>{props.data._id}</span>,
            <span className='x-small'>{props.num}</span>,
            <span style={{width:'20%'}}>{props.data.late_person_name}</span>,
            <span style={{width:'20%'}}>{props.data.ordered_by}</span>,
            <span style={{width:'20%'}}>{ToReadableDate(props.data.date,[],'/','DD/MM/YYYY','MM/DD/YYYY')}</span>,
            <span style={{width:'30%'}}>{ shorten_product_list(props.data.casket_cover_selected || [])}</span>,
            <span style={{width:'30%'}}>{shorten_product_list(props.data.tributes_selected || [])}</span>
        ],
            Retail: [<span hidden={true}>{props.data._id}</span>,
            <span className='x-small'>{props.num}</span>,
            <span style={{width:'20%'}}>{props.data.recipient_name}</span>,
            <span style={{width:'20%'}}>{props.data.company_name}</span>,
            <span style={{width:'20%'}}>{props.data.ordered_by}</span>,
            <span style={{width:'30%'}}>{ToReadableDate(props.data.date,[],'/','DD/MM/YYYY','MM/DD/YYYY')}</span>,
            <span style={{width:'30%'}}>{shorten_product_list(props.data.products_selected || [])}</span>
        ]
    }
    return (
        <div  key={props.key} className={(isactive?'active':'')+' table-row-content center-hor space-between font-small table-row'}>
                {table_row[props.orderType]}
        </div>
    )
}