import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "350px",
};

const center = {
  lat: -33.899889,
  lng: 151.1699117,
};

const locations = [
  {
    lat: -33.9122845, // Latitude of the center point
    lng: 151.1559232, // Longitude of the center point
  },
];

const mapStyles = [
  {
    featureType: "all",
    elementType: "geometry",
    stylers: [{ color: "#f5f5f5" }],
  },
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [
      { color: "#333333" }, // Font color
      { weight: 0.5 }, // Font weight, heavier can appear larger
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [{ color: "#616161" }],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#f5f5f5" }],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [{ color: "#bdbdbd" }],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [{ color: "#eeeeee" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#757575" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#e5e5e5" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#ffffff" }],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [{ color: "#757575" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#dadada" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#616161" }],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [{ color: "#e5e5e5" }],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [{ color: "#eeeeee" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#c9c9c9" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
];

// Custom OverlayView Component
const CustomMarker = ({ position, text, map }) => {
  useEffect(() => {
    const overlay = new window.google.maps.OverlayView();

    overlay.onAdd = () => {
      const div = document.createElement("div");
      div.style.position = "absolute";
      div.style.backgroundColor = "white";
      div.style.border = "1px solid black";
      div.style.padding = "5px";
      div.style.borderRadius = "5px";
      div.innerHTML = text;
      div.className = "bg-red";

      const panes = overlay.getPanes();
      panes.overlayLayer.appendChild(div);

      overlay.draw = () => {
        const projection = overlay.getProjection();
        const position = projection.fromLatLngToDivPixel(
          new window.google.maps.LatLng(position.lat, position.lng)
        );
        div.style.left = `${position.x}px`;
        div.style.top = `${position.y}px`;
      };
    };

    overlay.setMap(map);

    return () => {
      overlay.setMap(null);
    };
  }, [position, text, map]);

  return null;
};

const MapComponent = (props) => {
  const mapRef = React.useRef(null);

  const [marker, setMarker] = useState();

  useEffect(() => {
    setTimeout(() => {
      // <CustomMarker
      //   position={{
      //     lat: -33.895889,
      //     lng: 151.2699117,
      //   }}
      //   text={"The Loc"}
      //   map={mapRef.current}
      // />
      setMarker(() => {
        return (props.locations || []).map((each) => {
          return <Marker position={each} />;
        });
      });
    }, 2000);
  }, []);

  useEffect(() => {
    console.log("locs ", props.locations);
  }, [props.locations]);

  return (
    <div
      className="width-100 xlight-box-shadow"
      style={{ borderRadius: "10px", overflow: "hidden" }}
    >
      <LoadScript googleMapsApiKey="AIzaSyCZLh0EV7XPKEQ9-w80Ed8N6Wjq52Ijch4">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={5}
          options={{ styles: mapStyles, mapTypeControl: false }}
          onLoad={(map) => (mapRef.current = map)}
        >
          {marker}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapComponent;
