import { useState } from "react";
import moment from "moment";
import { DateTimeRangePicker } from "../../components";

export default function DateRangePicker(props) {
  const ranges = ["Today", "Yesterday", "This Week", "This Month"];
  const [active, setActive] = useState(props.activeRange);

  // Function to handle the selection of date ranges
  const handleRangeSelect = (rangeType) => {
    let startDate, endDate;

    switch (rangeType) {
      case "Today":
        startDate = moment().startOf("day");
        endDate = moment().endOf("day");
        break;

      case "Yesterday":
        startDate = moment().subtract(1, "days").startOf("day");
        endDate = moment().subtract(1, "days").endOf("day");
        break;

      case "This Week":
        startDate = moment().startOf("week");
        endDate = moment().endOf("week");
        break;

      case "Last Week":
        startDate = moment().subtract(1, "weeks").startOf("week");
        endDate = moment().subtract(1, "weeks").endOf("week");
        break;

      case "This Month":
        startDate = moment().startOf("month");
        endDate = moment().endOf("month");
        break;

      case "Last Month":
        startDate = moment().subtract(1, "months").startOf("month");
        endDate = moment().subtract(1, "months").endOf("month");
        break;

      default:
        break;
    }
    let setted = {
      startDate: moment(startDate).format("DD/MM/YYYY"),
      endDate: moment(endDate).format("DD/MM/YYYY"),
    };

    props.rangeSetter(setted, "defaults");
    props.SetActiveRange(rangeType);
    // startDate: startDate.toISOString(),
    // endDate: endDate.toISOString(),
  };

  return (
    <div
      className="date-range-picker hor-list-selector width-100 absolute-center light-grey-header xlight-box-shadow"
      // hidden="true"
    >
      {ranges.map((each, index) => {
        return (
          <span
            className={"flex-grow " + (each === active ? "active" : "")}
            key={index}
            onClick={() => {
              setActive(each);
              handleRangeSelect(each);
            }}
          >
            {each}
          </span>
        );
      })}
    </div>
  );
}
