import { useEffect, useState } from "react";

import { AiFillSetting } from "react-icons/ai";
import {
  LoadingWait,
  ButtonIcon,
  TextInputField,
  LoadingDiv,
  ButtonFlat,
  toName,
} from "../components";
import {
  MdAdd,
  MdCancel,
  MdModeEditOutline,
  MdOutlineCancel,
} from "react-icons/md";
import { BiSave } from "react-icons/bi";
import axios from "axios";
import { BASE_SERVER_URL } from "../sampleData";
import { PopUpAlert } from "../components/movableDragDropArea";
import { ProductTypes } from "../sampleData";

export function SetupPage(props) {
  let navList = {
    General: {
      icon: <AiFillSetting className="icon mid" />,
    },
    "Databases and Space": {
      icon: <AiFillSetting className="icon mid" />,
    },
    Media: {
      icon: <AiFillSetting className="icon mid" />,
    },
    "Users Access": {
      icon: <AiFillSetting className="icon mid" />,
    },
  };

  return (
    <div className="data-input">
      <header className="">Application Settings</header>

      <div className="width-100 work-order-list height-stretch-container">
        <VerticalNavication navList={navList} />
        <GeneralSettings />
      </div>
    </div>
  );
}

function VerticalNavication(props) {
  const [active, setActive] = useState(0);
  let navList = props.navList || {};
  return (
    <div
      className="ver-nav xlight-box-shadow font-medium"
      style={{ width: "15%" }}
    >
      {Object.keys(navList).map((e, index) => {
        let each = navList[e];
        return (
          <span
            key={index}
            onClick={() => {
              setActive(index);
            }}
            className={
              (index === active ? "selected" : "") + " center-hor justify-start"
            }
          >
            {/* {each.icon} */}
            {e}
          </span>
        );
      })}
    </div>
  );
}

function GeneralSettings(props) {
  const [loading, setLoading] = useState(false);
  const [setting, setSetting] = useState({});
  const [alert, setAlert] = useState({});
  const [types, setTypes] = useState([]);
  const [flowers, setFlowers] = useState([]);
  const [CCtypes, setCCtypes] = useState([]);
  const [newProductType, setNewProductType] = useState("");
  const [newFlower, setNewFlower] = useState("");
  const [editCCTypes, setEditCCTypes] = useState(false);

  useEffect(() => {
    let url = BASE_SERVER_URL + "/settings/getAll";
    setLoading(true);
    axios.get(url).then((doc) => {
      setLoading(false);
      if (doc.data.status === "success") {
        setSetting(doc.data.response[0] || {});
        setTypes(JSON.parse(doc.data.response[0].product_types || "[]"));
        setCCtypes(JSON.parse(doc.data.response[0].cc_types || "[]"));
        setFlowers(JSON.parse(doc.data.response[0].flowers || "[]"));
      }
    });
  }, []);

  function editSetting(data, callBack) {
    const updateData = { sash_prices: "2000", gst: "105", delivery_fee: "210" };
    let url = BASE_SERVER_URL + "/settings/update";
    axios
      .post(url, data)
      .then((doc) => {
        if (doc.data.status === "success") {
          setSetting(doc.data.response);
        } else {
          setAlert({
            type: "error",
            title: "Error Modifing Settings Data",
            message: doc.data.status,
          });
        }
      })
      .catch((err) => {
        setAlert({
          type: "error",
          title: "Error Modifing Settings Data",
          message: err.message,
        });
      })
      .finally(() => {
        callBack();
      });
  }

  function isValidSashPrice(inputString) {
    const numbersArray = inputString.split(",");
    for (const numberStr of numbersArray) {
      if (isNaN(parseFloat(numberStr)) || isNaN(Number(numberStr))) {
        return false;
      }
    }
    return true;
  }

  return (
    <div style={{ width: "85%" }} className="row margin-0 font-medium ">
      {Object.keys(alert).length > 0 && (
        <PopUpAlert
          info={alert}
          onClose={() => {
            setAlert({});
          }}
        />
      )}

      {loading ? (
        <div className="col-md-4 col-sm-12">
          <header className="golden-card">Fees and Prices</header>
          <LoadingDiv amount={5} noDelay={true} height="35px" />
        </div>
      ) : (
        <div className="col-md-4 col-sm-12 block">
          <header className="golden-card">Fees and Prices</header>
          <div className="width-100 center-hor margin-10">
            <span className="width-75">GST</span>
            <span className="width-25">
              <EditableText
                value={Number(setting.gst) || 0}
                stringCallBack={(a) => {
                  return <span className="bold-1">{a.toFixed(2)}%</span>;
                }}
                placeHolder="GST"
                actionButtonAction={(a, callBack) => {
                  editSetting({ gst: a }, callBack);
                }}
                onChange={(a) => {
                  setSetting((prev) => {
                    let obj = { ...prev };
                    return { ...obj, gst: a };
                  });
                }}
              />
            </span>
          </div>
          <div className="width-100 center-hor margin-10">
            <span className="width-50 center-ver-col">
              <span className="width-100"> Delivery Fee </span>
              <span className="italic font-small color-red width-100">
                Numbers separated by comma
              </span>
            </span>
            <span className="width-50">
              <EditableText
                formatText={true}
                textFormatChecker={isValidSashPrice}
                stringCallBack={(a) => {
                  return a.split(",").map((each) => {
                    return <span>${Number(each || 0).toFixed(2)}</span>;
                  });
                }}
                value={setting.delivery_fee || ""}
                placeHolder="Delivery Fees (eg. 8.5, 12.00, 23.20)"
                actionButtonAction={(a, callBack) => {
                  if (isValidSashPrice(a)) {
                    editSetting({ delivery_fee: a }, callBack);
                  } else {
                    callBack();
                    setAlert({
                      title: "Incorrect Listing Format",
                      message: "Please input number list separated by commas",
                      type: "error",
                    });
                  }
                }}
                onChange={(a) => {
                  if (
                    !isValidSashPrice(a) &&
                    a[a.length - 1] !== "," &&
                    a[a.length - 1] !== " "
                  ) {
                    return false;
                  }
                  setSetting((prev) => {
                    let obj = { ...prev };
                    return { ...obj, delivery_fee: a };
                  });
                }}
              />
            </span>
          </div>
          <div className="width-100 center-hor margin-10">
            <span className="width-50 center-ver-col">
              <span className="width-100">Sash Prices</span>
              <span className="italic font-small color-red width-100">
                Numbers separated by comma
              </span>
            </span>
            <span className="width-50">
              <EditableText
                formatText={true}
                textFormatChecker={isValidSashPrice}
                stringCallBack={(a) => {
                  return a.split(",").map((each) => {
                    return <span>${Number(each || 0).toFixed(2)}</span>;
                  });
                }}
                value={setting.sash_prices || ""}
                placeHolder="Sash Prices (eg. 8.5, 12.00, 23.20)"
                actionButtonAction={(a, callBack) => {
                  if (isValidSashPrice(a)) {
                    editSetting({ sash_prices: a }, callBack);
                  } else {
                    callBack();
                    setAlert({
                      title: "Incorrect Listing Format",
                      message: "Please input number list separated by commas",
                      type: "error",
                    });
                  }
                }}
                onChange={(a) => {
                  if (
                    !isValidSashPrice(a) &&
                    a[a.length - 1] !== "," &&
                    a[a.length - 1] !== " "
                  ) {
                    return false;
                  }
                  setSetting((prev) => {
                    let obj = { ...prev };
                    return { ...obj, sash_prices: a };
                  });
                }}
              />
            </span>
          </div>

          <header className="golden-card" style={{ marginTop: "50px" }}>
            Product Types
          </header>
          <div className="width-100 center-hor">
            <div className="width-90">
              <TextInputField
                addClass="width-100 height-thin flat light-border"
                placeHolder={"Add New Product Type..."}
                name="types"
                style={{ height: "30px !important" }}
                value={newProductType}
                noLable={true}
                onChageSetter={(a) => {
                  setNewProductType(a.value);
                }}
              />
            </div>
            <div className="width-10">
              <ButtonFlat
                addClass="width-100 thin-flat light-border"
                value="Add"
                style={{
                  height: "45px !important",
                  marginTop: "3px",
                  boxShadow: "0px 3px 8px rgba(142, 139, 139, 0.1) !important",
                }}
                clickTo={() => {
                  if (!Boolean(newProductType)) {
                    return false;
                  }

                  editSetting(
                    {
                      product_types: JSON.stringify([
                        ...types,
                        toName(newProductType),
                      ]),
                    },
                    function () {
                      setNewProductType("");
                      setTypes((prev) => {
                        return [...prev, toName(newProductType)];
                      });
                    }
                  );
                }}
              />
            </div>
          </div>
          <div className="row width-100" style={{}}>
            {(types || []).sort().map((each, index) => {
              return (
                <div className="col-md-6">
                  <TaggedList
                    text={each}
                    editCCTypes={editCCTypes}
                    onTagClick={() => {
                      if (!CCtypes.includes(each) && editCCTypes) {
                        editSetting(
                          { cc_types: JSON.stringify([...CCtypes, each]) },
                          function () {
                            setCCtypes([...CCtypes, each]);
                          }
                        );
                      }
                    }}
                    remove={() => {
                      editSetting(
                        {
                          product_types: JSON.stringify(
                            types.filter((e, i) => {
                              return i !== index;
                            })
                          ),
                        },
                        function () {
                          setTypes((prev) => {
                            return prev.filter((e, i) => {
                              return i !== index;
                            });
                          });
                        }
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {loading ? (
        <div className="col-md-4 col-sm-12">
          <header className="golden-card">Casket Cover Categories</header>
          <LoadingDiv amount={5} noDelay={true} height="35px" />
        </div>
      ) : (
        <div className="col-md-4 col-sm-12 block">
          <header className="golden-card relative">
            Casket Cover Categories
            <div style={{ width: "50px", top: "2px" }} className="float-right">
              {editCCTypes ? (
                <MdOutlineCancel
                  className="icon mid"
                  onClick={() => {
                    setEditCCTypes(false);
                  }}
                />
              ) : (
                <MdAdd
                  className="icon mid"
                  onClick={() => {
                    setEditCCTypes(true);
                  }}
                />
              )}
            </div>
          </header>
          <div className="row width-100" style={{}}>
            {(CCtypes || []).sort().map((each, index) => {
              return (
                <div className="col-md-6">
                  <TaggedList
                    text={each}
                    remove={() => {
                      editSetting(
                        {
                          cc_types: JSON.stringify(
                            CCtypes.filter((e, i) => {
                              return i !== index;
                            })
                          ),
                        },
                        function () {
                          setCCtypes((prev) => {
                            return prev.filter((e, i) => {
                              return i !== index;
                            });
                          });
                        }
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {loading ? (
        <div className="col-md-4 col-sm-12">
          <header className="golden-card">Content Flowers</header>
          <LoadingDiv amount={5} noDelay={true} height="35px" />
        </div>
      ) : (
        <div className="col-md-4 col-sm-12 block">
          <header className="golden-card">Content Flowers</header>
          <div className="width-100 center-hor">
            <div className="width-90">
              <TextInputField
                addClass="width-100 height-thin flat light-border"
                placeHolder={"Add New Flower..."}
                name="types"
                style={{ height: "30px !important" }}
                value={newFlower}
                noLable={true}
                onChageSetter={(a) => {
                  setNewFlower(a.value);
                }}
              />
            </div>
            <div className="width-10">
              <ButtonFlat
                addClass="width-100 thin-flat light-border"
                value="Add"
                style={{
                  height: "45px !important",
                  marginTop: "3px",
                  boxShadow: "0px 3px 8px rgba(142, 139, 139, 0.1) !important",
                }}
                clickTo={() => {
                  if (!Boolean(newFlower)) {
                    return false;
                  }

                  editSetting(
                    {
                      flowers: JSON.stringify([...flowers, toName(newFlower)]),
                    },
                    function () {
                      setNewFlower("");
                      setFlowers((prev) => {
                        return [...prev, toName(newFlower)];
                      });
                    }
                  );
                }}
              />
            </div>
          </div>
          <div className="row width-100" style={{}}>
            {(flowers || []).sort().map((each, index) => {
              return (
                <div className="col-md-6">
                  <TaggedList
                    text={each}
                    onTagClick={() => {}}
                    remove={(a) => {
                      setAlert({
                        type: "error",
                        title: "Confirm Data Removal",
                        message: "Remove " + a + " From Flowers List?",
                        confirmPrompt: true,
                        callback: () => {
                          editSetting(
                            {
                              flowers: JSON.stringify(
                                flowers.filter((e, i) => {
                                  return i !== index;
                                })
                              ),
                            },
                            function () {
                              setFlowers((prev) => {
                                return prev.filter((e, i) => {
                                  return i !== index;
                                });
                              });
                            }
                          );
                        },
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

function TaggedList(props) {
  return (
    <div
      className={
        "relative center-hor space-between removable-tag-list xlight-box-shadow " +
        (props.editCCTypes ? "cc-edit" : "")
      }
      onClick={() => {
        props.onTagClick();
      }}
    >
      <div
        className="text-left width-90 bold-1"
        style={{ marginRight: "30px", marginLeft: "10px" }}
      >
        {props.text}
      </div>
      <div
        style={{ width: "20px", padding: "0px 2px" }}
        className="center-hor float-absolute-right"
      >
        {props.editCCTypes ? (
          ""
        ) : (
          <ButtonIcon
            addClass="small red"
            loading={props.loading}
            icon={
              <MdCancel
                className="icon mid light-red"
                onClick={() => {
                  props.remove(props.text);
                }}
              />
            }
          />
        )}
      </div>
    </div>
  );
}

function EditableText(props) {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  return edit ? (
    <TextInputField
      formatText={props.formatText}
      actionButtonAction={(a) => {
        setLoading(true);
        props.actionButtonAction(a, () => {
          setLoading(false);
          setEdit(false);
        });
      }}
      actionButton={true}
      actionButtonClass="flat2-btn float-absolute-right border-none"
      value={props.value}
      textFormatChecker={(a) => {
        return props.textFormatChecker(a);
      }}
      actionButtonValue={
        <ButtonIcon
          addClass="small"
          loading={loading}
          icon={<BiSave onClick={() => {}} className="icon mid" />}
        />
        // <BiSave className='icon mid'/>
      }
      addClass="flat2"
      onChageSetter={(a) => {
        props.onChange(a.value);
      }}
    />
  ) : (
    <span className="text-list justify-end width-100 center-hor relative">
      {props.stringCallBack(props.value)}
      <div
        style={{
          width: "20px",
          position: "absolute",
          right: "-2px",
          top: "-15px",
        }}
      >
        <MdModeEditOutline
          onClick={() => {
            setEdit(true);
          }}
          className="icon small"
        />
      </div>
    </span>
  );
}
