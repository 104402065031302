export function sendPrintRequest({ pdfUrls, printerId, apiKey }, callback) {
  var totalRequests = pdfUrls.length;
  var completedRequests = 0;

  function sendRequest(pdfUrl) {
    var xhr = new XMLHttpRequest();
    var url = "https://api.printnode.com/printjobs";
    var printJobData = {
      printerId: printerId,
      contentType: "pdf_uri",
      content: pdfUrl,
    };
    var jsonData = JSON.stringify(printJobData);
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Basic " + btoa(apiKey + ":"));
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        console.log("Print request sent successfully!");
        completedRequests++;
        if (completedRequests === totalRequests) {
          callback({ success: true });
        }
      } else {
        console.error(
          "Failed to send print request. Status code: " + xhr.status
        );
        completedRequests++;
        if (completedRequests === totalRequests) {
          callback({
            success: false,
            message: "Failed to send print request. Status code: " + xhr.status,
          });
        }
      }
    };
    xhr.onerror = function () {
      console.error("Request failed");
      completedRequests++;
      if (completedRequests === totalRequests) {
        callback({ success: false, message: "Error: failed request" });
      }
    };
    xhr.send(jsonData);
  }

  pdfUrls.forEach(sendRequest);
}
