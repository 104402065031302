import { useState, useEffect } from "react";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";

export function Pagenation(props) {
  let a = (props.dates || []).indexOf(props.currentDate);
  const [active, setActive] = useState(a !== -1 ? a : 1);
  const [start, setStart] = useState(0);
  useEffect(() => {
    // console.log('showing date ', (props.dates || []).indexOf(props.currentDate),' !== ',active)
    if ((props.dates || []).indexOf(props.currentDate) !== active - 1) {
      // console.log('start end changed ',active, start, end)
      if (active > 10 + start) {
        setStart(9 + start);
      } else if (active <= start + 1) {
        setStart(start ? start - 9 : 0);
      }
      props.setActivePage(active);
    }
  }, [active]);

  return (
    <div className="pagenation absolute-center">
      <BsFillArrowLeftCircleFill
        onClick={() => {
          setActive((prev) => {
            return prev > 1 ? prev - 1 : prev;
          });
        }}
        className="page-btn disabled icon grey large width-auto"
      />
      <div className="center-hor width-90">
        {Array.from(Array(props.pages < 18 ? props.pages : 18).keys()).map(
          (each, index) => {
            return (
              <div
                onClick={() => {
                  setActive(index + 1 + start);
                }}
                key={index}
                className={
                  (active === index + 1 + start ? "active" : "") +
                  " page page-btn"
                }
              >
                {index + 1 + start}
              </div>
            );
          }
        )}
      </div>
      <BsFillArrowRightCircleFill
        onClick={() => {
          setActive((prev) => {
            return prev < props.pages ? prev + 1 : prev;
          });
        }}
        className="page-btn disabled icon grey large width-auto"
      />
    </div>
  );
}
