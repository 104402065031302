import { useState, useEffect } from "react";
import {
  TextInputField,
  ProductTypeSelectionNav,
  SearchField,
  ProductDetailSide,
  LoadingDiv,
  ButtonWithIcon,
  Tag,
} from "../components";
import { BASE_SPACE_URL } from "../sampleData";
import axios from "axios";
import { Pagenation } from "../components/pagenation";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { BASE_SERVER_URL } from "../sampleData";
import { BsFlower3 } from "react-icons/bs";
import { GiGraveFlowers } from "react-icons/gi";
import { FaFilter } from "react-icons/fa";

export function ProductPage(props) {
  document.title = props.title || "Ray`s Florist App";
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const navigate = useNavigate();
  //filters
  const [productType, setProductType] = useState("");
  const [query, setQuery] = useState("");
  const [allPage, setAllPage] = useState(0);
  const [page, setPage] = useState(1);
  const [activeType, setActiveType] = useState(false);
  const pageLimit = 18;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    refreshList();
  }, [productType, page]);

  function refreshList() {
    setIsLoading(true);
    setSelectedProduct("");
    let url = BASE_SERVER_URL + "/products/getAllBy";
    let filter = {
      finder: {
        ...(productType ? { product_type: productType } : {}),
        ...(query ? { $text: { $search: query } } : {}),
      },
      pager: {
        page: page,
        limit: pageLimit,
      },
    };
    axios.post(url, filter).then((response) => {
      setProducts(response.data.response);
      setAllPage(Math.ceil(response.data.responseLength / pageLimit));
      setIsLoading(false);
    });
  }
  return (
    <div className="product-page">
      <ProductDetailSide
        active={Boolean(selectedProduct)}
        show={selectedProduct}
        refresh={() => {
          refreshList();
        }}
        hidder={() => {
          setSelectedProduct("");
        }}
      />
      <header className="large row">
        {/* <div className="col-md-1">Products List</div> */}
        <div className="col-md-4">List of Products</div>
        <div className="col-md-4 center-hor space-between">
          <ButtonWithIcon
            addClass="width-30 margin-0 stretch-height font-small thin"
            value={productType ? productType : "Filter by Type"}
            iconWidth="30%"
            borderRad="6px"
            icon={<FaFilter className="icon XL white width-100" />}
            clickTo={() => {
              setActiveType((prev) => !prev);
            }}
          />
          <ButtonWithIcon
            addClass="width-30 margin-0 stretch-height font-small thin"
            value="Add New Product"
            iconWidth="30%"
            borderRad="6px"
            icon={<AiOutlinePlus className="icon XL white width-100" />}
            clickTo={() => {
              navigate("/newProducts");
            }}
          />
        </div>
        <div className="col-md-4">
          <SearchField
            searchTrigger={() => {
              refreshList();
            }}
            setQuery={(a) => {
              setQuery(a);
            }}
            addClass="stretch stretch-height"
          />
        </div>
      </header>
      <div
        className={"product-type-select-card width-100 active"}
        hidden={!activeType}
      >
        <ProductTypeSelectionNav
          selected={productType}
          onChange={(a) => {
            setActiveType(false);
            setProductType(a);
          }}
          list={[
            "Casket cover",
            "Heart",
            "Bowl",
            "Wreath",
            "Sprays",
            "Croatian Tributes",
            "Cross",
            "Letters",
            "Singles/Petals",
          ]}
        />
      </div>
      <div className="contents width-100 row">
        <div className="col-md-12 product-content">
          <div className="hor-line"></div>
          <div
            className="row"
            style={{ paddingBottom: "200px", minHeight: "750px" }}
          >
            {isLoading ? (
              <LoadingDiv
                amount={11}
                noDelay={true}
                speed={2}
                CoverDivClass={"col-lg-3 col-md-6 col-sm-12 margin-top-10"}
                height={"95px"}
              />
            ) : products.length ? (
              products.map((val, index) => {
                return (
                  <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                    <ProductListElement
                      toggleSelect={() => {
                        setSelectedProduct(val);
                      }}
                      hidder={() => {
                        setSelectedProduct(null);
                      }}
                      content={val.content}
                      prod={val}
                    />
                  </div>
                );
              })
            ) : (
              <p className="font-medium width-100 absolute-center">
                Products not found
              </p>
            )}
          </div>
          <div className="row width-100 absolute-center">
            <div className="col-lg-4 col-md-8 col-sm-12">
              <Pagenation
                pages={allPage}
                setActivePage={(a) => {
                  setPage(a);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ProductListElement(props) {
  let content = Object.keys(JSON.parse(props.content || "")) || [];
  // console.log('prod detail ',props.prod)
  return (
    <div
      className="selectable-card center-hor relative"
      onClick={() => {
        props.toggleSelect();
      }}
    >
      {props.prod.image ? (
        <div
          className="product-img list width-25"
          style={{
            backgroundImage:
              "url(" + BASE_SPACE_URL + "" + props.prod.image + ")",
          }}
        ></div>
      ) : props.prod.product_type === "Casket cover" ? (
        <div className="width-20">
          <GiGraveFlowers className="icon product-img XL grey" />
        </div>
      ) : (
        <div className="width-20">
          <BsFlower3 className="icon product-img XL grey" />
        </div>
      )}
      <div className="text width-75">{props.prod.product_name}</div>
      <div className="absolute-center font-small code">
        {props.prod.code || "Code"}
      </div>
      <div className="center-hor flowers float-bottom-right">
        <Tag message={props.prod.product_type || "N/A"} />
      </div>
      {/* <div className='absolute-center font-small type'>{props.prod.type || 'Type Name'}</div> */}
    </div>
  );
}
