import { SummaryTable } from "../../components";
import ExportTools from "../ExportTools";

export default function FlowerContentList(props) {
  return (
    <div
      ref={props.referece}
      className={
        props.show
          ? "order-side-slide flowers light-box-shadow active border-rad-top-LR"
          : "light-box-shadow order-side-slide border-rad-top-LR"
      }
    >
      <div
        style={{ background: "#6a6a6a" }}
        className=" width-100 padding-10 color-white center-hor space-between"
      >
        <h5>Flower Contents</h5>
        <ExportTools
          exportName={"flowercontent" + props.date}
          jsonExport={Object.keys(props.flowers || {}).map((each) => {
            return { flower: each, amount: props.flowers[each] };
          })}
          addIconClass={"white"}
        />
      </div>
      <div className="width-100">
        <div className="width-100 font-small header-small">
          Flower Contents for {props.date}
        </div>
        <div className="width-100 font-small header-small color-red">
          Warning! Flower counts are not accurate as some registered products do
          not have flower contents.
        </div>
        <SummaryTable
          numbered={true}
          tableHeader={["Flower", "Amount"]}
          nameProperty={true}
          data={props.flowers || {}}
        />
      </div>
    </div>
  );
}
