import React, { useState, useEffect } from "react";
import {
  TableList,
  ButtonWithIcon,
  SearchField,
  WorkOrderDetailSide,
  DatePickerSmall,
  ToggleOrderType,
  LoadingDiv,
  orderObject,
  filter_products_by_type,
  ToReadableDate,
  replaceCharacter,
  CalendarPagePicker,
  TextInputFieldWithRelatedResult,
  RunsheetCards,
} from "../components";
import "bootstrap/dist/css/bootstrap.css";
import * as IconsBS from "react-icons/bs";
import * as IconsAI from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import axios from "axios";
import moment from "moment";
import { PopUpAlert } from "../components/movableDragDropArea";

import { BASE_SERVER_URL } from "../sampleData";
import { toast } from "react-toastify";

import { useNavigate, useParams } from "react-router-dom";

export function MergeWorkOrders(order) {
  let objs = {};
  order.forEach((each, index) => {
    objs[each.parent_funeral_id] = objs[each.parent_funeral_id]
      ? [...objs[each.parent_funeral_id], each]
      : [each];
  });
  return objs;
}

export function WorkOrderListPage(props) {
  document.title = props.title || "Ray`s Florist App";
  const { runId, runSheetDate, listDateParam, type, serial } = useParams();
  let listDateParamFliped = "";

  if (listDateParam) {
    listDateParamFliped = replaceCharacter(listDateParam, "-", "/");
  }

  const [orders, setOrders] = React.useState([]);
  const [retailOrders, setRetailOrders] = React.useState([]);
  const [dates, setDates] = React.useState([]);
  const [selectedRunOrders, setSelectedRunOrders] = React.useState([]);
  const [selectedWorkOrder, setSelectedWorkOrder] = React.useState(false);
  const [selectedWorkOrderOrdered, setSelectedWorkOrderOrdered] =
    React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [makeRunSheet, setMakeRunSheet] = React.useState(
    props.newRunSheet || props.editRun || false
  );
  const [typePropmt, setTypePropmt] = React.useState(false);
  const [preSelectedRunState, setPreSelectedRun] = useState({});
  const [selectedForRunSheetIds, setSelectedForRunSheetIds] = useState({});

  const [listDate, setListDate] = React.useState(
    runSheetDate
      ? replaceCharacter(runSheetDate, "-", "/")
      : moment(new Date()).format("DD/MM/YYYY")
  );
  const [orderType, setOrderType] = React.useState(
    props.orderType || "Funeral"
  );
  const [sortBy, setSortBy] = React.useState("funeral_date");
  const [query, setQuery] = React.useState("");
  const [allPage, setAllPage] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const pageLimit = 100;
  const sortDirection = { funeral_date: -1, date: -1 };
  //loadings

  const [listIsLoading, setListIsLoading] = useState(false);
  const [runListIsLoading, setRunListIsLoading] = useState(false);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const [oldRunsLoading, setOldRunsLoading] = useState(false);
  const [settings, setSettings] = useState({});
  const [listFilter, setListFilter] = useState({});
  const [oldRunSheets, setOldRunSheets] = useState([]);
  const [oldRunSheetProductTypes, setOldRunSheetProductTypes] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (props.showOne) {
      axios
        .get("http://localhost:9999/work-orders/get-by-order")
        .then((doc) => {
          setSelectedWorkOrder(doc.data[0].body2[0]);
          setOrders(MergeWorkOrders(doc.data[0].body1.Funeral.data));
        });
    }

    if (props.editRun) {
      setListDate(replaceCharacter(runSheetDate, "-", "/"));
      let url = BASE_SERVER_URL + "/run/getRun/" + runId;
      axios.get(url).then((doc) => {
        if (doc.data.status === "success") {
          setSelectedRunOrders(doc.data.response[0].deliveries);
          setSelectedForRunSheetIds(() => {
            let ret = {};
            doc.data.response[0].deliveries.forEach((each) => {
              if (each.parent_funeral_id && each.order_type === "Funeral") {
                ret[each.work_order_id] = ret[each.work_order_id]
                  ? ret[each.work_order_id]
                  : {};
                ret[each.work_order_id]["parent_funeral_id"] =
                  each.parent_funeral_id;
                ret[each.work_order_id] = {
                  ...(ret[each.work_order_id] ? ret[each.work_order_id] : {}),
                  [each.product_type.toLowerCase() === "casket cover"
                    ? "casket cover"
                    : "tributes"]: "true",
                };
              } else if (each.order_type === "Retail") {
                ret[each.work_order_id] = { all: true };
              }
            });
            return ret;
          });
          let x = { ...doc.data.response[0] };
          delete x.deliveries;
          localStorage.setItem("run-details", JSON.stringify(x));
        }
      });
    }
    let url2 = BASE_SERVER_URL + "/settings/getAll";
    axios
      .get(url2)
      .then((doc) => {
        // console.log('setting selected ids ',doc.data.response[0])
        if (doc.data.status === "success") {
          setSettings(doc.data.response[0]);
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "selected-products",
      JSON.stringify(selectedForRunSheetIds)
    );
  }, [selectedForRunSheetIds]);
  useEffect(() => {
    if (!makeRunSheet) {
      setSelectedForRunSheetIds({});
    }
    props.ChangeGlobalState(makeRunSheet);
  }, [makeRunSheet]);

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      if (localStorage.getItem("run-sheet-state") === "active") {
        setSelectedRunOrders(
          JSON.parse(localStorage.getItem("selected-run-orders"))
        );
        setSelectedForRunSheetIds(
          JSON.parse(localStorage.getItem("selected-products"))
        );
      }
      if (localStorage.getItem("run-sheet-state") === "cancel") {
        localStorage.setItem("selected-run-orders", "[]");
        localStorage.setItem("selected-products", "{}");
        localStorage.setItem("run-details", "{}");
        localStorage.setItem("old-run-sheets", "[]");
      }
      if (localStorage.getItem("run-sheet-state") === "closed") {
        setSelectedForRunSheetIds({});
        setMakeRunSheet(false);
        navigate("/DeliveryRunList");
      }
    });
  }, []);

  const [deleteLoading, setDeleteLoading] = useState(false);

  function deleteWorkOrder(a) {
    let url = BASE_SERVER_URL + "/wo/deleteWorkOrder/" + a;
    setDeleteLoading(true);
    axios
      .get(url)
      .then((doc) => {
        setDeleteLoading(false);
        if (doc.data.status === "success") {
          refreshList();
          setSelectedWorkOrder(false);
          toast.success("Work Order Deleted", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch(function (error) {
        setDeleteLoading(false);
      });
  }

  const typeSort = { Funeral: "funeral_date", Retail: "delivery_date" };

  React.useEffect(() => {
    setSortBy(typeSort[orderType]);
    if (dates.length > 0 && props.orderType === "Funeral") {
    }
    refreshList(makeRunSheet);
  }, [listDate, orderType, page, allPage, dates, listFilter]);

  function refreshList(runsheet = false, allDate = false) {
    if (props.orderType === "Funeral" && listDate === "") {
      return false;
    }
    setListIsLoading(true);
    setOldRunsLoading(true);
    let url = BASE_SERVER_URL + "/wo/getAllBy/";
    let filter = {
      filter: {
        ...(orderType ? { type: orderType } : {}),
        ...(listDate && !allDate
          ? orderType === "Funeral"
            ? { funeral_date: listDate }
            : { delivery_date: listDate }
          : {}),
      },
      page: page,
      byQuery: query,
      limit: pageLimit,
      sortBy: allDate ? "timestamp" : sortBy,
      sortDirection: sortDirection[sortBy] || -1,
    };
    setLoadingFailed(false);
    axios
      .post(url, filter)
      .then((doc) => {
        if (doc.data.status === "success") {
          if (!runsheet) {
            setListIsLoading(false);
          } else {
            let runUrl = BASE_SERVER_URL + "/run/getAllAddedProducts/";
            axios
              .post(runUrl, {
                date: listDate,
                ...(props.editRun ? { exclude: runId } : {}),
              })
              .then((runs) => {
                setOldRunsLoading(false);
                localStorage.setItem(
                  "old-run-sheets",
                  JSON.stringify(runs.data.response)
                );
                let oldDeliveries = {};
                runs.data.response.forEach((each) => {
                  Object.keys(each.deliveries).map((woID) => {
                    let delivery = each.deliveries[woID];
                    let NewLen = JSON.parse(
                      (delivery || {}).selected_types || "[]"
                    ).length;
                    oldDeliveries[woID] = {
                      ...oldDeliveries[woID],

                      ...{
                        cue: delivery.cue,
                        parent_funeral_id: delivery.parent_funeral_id,
                        [delivery.product_type]: true,
                        selected_types: [
                          ...((oldDeliveries[woID] || {}).selected_types || []),
                          ...JSON.parse(delivery.selected_types || []),
                        ],
                        type_cues: [
                          ...((oldDeliveries[woID] || {}).type_cues || []),
                          ...Array(NewLen).fill(delivery.cue),
                        ],
                      },
                    };
                  });
                });
                setOldRunSheets(runs.data.response);
                setOldRunSheetProductTypes(oldDeliveries);
                setListIsLoading(false);
              });
          }
          let respData = doc.data.response;
          if (Object.keys(listFilter).length > 0) {
            respData = respData.filter((each) => {
              let val = false;
              let comp;
              Object.keys(listFilter).forEach((e) => {
                if (e === "fd_code") {
                  comp = JSON.parse(each.fd_code || "{}").code;
                } else {
                  comp = each;
                }
                if (comp === listFilter[e]) {
                  val = true;
                }
              });
              return val;
            });
          }
          let toOrder =
            respData.length && respData[0].type === "Funeral"
              ? MergeWorkOrders(respData || [])
              : respData;
          setOrders(toOrder);
        } else {
          setLoadingFailed(true);
        }
      })
      .catch(function (error) {
        setLoadingFailed(true);
      });
  }

  const turnType = { Retail: "Funeral", Funeral: "Retail" };
  React.useEffect(() => {
    if (makeRunSheet) {
      let url = BASE_SERVER_URL + "/wo/getAllBy/";
      let filter = {
        filter: {
          ...{ type: turnType[orderType] },
          ...(listDate
            ? orderType === "Retail"
              ? { funeral_date: listDate }
              : { delivery_date: listDate }
            : {}),
        },
        page: page,
        byQuery: query,
        limit: pageLimit,
        sortBy: sortBy,
        sortDirection: sortDirection[sortBy] || -1,
      };
      setRunListIsLoading(true);
      axios
        .post(url, filter)
        .then((doc) => {
          setRunListIsLoading(false);
          if (doc.data.status === "success") {
            let toOrder = doc.data.response;
            setRetailOrders(toOrder);
          } else {
          }
        })
        .catch(function (error) {});
    }
  }, [makeRunSheet, listDate]);
  React.useEffect(() => {
    setSelectedWorkOrderOrdered(orderObject(selectedWorkOrder, orderType));
  }, [selectedWorkOrder]);

  React.useEffect(() => {
    if (localStorage.getItem("run-sheet-state") === "active") {
      let selected = {};
      selectedRunOrders.forEach((each) => {
        (typeof each.products === "string"
          ? JSON.parse(each.products || "[]")
          : each.products || []
        ).forEach((eachProducts) => {
          let woID = eachProducts.work_order_id
            ? eachProducts.work_order_id
            : each.work_order_id;
          selected[woID] = selected[woID] || {};
          selected[woID] = {
            parent_funeral_id: each.parent_funeral_id,
            ...{ [each.product_type]: true },
            selected_types: [
              ...(selected[woID].selected_types || []),
              eachProducts.product_type,
            ],
          };
        });
      });
      setSelectedForRunSheetIds(selected);
      localStorage.setItem(
        "selected-run-orders",
        JSON.stringify(selectedRunOrders)
      );
    }
  }, [selectedRunOrders]);

  const [alert, setAlert] = useState({});

  return (
    <div
      className="data-input center-ver-col"
      style={makeRunSheet ? { width: "97%", marginTop: "20px" } : {}}
    >
      {Object.keys(alert).length > 0 && (
        <PopUpAlert
          info={alert}
          onClose={() => {
            setAlert({});
          }}
        />
      )}
      <WorkOrderDetailSide
        deleteLoading={deleteLoading}
        remove={(a) => {
          setAlert({
            title: "Confirming File Deletion",
            message:
              "Are you sure you want to permanently delete the work order?",
            type: "error",
            confirmPrompt: true,
            callback: () => {
              deleteWorkOrder(a);
              setAlert({});
            },
          });
        }}
        refresh={() => {
          refreshList();
        }}
        hidder={() => {
          setSelectedWorkOrder(false);
        }}
        active={selectedWorkOrder}
        orderType={orderType}
        show={
          Object.entries(selectedWorkOrderOrdered).length
            ? selectedWorkOrderOrdered
            : { ids: { _id: "" } }
        }
      />
      <header
        className="row large main space-between padding-5 width-100 relative center-hor"
        style={{ margin: "0px" }}
      >
        <div
          className={
            "center-hor col-sm-12 wo-filter-tools space-between padding-0 " +
            (makeRunSheet ? "col-lg-12 col-md-12" : "col-lg-6 col-md-6")
          }
        >
          <div className={makeRunSheet ? "display-flex" : "center-hor"}>
            {!makeRunSheet ? (
              <ToggleOrderType
                orderType={orderType}
                toggleOptions={{
                  Funeral: "/WorkOrderList/funeral",
                  Retail: "/WorkOrderList/retail",
                }}
              />
            ) : (
              ""
            )}
            <DatePickerSmall
              value={listDate}
              onChageSetter={(a) => {
                setListDate(ToReadableDate(a.value, [], "/"));
              }}
              name="funeral_date"
              label="Date"
              addClass="margin-left-10"
              icon={<IconsBS.BsCalendarFill className="icon grey" />}
              placeHolder="Date"
              light={true}
              noClearButton={true}
              clearDate={() => {
                setListDate("");
              }}
            />
            {listDate && !makeRunSheet && (
              <ButtonWithIcon
                addClass="width-auto margin-0 stretch-height font-small thin margin-left-10"
                value="Print Day Sheet"
                iconWidth="40px"
                borderRad="3px"
                icon={
                  <IconsAI.AiFillPrinter className="icon XL white width-100" />
                }
                clickTo={() => {
                  window.open(
                    "/workorders/print/" +
                      JSON.stringify({
                        filter: {
                          [orderType === "Funeral"
                            ? "funeral_date"
                            : "delivery_date"]: replaceCharacter(
                            listDate,
                            "/",
                            "-"
                          ),
                          type: orderType,
                        },
                      }),
                    "_blank"
                  );
                }}
              />
            )}
            <ButtonWithIcon
              addClass={
                (makeRunSheet ? "light-red-bg" : "") +
                " width-auto margin-left-10 stretch-height font-small thin"
              }
              value={
                makeRunSheet
                  ? !props.editRun
                    ? "Cancel Making Run Sheet"
                    : "Cancel Editing Run Sheet"
                  : "Make Run Sheet"
              }
              iconWidth="40px"
              labelClass={makeRunSheet ? "color-white" : ""}
              borderRad="3px"
              clickTo={() => {
                if (orderType === "Funeral") {
                  if (makeRunSheet) {
                    if (props.editRun) {
                      localStorage.setItem("run-sheet-state", "cancel");
                      window.open("/WorkOrderList/funeral", "_self");
                    } else {
                      setMakeRunSheet(false);
                      setSelectedRunOrders([]);
                      localStorage.setItem("run-sheet-state", "cancel");
                    }
                  } else {
                    localStorage.setItem("run-sheet-state", "active");
                    window.open(
                      "/RunSheetMaking",
                      "_blank",
                      "left=100,top=250,width=1500,height=720"
                    );
                    setMakeRunSheet(true);
                    refreshList(true);
                  }
                } else {
                  localStorage.setItem("run-sheet-state", "active");
                  window.open(
                    "/RunSheetMaking",
                    "_blank",
                    "left=100,top=250,width=1500,height=720"
                  );
                  window.open("/WorkOrderList/newRunSheet", "_self");
                }
              }}
              iconClass={makeRunSheet ? "red-bg" : ""}
              icon={
                makeRunSheet ? (
                  <MdCancel className={" icon XL white width-100"} />
                ) : (
                  <IconsAI.AiOutlinePlus
                    className={" icon XL white width-100"}
                  />
                )
              }
            />
            {makeRunSheet ? (
              <RunsheetCards
                num={0}
                newSheet={true}
                details={JSON.parse(
                  localStorage.getItem("run-details") || "{}"
                )}
                allDetails={selectedRunOrders}
              />
            ) : (
              ""
            )}
            {makeRunSheet ? (
              oldRunsLoading ? (
                <LoadingDiv
                  height={"120px"}
                  speed={2}
                  style={{
                    width: "300px",
                    borderRadius: "8px",
                    margin: "5px 4px",
                    // border: "1px solid rgb(230 217 190)",
                  }}
                  amount={3}
                />
              ) : (
                oldRunSheets.map((each, index) => {
                  return (
                    <RunsheetCards num={index} details={each.runDetails} />
                  );
                })
              )
            ) : (
              ""
            )}
            {!makeRunSheet ? (
              <ButtonWithIcon
                addClass="width-auto margin-left-10 stretch-height font-small thin"
                value="Add New Order"
                iconWidth="40px"
                borderRad="3px"
                icon={
                  <IconsAI.AiOutlinePlus className="icon XL white width-100" />
                }
                clickTo={() => {
                  navigate("/ChooseOrderType");
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        {makeRunSheet ? (
          ""
        ) : (
          <div className="col-lg-6 col-md-6 col-sm-12 center-hor flex-end padding-0 ">
            {/* <FDSelectionField searchTrigger={()=>{refreshList()}} setQuery={(a)=>{setQuery(a)}} addClass=' stretch-height'/> */}

            <div className="center-hor margin-right-5">
              <TextInputFieldWithRelatedResult
                value={listFilter.fd_code || ""}
                onChageSetter={(a) => {}}
                onSelect={(a) => {
                  setListFilter({ fd_code: a });
                }}
                disabled={false}
                containerClass={"hover"}
                compClass={""}
                name="fd_code"
                addClass="stretch search thin"
                placeHolder="Filter By FD"
              />
              {Object.keys(listFilter).length > 0 ? (
                <MdCancel
                  className="form-button"
                  onClick={() => {
                    setListFilter({});
                  }}
                />
              ) : (
                ""
              )}
            </div>
            <SearchField
              searchTrigger={() => {
                refreshList(false, true);
              }}
              setQuery={(a) => {
                setQuery(a);
              }}
              addClass=" stretch-height"
            />
          </div>
        )}
      </header>
      {makeRunSheet && false ? (
        ""
      ) : (
        <CalendarPagePicker
          changeDate={(a) => {
            setListDate(a);
          }}
          selectedDate={listDate}
        />
      )}
      {/* {console.log('orders : ',orders)} */}
      <TableList
        selectedWorkOrder={selectedWorkOrder}
        toggleShow={(x, y) => {
          if (!makeRunSheet) {
            setSelectedWorkOrder(x);
          } else {
            propmtForType(x, y);
          }
        }}
        setDateFilter={(a) => {
          setListDate(a);
          setSortBy(typeSort[orderType || "funeral_date"]);
        }}
        data={orders}
        listDate={listDate}
        settings={settings}
        dataRetails={retailOrders}
        orderType={orderType}
        listStart={(page - 1) * pageLimit}
        loading={listIsLoading}
        runLoading={runListIsLoading}
        loadingFailed={false && loadingFailed}
        makeRunSheet={makeRunSheet}
        reload={() => {
          refreshList();
        }}
        sortBy={sortBy}
        selectedForRunSheet={selectedForRunSheetIds}
        oldSelectedForRunSheet={oldRunSheets}
        oldSelectedTypes={oldRunSheetProductTypes}
      />
      {typePropmt && (
        <ToolTipPropmpt
          hidder={() => {
            setPreSelectedRun({});
            setTypePropmt(false);
          }}
          orderType={"Funeral"}
        />
      )}
    </div>
  );

  function upDateAndCallback(value, callback) {
    setPreSelectedRun(value);
    callback(value);
  }

  function propmtForType(data, extra) {
    // console.log('propmt for active ',data.type, extra)
    if (data.type === "Retail") {
      upDateAndCallback(data, (a) => {
        handleSubmit(
          "products_selected",
          "Flower Arrangements",
          "Retail",
          data
        );
      });
    } else {
      setTypePropmt(true);
      upDateAndCallback(data, () => {});
    }
  }

  function ToolTipPropmpt(props) {
    const [mousePos, setMousePos] = useState({});

    useEffect(() => {
      const handleMouseMove = (event) => {
        if (Object.keys(mousePos).length === 0) {
          setMousePos({ x: event.clientX, y: event.clientY });
        }
      };

      window.addEventListener("click", handleMouseMove);
      // window.removeEventListener('click',handleMouseMove);

      return () => {};
    }, []);
    return (
      <div
        style={{ left: mousePos.x - 120, top: mousePos.y - 100 }}
        className="tooltip-prompt xlight-box-shadow"
      >
        <div className="msg light-box-shadow relative">
          Select Product Type
          <span className="float-right">
            <MdCancel
              onClick={props.hidder}
              className="icon mid white width-auto"
            />
          </span>
        </div>
        <div className="opts">
          <button
            onClick={() => {
              handleSubmit(
                "casket_cover_selected",
                "Casket Cover",
                props.orderType
              );
            }}
          >
            Casket Cover
          </button>
          <button
            onClick={() => {
              handleSubmit("tributes_selected", "Tributes", props.orderType);
            }}
          >
            Tribute
          </button>
        </div>
        <div className="corner light-box-shadow"></div>
      </div>
    );
  }

  function handleSubmit(a, b, orderTypee, preVal) {
    const preSelectedRun =
      Object.keys(preSelectedRunState).length > 0
        ? preSelectedRunState
        : preVal;
    setSelectedRunOrders((prev) => {
      let t = [...prev];
      let g = [...prev];
      let found = g.filter((each, index) => {
        return (
          each.work_order_id === preSelectedRun._id &&
          each.product_type.toLowerCase() === b.toLowerCase()
        );
      });
      let foundInOlds = { found: false };

      oldRunSheets.forEach((run) => {
        let inOlds =
          Boolean(run.deliveries[preSelectedRun._id]) &&
          run.deliveries[preSelectedRun._id].product_type.toLowerCase() ===
            b.toLowerCase();
        if (inOlds) {
          foundInOlds.found = true;
          foundInOlds.detail = run.runDetails;
        }
      });

      // if(found.length === 0 && JSON.parse(preSelectedRun[a] || '').length > 0){
      if (found.length === 0 && !foundInOlds.found) {
        let added =
          orderTypee === "Funeral"
            ? {
                fd_code: preSelectedRun.fd_code,
                late_person_name: preSelectedRun.late_person_name,
              }
            : {};
        let runLocation =
          a === "casket_cover_selected"
            ? preSelectedRun.casket_cover_delivery_location
            : a === "tributes_selected"
            ? preSelectedRun.tribuite_delivery_location
            : a === "products_selected"
            ? preSelectedRun.delivery_location
            : "";

        let fromParentsCC = [];
        let fromParentsTri = [];
        let forTaggingCC = {};
        let forTaggingTri = {};
        (orderTypee === "Funeral"
          ? orders[preSelectedRun.parent_funeral_id]
          : []
        ).forEach((each) => {
          if (preSelectedRun._id !== each._id) {
            forTaggingTri[each._id] = {
              parent_funeral_id: preSelectedRun.parent_funeral_id,

              selected_types: JSON.stringify(
                JSON.parse(each.tributes_selected || "[]").map((each) => {
                  return each.product_type === "Casket cover"
                    ? each.code
                    : each.product_type;
                })
              ),
              ...(a === "casket_cover_selected"
                ? { "casket cover": "true" }
                : a === "tributes_selected"
                ? { tributes: "true" }
                : a === "products_selected"
                ? { all: "true" }
                : { "N/A": "" }),
            };
            forTaggingCC[each._id] = {
              parent_funeral_id: preSelectedRun.parent_funeral_id,

              selected_types: JSON.stringify(
                JSON.parse(each.casket_cover_selected || "[]").map((each) => {
                  return each.product_type === "Casket cover"
                    ? each.code
                    : each.product_type;
                })
              ),
              ...(a === "casket_cover_selected"
                ? { "casket cover": "true" }
                : a === "tributes_selected"
                ? { tributes: "true" }
                : a === "products_selected"
                ? { all: "true" }
                : { "N/A": "" }),
            };

            fromParentsCC = [
              ...fromParentsCC,
              ...JSON.parse(each.casket_cover_selected || "[]").map((one) => {
                one.work_order_id = each._id;
                return one;
              }),
            ];
            fromParentsTri = [
              ...fromParentsTri,
              ...JSON.parse(each.tributes_selected || "[]").map((one) => {
                one.work_order_id = each._id;
                return one;
              }),
            ];
          }
        });

        let runProducts =
          a === "casket_cover_selected"
            ? [
                ...JSON.parse(preSelectedRun[a] || "[]"),
                ...fromParentsCC,
                ...filter_products_by_type(
                  [
                    ...fromParentsTri,
                    ...JSON.parse(preSelectedRun.tributes_selected || "[]").map(
                      (one) => {
                        one.work_order_id = preSelectedRun._id;
                        return one;
                      }
                    ),
                  ],
                  JSON.parse(settings.cc_types || '["Casket cover"]')
                ),
              ]
            : a === "tributes_selected"
            ? filter_products_by_type(
                [
                  ...fromParentsTri,
                  ...JSON.parse(preSelectedRun.tributes_selected || "[]").map(
                    (one) => {
                      one.work_order_id = preSelectedRun._id;
                      return one;
                    }
                  ),
                ],
                JSON.parse(settings.cc_types || '["Casket cover"]'),
                true
              )
            : a === "products_selected"
            ? JSON.parse(preSelectedRun[a])
            : [];

        // console.log("run sheet pre runs ", runProducts);
        if (runLocation === "" || runProducts.length === 0) {
          setAlert({
            title: "Unable to incude in Delivery Run Sheet",
            message:
              runProducts.length === 0
                ? "Product not found"
                : "Delivery location not found ",
            type: "error",
          });
          return prev;
        }
        t.push({
          ...added,
          delivery_date:
            orderTypee === "Funeral"
              ? preSelectedRun.funeral_date
              : preSelectedRun.delivery_date,
          work_order_id: preSelectedRun._id,
          parent_funeral_id: preSelectedRun.parent_funeral_id,
          order_type: preSelectedRun.type,
          name:
            orderTypee === "Funeral"
              ? preSelectedRun.late_person_name
              : preSelectedRun.recipient_name,
          product_type: b,
          time:
            orderTypee === "Retail"
              ? preSelectedRun.time_of_delivery
              : a === "casket_cover_selected"
              ? preSelectedRun.casket_cover_delivery_time
              : preSelectedRun.funeral_time,
          location: runLocation,
          products: runProducts,
        });
        // setSelectedForRunSheetIds((prev) => {
        //   let arr = { ...prev };
        //   arr[preSelectedRun._id] = arr[preSelectedRun._id]
        //     ? arr[preSelectedRun._id]
        //     : {};
        //   if (preSelectedRun.type === "Funeral") {
        //     arr[preSelectedRun._id]["parent_funeral_id"] =
        //       preSelectedRun.parent_funeral_id;
        //   }
        //   arr[preSelectedRun._id] = {
        //     ...arr[preSelectedRun._id],
        //     ...(a === "casket_cover_selected"
        //       ? { "casket cover": "true" }
        //       : a === "tributes_selected"
        //       ? { tributes: "true" }
        //       : a === "products_selected"
        //       ? { all: "true" }
        //       : { "N/A": "" }),
        //   };
        //   arr[preSelectedRun._id]["selected_types"] = runProducts.map(
        //     (each) => {
        //       return each.product_type;
        //     }
        //   );
        //   return { ...arr, ...forTaggingTri, ...forTaggingCC };
        // });
        return t;
      } else {
        let message = foundInOlds.found
          ? "Deliery has already been added to previous runsheet for " +
            foundInOlds.detail.drivers_name
          : JSON.parse(preSelectedRun[a]).length === 0
          ? "No product found saved under this Work Order"
          : "Delivery already added to current run sheet";

        setAlert({ title: "Can Not Add to Run Sheet", message: message });

        return prev;
      }
    });
    setPreSelectedRun({});
    setTypePropmt(false);
  }
}
