import React, { createRef, useEffect, useMemo, useState } from "react";
import logo from "./images/logo.png";
import profile from "./images/profile.jpg";
import flowerImage from "./images/cc.png";
import logoRight from "./images/logo-right.png";
import { useOutsideAlerter } from "./script";
import axios from "axios";
import moment, { monthsShort } from "moment";

// import ReactToPdf from 'react-to-pdf'

import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";

import { Pagenation } from "./components/pagenation";
import {
  keyToNameList,
  BASE_SERVER_URL,
  ProductTypes,
  BASE_SPACE_URL,
  weekDays,
  ThemeColor,
  EaselProducts,
} from "./sampleData";
import DateTimePicker from "react-datetime-picker";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import * as IconsBS from "react-icons/bs";
import * as IconsAI from "react-icons/ai";
import * as IconsMD from "react-icons/md";
import * as IconsBI from "react-icons/bi";
import * as IconsFA from "react-icons/fa";
import { GiGraveFlowers } from "react-icons/gi";
import { TbTruckDelivery } from "react-icons/tb";
import { HiUsers } from "react-icons/hi";
import { CgOrganisation } from "react-icons/cg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import { WidgetTitle } from "./pages/dashboard";
import RoundCheckBox from "./components/CheckBox";

const ReactToPdf = "react-to-pdf";
// const DateTimePicker = 'react-datetime-picker';

function ScreeSize() {
  if (window.innerWidth <= 770) {
    return "small";
  } else {
    return "";
  }
}

function Logo(props) {
  return (
    <div className={"logo " + (props.simple ? "simple" : "")}>
      {/* <img className="left"  /> */}
      <a href="/">
        <img src={logo} />
      </a>
      {/* <img className="right" src={logoRight} /> */}
    </div>
  );
}

export function Header(props) {
  return (
    <div className="no-print">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        className={"header absolute-center " + (props.simple ? "simple" : "")}
      >
        {props.simple ? (
          ""
        ) : (
          <div className="btn-slider absolute-center float-absolute-left">
            {!props.toggleActive ? (
              <IconsBS.BsList
                className="icon-btn width-100 XL"
                onClick={props.toggleSideNav}
              />
            ) : (
              <IconsMD.MdClose
                className="icon-btn width-100 XL"
                onClick={props.toggleSideNav}
              />
            )}
          </div>
        )}
        <Logo simple={props.simple} />
        {props.simple
          ? ""
          : [
              <DateTime />,
              <div className="float-right">
                <ProfileView
                  currentUser={props.currentUser}
                  logout={() => {
                    props.logout();
                  }}
                />
              </div>,
            ]}
      </div>
    </div>
  );
}

export function SideNavigationSlider(props) {
  const nav = React.useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  function redirect(a) {
    props.hidder();
    navigate(a);
  }
  // console.log('logger current user ', props.currentUser)
  useEffect(() => {
    tippy(".sidenavs", {
      content(reference) {
        return reference.previousSibling.innerText;
      },
      placement: "right",
    });
  }, []);

  useOutsideAlerter(nav, () => {
    props.setActive(false);
  });
  return (
    <>
      <div
        ref={nav}
        className={
          props.active ? "sideNav no-print" : "sideNav noshow no-print"
        }
      >
        {/* <Link to="/blogs"><span className='gold-themed'>Dashboard</span></Link> */}
        <span
          onClick={() => redirect("/")}
          className={location.pathname === "/" ? "grey-themed" : ""}
        >
          <div className="width-80">Dashboard</div>
          <span className="width-20 sidenavs" id="myButton">
            <IconsMD.MdDashboard
              className={
                "icon mid " + (location.pathname === "/" ? "white" : "grey")
              }
            />{" "}
          </span>{" "}
        </span>
        <span
          onClick={() => redirect("/WorkOrderList")}
          className={
            location.pathname === "/WorkOrderList" ? "grey-themed" : ""
          }
        >
          <div className="width-80">Work Orders</div>
          <span className="width-20 sidenavs" id="myButton">
            <IconsMD.MdFactory
              className={
                "icon mid " +
                (location.pathname === "/WorkOrderList" ? "white" : "grey")
              }
            />{" "}
          </span>{" "}
        </span>
        <span
          onClick={() => redirect("/ordered-products")}
          className={
            location.pathname === "/ordered-products" ? "grey-themed" : ""
          }
        >
          <div className="width-80">Ordered Products</div>
          <span className="width-20 sidenavs" id="myButton">
            <IconsMD.MdLocalShipping
              className={
                "icon mid " +
                (location.pathname === "/ordered-products" ? "white" : "grey")
              }
            />{" "}
          </span>{" "}
        </span>
        <span
          onClick={() => redirect("/products")}
          className={location.pathname === "/products" ? "grey-themed" : ""}
        >
          <div className="width-80">Products</div>
          <span className="width-20 sidenavs">
            <GiGraveFlowers
              className={
                "icon mid " +
                (location.pathname === "/products" ? "white" : "grey")
              }
            />{" "}
          </span>{" "}
        </span>
        <span
          onClick={() => redirect("/DeliveryRunList")}
          className={
            location.pathname === "/DeliveryRunList" ? "grey-themed" : ""
          }
        >
          <div className="width-80">Delivery Runs</div>
          <span className="width-20 sidenavs">
            <TbTruckDelivery
              className={
                "icon mid " +
                (location.pathname === "/DeliveryRunList" ? "white" : "grey")
              }
            />{" "}
          </span>{" "}
        </span>
        <span
          onClick={() => redirect("/InventoryList")}
          className={
            location.pathname === "/InventoryList" ? "grey-themed" : ""
          }
        >
          <div className="width-80">Inventory</div>
          <span className="width-20 sidenavs">
            <IconsMD.MdOutlineInventory
              className={
                "icon mid " +
                (location.pathname === "/InventoryList" ? "white" : "grey")
              }
            />{" "}
          </span>{" "}
        </span>
        {(props.currentUser || {}).access === "Super Admin" && (
          <span
            onClick={() => redirect("/users")}
            className={location.pathname === "/users" ? "grey-themed" : ""}
          >
            <div className="width-80">Users</div>
            <span className="width-20 sidenavs">
              <HiUsers
                className={
                  "icon mid " +
                  (location.pathname === "/users" ? "white" : "grey")
                }
              />{" "}
            </span>{" "}
          </span>
        )}
        <span
          onClick={() => redirect("/FuneralDirectors")}
          className={
            location.pathname === "/FuneralDirectors" ? "grey-themed" : "grey"
          }
        >
          <div className="width-80">Funeral Directors</div>
          <span className="width-20 sidenavs">
            <CgOrganisation
              className={
                "icon mid " +
                (location.pathname === "/FuneralDirectors" ? "white" : "grey")
              }
            />{" "}
          </span>{" "}
        </span>
        <span
          onClick={() => redirect("/customer-history")}
          className={
            location.pathname === "/customer-history" ? "grey-themed" : "grey"
          }
        >
          <div className="width-80">Customers History</div>
          <span className="width-20 sidenavs">
            <IconsMD.MdHistoryEdu
              className={
                "icon mid " +
                (location.pathname === "/customer-history" ? "white" : "grey")
              }
            />{" "}
          </span>{" "}
        </span>
        <span
          onClick={() => redirect("/work-history")}
          className={
            location.pathname === "/work-history" ? "grey-themed" : "grey"
          }
        >
          <div className="width-80">Work History</div>
          <span className="width-20 sidenavs">
            <IconsMD.MdWorkHistory
              className={
                "icon mid " +
                (location.pathname === "/work-history" ? "white" : "grey")
              }
            />{" "}
          </span>{" "}
        </span>
        <span
          onClick={() => redirect("/settings")}
          className={location.pathname === "/settings" ? "grey-themed" : "grey"}
        >
          <div className="width-80">Settings</div>
          <span className="width-20 sidenavs">
            <IconsMD.MdSettings
              className={
                "icon mid " +
                (location.pathname === "/settings" ? "white" : "grey")
              }
            />{" "}
          </span>{" "}
        </span>
        {/* <Link to="/products">Link</Link> */}
        {/* <Link to="/blogs"><span>Deliveries</span></Link>
            <Link to="/blogs"><span>Users</span></Link> */}
      </div>
      <Outlet />
    </>
  );
}

export function BasePage(props) {
  const [sideNavState, setSideNavState] = React.useState(false);
  const location = useLocation();
  const avoidHeader = ["/print/", "/test"];
  // console.log('current user btn Base Page',props.currentuser)
  if (
    avoidHeader.filter((each) => {
      return location.pathname.indexOf(each) !== -1;
    }).length
  ) {
    return "";
  }
  return (
    <div className="no-print">
      <Header
        currentUser={props.currentUser}
        logout={() => {
          props.logout();
        }}
        toggleActive={sideNavState}
        toggleSideNav={() => {
          setSideNavState((prev) => !prev);
        }}
        simple={props.removeHeaderAndNav}
      />
      {props.removeHeaderAndNav ? (
        ""
      ) : (
        <SideNavigationSlider
          currentUser={props.currentUser}
          hidder={() => {
            setSideNavState(false);
          }}
          active={sideNavState}
          setActive={setSideNavState}
        />
      )}
    </div>
  );
}

export function TextInputFieldWithRelatedResult(props) {
  const [value, setValue] = React.useState(props.value);
  const [loading, setLoading] = React.useState(false);

  let searchFromData = props.search || "FD_codes";

  const [searchFroms, setSearchForm] = useState({
    FD_codes: [],
    // flower_names:['Rose','Lili','Tulip','Orchid','Carnation','Hyacinth','Peruvian Lily'],
    // ...(true? {[searchFromData] : [...props.searchList]} : {})
  });
  useEffect(() => {
    if (searchFromData === "FD_codes") {
      setValue(props.value);
      let url = BASE_SERVER_URL + "/fd/getAll";
      setLoading(true);
      axios.get(url).then((response) => {
        setSearchForm((prev) => {
          let obj = { ...prev };
          obj.FD_codes = response.data.response;
          return obj;
        });
        setLoading(false);
      });
    }
    if (searchFromData === "flowers" || searchFromData === "product_types") {
      setValue(props.value);
      let url = BASE_SERVER_URL + "/settings/getAll";
      setLoading(true);
      axios.get(url).then((response) => {
        setSearchForm((prev) => {
          let obj = { ...prev };
          obj[searchFromData] = JSON.parse(
            response.data.response[0][searchFromData] || "[]"
          );
          return obj;
        });
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    // console.log("flower temp data change : props input after ", searchFroms);
  }, [searchFroms]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  const searchResults = [];

  let searchFrom = searchFroms[searchFromData] || [];

  const input = React.useRef("");
  const [relatedList, setRelatedList] = React.useState([]);
  const searchRef = React.useRef(null);
  useOutsideAlerter(searchRef, () => {
    setFocus(false);
  });
  const [focus, setFocus] = React.useState(false);

  function setter(a) {
    // console.log('setter ',a)

    setValue(a);
    let x = (searchFroms[searchFromData] || []).filter((val) => {
      return searchFromData === "FD_codes" ? val.code === a : val === a;
    });
    props.onChageSetter(x[0]);
  }

  React.useEffect(() => {
    let all = [];
    // console.log('searching after value change ', searchFroms, (searchFroms[searchFromData] || []))
    (searchFroms[searchFromData] || []).forEach((each) => {
      all.push(searchFromData === "FD_codes" ? each.code : each);
    });

    setRelatedList([]);
    //console.log('all of the codes ',all)xx
    let filtered = [];
    if (all.length > 0 && value !== "") {
      all.filter((each) => {
        //console.log('index in codes ',each.indexOf(value),each,value)
        if (
          (each || "").toLowerCase().indexOf((value || "").toLowerCase()) >= 0
        ) {
          filtered.push(each);
        }
      });
      setRelatedList((prev) => {
        return filtered;
      });
    }
    //console.log('all related codes ',filtered)
  }, [value]);
  let propsValue =
    searchFromData === "FD_codes" ? props.value.code : props.value;

  return loading ? (
    <LoadingDiv amount={0} speed={2} />
  ) : (
    <div class={"input relative " + props.compClass} ref={searchRef}>
      <input
        disabled={props.disabled}
        ref={input}
        onFocus={() => {
          setFocus(true);
        }}
        value={propsValue || value}
        onChange={(event) => {
          props.onChageSetter(event.target.value);
          setValue(event.target.value);
        }}
        name={props.name}
        className={
          (props.disabled && "bold-1") +
          " input-forms text-input font-medium " +
          props.addClass
        }
        type={props.type || "text"}
        placeholder={props.placeHolder}
      />
      {focus && (
        <div
          className={
            "related-results text-only center-ver-col " + props.containerClass
          }
        >
          {relatedList.length ? "" : "No Result"}
          {relatedList.map((val) => {
            return (
              <div
                class="width-100 small-list-card xlight-box-shadow text-left padding-10 font-medium touchable"
                onClick={() => {
                  if (props.onSelect) {
                    props.onSelect(val);
                  }
                  setter(val);
                  setFocus(false);
                }}
              >
                {val}
              </div>
            );
          })}
          {/* <ParentOrderSearchResultList setFocus={setFocus} selectResultVal={props.selectResultVal} data={searchResult}/> */}
        </div>
      )}
      {/* {//console.log(input.current.value)} */}
      <label
        className={
          focus || (input.current || {}).value || props.value
            ? "input focused"
            : "input"
        }
      >
        {props.placeHolder}
      </label>
    </div>
  );
}

export function LocationInputField(props) {
  const [address, setAddress] = useState("");
  const [focus, setFocus] = useState(false);
  const [addressInfo, setAddressInfo] = useState({});
  const field = React.useRef();

  const handleChange = (newAddress) => {
    // console.log("LocationChange : handleChange", newAddress);
    setAddress(newAddress);

    props.onChageSetter({
      name: props.name,
      value: JSON.stringify({ name: field.current.value }),
    });
  };

  const handleSelect = (newAddress) => {
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        // setAddressInfo(latLng)
        setAddressInfo((prev) => {
          let obj = { ...prev };
          return { ...prev, ...{ latlong: latLng } };
        });
        props.onChageSetter({
          name: props.name,
          value: JSON.stringify({
            name: newAddress,
            coordinates: latLng,
          }),
        });
      })
      .catch((error) => {
        console.error("Error latlng ", error);
      });
  };
  return (
    <PlacesAutocomplete
      value={props.value}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="width-100 relative">
          <input
            {...getInputProps({
              placeholder: props.placeHolder || "Search Location...",
              className:
                (props.disabled && "bold-1") +
                " input-forms text-input font-medium width-100 " +
                (Object.keys(addressInfo).length ? "lime-border" : ""),
            })}
            disabled={props.disabled}
            value={props.value}
            ref={field}
            onFocus={() => {
              setFocus(true);
            }}
          />
          <div
            className="autocomplete-dropdown-container width-100 bg-white light-box-shadow"
            style={{ position: "absolute", zIndex: "99" }}
          >
            {loading && <div className="font-medium">Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  className="font-medium related-list"
                >
                  <span
                    onClick={() => {
                      setAddress(suggestion.description);
                      props.onChageSetter({
                        name: props.name,
                        value: JSON.stringify({ name: suggestion.description }),
                      });
                      setAddressInfo((prev) => {
                        let obj = { ...prev };
                        return { ...prev, ...{ name: suggestion.description } };
                      });
                    }}
                  >
                    {suggestion.description}
                  </span>
                </div>
              );
            })}
          </div>
          <label
            className={
              (focus && field.current.value) || props.value
                ? "input focused"
                : "input"
            }
          >
            {props.placeHolder}
          </label>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export function TextInputField(props) {
  // console.log('Text Input Rendered : ',props.placeHolder)
  const [searchResult, setSearchResults] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [selectedParent, setSelectedParent] = useState(
    props.selectedParent || {}
  );

  const input = React.useRef("");
  const searchRef = React.useRef(null);

  React.useEffect(() => {
    if (props.textArea) {
      const textarea = input.current;
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  }, [props.value]);

  React.useEffect(() => {
    if (props.listRelated) {
      let url = BASE_SERVER_URL + "/wo/searchFunerals/" + searchString;
      axios.get(url).then((doc) => {
        // let data = ToReadableDate(doc.data.response,['funeral_date'])
        setSearchResults(doc.data.response);
      });
    }
  }, [searchString]);

  useOutsideAlerter(searchRef, () => {
    setFocus(false);
  });
  const [focus, setFocus] = React.useState(false || Boolean(props.value));

  if (Object.keys(selectedParent).length) {
    // console.log('order detail change parent data ',selectedParent)
    return (
      <ParentOrderSearchResultList
        showLimit={1}
        data={[selectedParent]}
        linked={true}
        addClass={"margin-bottom-10"}
        unLink={() => {
          setSelectedParent({});
          props.selectResultVal({});
        }}
      />
    );
  }
  const textFormatChecker = props.formatText
    ? (a) => {
        props.textFormatChecker(a);
      }
    : function (a) {
        return false;
      };
  return (
    <div class={"input relative " + props.addClass}>
      {!props.textArea ? (
        <input
          id={props.domId || ""}
          ref={input}
          onFocus={() => {
            setFocus(true);
          }}
          value={props.value}
          disabled={props.disabled}
          style={props.style || {}}
          onChange={(event) => {
            setSearchString(event.target.value);
            props.onChageSetter({
              name: event.target.name,
              value: event.target.value,
            });
          }}
          name={props.name}
          className={
            (props.disabled && "bold-1") +
            " input-forms text-input font-medium width-100 " +
            props.addClass +
            " " +
            (props.formatText &&
              !textFormatChecker(input.current.value || "") &&
              "red-border")
          }
          type={props.type || "text"}
          placeholder={props.placeHolder}
        />
      ) : (
        <textarea
          value={props.value}
          rows={props.rows || 5}
          ref={input}
          id={props.domId || ""}
          disabled={props.disabled}
          onFocus={() => {
            setFocus(true);
          }}
          onChange={(event) => {
            props.onChageSetter({
              name: event.target.name,
              value: event.target.value,
            });
          }}
          name={props.name}
          className={
            (props.disabled && "bold-1") +
            " width-100 text-input padding-10 font-medium " +
            props.addClass
          }
          placeholder={props.placeHolder}
        ></textarea>
      )}
      {props.listRelated && focus && (
        <div className="related-results center-ver-col" ref={searchRef}>
          {searchResult.length ? (
            <ParentOrderSearchResultList
              setFocus={setFocus}
              selectResultVal={(a) => {
                setSelectedParent(a);
                props.selectResultVal(a);
              }}
              data={searchResult}
              showLimit={5}
            />
          ) : (
            "No Result"
          )}
        </div>
      )}

      {props.noLable ? (
        ""
      ) : (
        <label
          className={
            (focus && input.current.value) || props.value
              ? "input focused"
              : "input"
          }
        >
          {props.placeHolder}
        </label>
      )}
      {props.actionButton && (
        <button
          onClick={() => {
            props.actionButtonAction(input.current.value);
          }}
          className={props.actionButtonClass}
        >
          {props.actionButtonValue}
        </button>
      )}
    </div>
  );
}

function ParentOrderSearchResultList(props) {
  // console.log('searchResult ',props.data);
  let data = props.data.slice(0, props.showLimit || 5);

  return data.map((funeral, index) => {
    return (
      <div
        key={index}
        style={props.linked ? { marginBottom: "15px !important" } : {}}
        className={
          "relative width-100 row search-result-row light-box-shadow " +
          props.addClass
        }
      >
        <span className="col-md-12 col-sm-12 bold-1">
          {funeral.late_person_name}
        </span>
        <div className="padding-0 col-md-12 col-sm-12">
          <div className="row">
            <div className="col-md-8 col-sm-8 ">
              <span>
                <IconsMD.MdLocationOn className="icon icon-small width-auto" />{" "}
                {shorten_string(
                  JSON.parse(funeral.funeral_location || "{}").name
                )}
              </span>
            </div>
            <div className="col-md-4 col-sm-4">
              <span>
                <IconsBI.BiTimeFive className="icon icon-small width-auto" />{" "}
                {ToReadableDate(
                  funeral.funeral_date,
                  [],
                  "/",
                  "ll",
                  "DD/MM/YYYY"
                )}
              </span>
            </div>
          </div>
        </div>
        {/* {//console.log('the funeral ',funeral)} */}
        {props.linked ? (
          <span className="icon-link absolute-center">
            <IconsMD.MdCancel
              onClick={() => {
                props.unLink();
              }}
              className="icon red width-100 mid white"
            />
          </span>
        ) : (
          <span className="icon-link absolute-center">
            <IconsAI.AiOutlineLink
              onClick={() => {
                props.setFocus(false);
                props.selectResultVal(funeral);
              }}
              className="icon width-100 mid white"
            />
          </span>
        )}
      </div>
    );
  });
}

export function FDSelectionField(props) {
  if (ScreeSize() === "small") {
    return "";
  }
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        props.searchTrigger();
      }}
    >
      <div className={"center-hor search width-auto"}>
        <input
          className="input-forms search text-input margin-0 border-rad-0"
          value={props.value}
          onChange={(event) => {
            props.setQuery(event.target.value);
          }}
          type="text"
          placeholder="Select Fueral Director"
        />
        {/* <span className='border-rad-0 absolute-center' onClick={()=>{props.searchTrigger()}}><IconsBS.BsSearch className='icon'/></span> */}
      </div>
    </form>
  );
}
export function SearchField(props) {
  if (ScreeSize() === "small") {
    return "";
  }
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        props.searchTrigger();
      }}
    >
      <div
        className={
          props.addClass ? "center-hor search " + props.addClass : "search"
        }
      >
        <input
          className="input-forms search text-input margin-0 border-rad-0"
          value={props.value}
          onChange={(event) => {
            props.setQuery(event.target.value);
          }}
          type="text"
          placeholder="Type to search by key word..."
        />
        <span
          className="border-rad-0 absolute-center"
          onClick={() => {
            props.searchTrigger();
          }}
        >
          <IconsBS.BsSearch className="icon" />
        </span>
      </div>
    </form>
  );
}
export function IconedButton() {
  return (
    <div className="">
      <button className="iconed-button small inline-hor">
        <div className="absolute-center">
          <IconsBS.BsSearch className="icon" />
        </div>
        <span className="text absolute-center">Name</span>
      </button>
    </div>
  );
}
export function ButtonMid(props) {
  if (props.loading) {
    return <LoadingWait />;
  }

  return (
    <button
      onClick={props.clickTo}
      className="button mid inline-hor absolute-center bold-1"
    >
      {props.value}
    </button>
  );
}
export function ButtonFlat(props) {
  if (props.loading) {
    return (
      <LoadingWait
        dotColor={props.dotColor || "white"}
        addStyle={
          props.style || {
            background: "#AAE49E",
            width: "132px",
            height: "40px",
            marginRight: "30px",
          }
        }
      />
    );
  } else {
    return (
      <button
        style={props.style || {}}
        disabled={props.disabled === "disabled" ? true : false}
        onClick={() => {
          props.clickTo();
        }}
        className={
          "button flat width-100 inline-hor absolute-center bold-1 " +
          props.disabled +
          " " +
          props.addClass
        }
      >
        {props.loadingValue ? <div class="loader"></div> : props.value}
      </button>
    );
  }
}

export function TextInputFieldWithIcon(props) {
  return (
    <div className="iconed-input">
      <input
        className={"input-forms text-input " + props.addClass}
        type="text"
        placeholder={props.placeHolder || "Text Here"}
      />
      <span className="field-icon">{props.icon}</span>
    </div>
  );
}

export function toName(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
}

export function FD_DropDown(props) {
  const [selected, setSelected] = React.useState("");
  const [lister, setLister] = React.useState([]);

  useEffect(() => {
    props.onChageSetter(
      lister.filter((each) => {
        return each.code === selected;
      })
    );
    // console.log('fd change ',selected)
  }, [selected]);
  useEffect(() => {
    let url = BASE_SERVER_URL + "/fd/getAll";
    axios.get(url).then((response) => {
      setLister((prev) => {
        return response.data.response.map((each) => {
          return { code: each.code, value: JSON.stringify(each) };
        });
      });
    });
  }, []);

  return (
    <div className="width-auto">
      <select
        onChange={(event) => {
          setSelected(event.target.value);
        }}
        name={"fd_name"}
        style={{ height: "35px" }}
        className={
          (!props.value || props.value === props.placeHolder
            ? "fade-text"
            : "") +
          " width-100 text-input dropdown font-medium " +
          props.addClass
        }
        value={props.value}
      >
        {props.placeHolder && (
          <option className="fade-text font-medium">{props.placeHolder}</option>
        )}
        {lister.map((val, index) => {
          return (
            <option className="black-text" value={val.code} key={index}>
              {val.code}
            </option>
          );
        })}
      </select>
    </div>
  );
}
export function DropDown(props) {
  const input = React.useRef("");
  const [focus, setFocus] = useState(false || Boolean(props.value));
  const [selected, setSelected] = React.useState("");
  let list = props.options || [];
  let lister;
  if (props.keyValue) {
    lister = Object.keys(list);
  } else {
    lister = [...list];
  }
  // if(props.conditionalInputField && lister.indexOf(props.conditionalInputField.on) === -1){
  if (
    props.conditionalInputField &&
    lister.filter(function (n) {
      return props.conditionalInputField.on.indexOf(n) !== -1;
    }).length === 0
  ) {
    lister = [...lister, ...props.conditionalInputField.on];
  }

  let colorRedVals = ["**UNPAID**"];
  return (
    <div className={props.width || "width-auto relative"}>
      <select
        ref={input}
        onFocus={() => {
          setFocus(true);
        }}
        onChange={(event) => {
          props.onChageSetter(
            event.target.value === props.placeHolder
              ? { name: event.target.name, value: "" }
              : props.name === "product_name"
              ? event.target.textContent
              : {
                  name: event.target.name,
                  value:
                    props.valueType === "Number"
                      ? Number(event.target.value)
                      : event.target.value,
                }
          );
          setSelected(event.target.value);
        }}
        name={props.name || "payment_option"}
        style={{ height: "35px" }}
        className={
          (!props.value || props.value === props.placeHolder
            ? "fade-textaa"
            : "") +
          " width-100 text-input dropdown font-medium " +
          props.addClass +
          (colorRedVals.includes(props.value)
            ? " red-text bold-1"
            : " black-text")
        }
        value={props.value}
        // value={1}
      >
        {props.placeHolder && (
          <option className="fade-text font-medium">{props.placeHolder}</option>
        )}
        {lister.map((val, index) => {
          let theVal;
          if (props.makeIndexValue) {
            theVal = index;
          } else if (props.usePropertyNameAsValue) {
            theVal = val;
          } else {
            theVal = list[val];
          }
          let displayValue = props.mergeKeyValue
            ? toName(val) + " - $" + list[val]
            : val;
          // console.log('Key Val ',theVal)
          return (
            <option
              className={
                colorRedVals.includes(displayValue) ? "red-text" : "black-text"
              }
              value={theVal}
              key={index}
            >
              {(props.conditionalInputField || {}).on !== val
                ? props.displayUnit
                : ""}
              {displayValue}
            </option>
          );
        })}
      </select>
      <label
        className={
          (focus && input.current.value) || props.value
            ? "input focused"
            : "input"
        }
      >
        {props.placeHolder}
      </label>

      {/* {console.log('filer ',props.conditionalInputField , selected,props.conditionalInputField.on)} */}
      {props.conditionalInputField &&
        (props.conditionalInputField.on.includes(selected) ||
          props.conditionalInputField.on.includes(props.value)) && (
          <div className="relative input">
            <input
              className="input-forms text-input stretch"
              onChange={(event) => {
                props.onChageSetter({
                  name: props.conditionalInputField.property,
                  value: event.target.value,
                  conditional: true,
                });
              }}
              placeholder={props.conditionalInputField.placeHolder}
              type="email"
              value={props.fieldValue || ""}
            />
            <label className="input focused">
              {props.conditionalInputField.placeHolder}
            </label>
          </div>
        )}
    </div>
  );
}

export function OrdersSummaryTable(props) {
  let data = props.data || {};
  // console.log('summary props overlay table ',data)
  return (
    <table className="table-list summary">
      <tr className="bold-1 table-head-bg">
        <td>Product Name</td>
        <td>Sash Message</td>
        <td>Sash Price</td>
        <td>Product Value</td>
        <td>Quantity</td>
        <td>Price Total</td>
      </tr>
      {Object.keys(data).map((val, index) => {
        let price = data[val].customPrice
          ? data[val].customPrice
          : data[val].price;
        return (
          <tr key={index}>
            <td>{data[val].product_name}</td>
            <td>{data[val].sash_message || ""}</td>
            <td>
              {"$ "}
              {data[val].sashPrice || 0}
            </td>
            <td>{"$ " + price}</td>
            <td>{"x " + data[val].amount}</td>
            <td>
              {"$ " +
                eval(
                  data[val].amount * Number(price) +
                    Number(data[val].sashPrice || 0)
                )}
            </td>
          </tr>
        );
      })}
    </table>
  );
}

export function shorten_string(a, max = 25) {
  if (!a) {
    return a;
  }
  if (a.length > max) {
    return a.slice(0, max) + " ...";
  } else {
    return a;
  }
}
export function OrdersPreSummaryTable(props) {
  const [cc_types, seCC_types] = useState(
    JSON.parse((props.settings || {}).cc_types || "[]")
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(props.edittingWO ? [] : props.data);
  const [selectedProductPreView, setSelectedProductPreView] = useState({});
  useEffect(() => {
    if (props.edittingWO) {
      setLoading(true);
      let url = BASE_SERVER_URL + "/wo/getProductsBy";
      let body = {
        filter: { work_order_id: props.wo_id },
        fetchType: props.selectType,
        ...(props.selectType === "products"
          ? {}
          : {
              type: props.product_category,
              // cc_types: JSON.parse((props.settings || {}).cc_types || "[]"),
              cc_types: JSON.parse(
                '["Balloons","Bookspray","Casket cover","Large Petals","Singles","Small Petals"]'
              ),
            }),
      };
      axios.post(url, body).then((res) => {
        setLoading(false);
        let synced = res.data.response.map((each) => {
          each.fromDB = true;
          return each;
        });
        props.syncOrderedProducts(synced);
        setData(synced);
      });
    }
  }, []);

  useEffect(() => {
    props.syncOrderedProducts(data);
  }, [data]);

  function removeFromList(index) {
    setData((pre) => {
      let arr = [...pre];
      if (
        props.edittingWO &&
        (data[index] || {}).fromDB &&
        !(arr[index] || {})["newPrdt"]
      ) {
        arr[index]["deleted"] = true;
        arr[index]["editProduct"] = false;
      } else {
        arr.splice(index, 1);
      }
      return arr;
    });
  }

  useEffect(() => {
    // console.log("Product Change edit ", selectedProductPreView);
  }, [selectedProductPreView]);
  // let data = ;
  let x = 0;
  return (
    <>
      {props.selectProduct && (
        <ProductTypeSelectorList
          settings={props.settings}
          preSelected={selectedProductPreView}
          edit={Boolean(Object.keys(selectedProductPreView).length)}
          select={props.selectType}
          cc_types={cc_types}
          // select="tribuites"
          setAlertDetails={(a) => {
            props.setAlertDetails(a);
          }}
          hidder={() => {
            props.setSelectProduct(false);
            setSelectedProductPreView({});
          }}
          addproduct={(prod, replace = false) => {
            setData((prev) => {
              if (prod.replace_fromDB) {
                // console.log("Replace from DB ", prod.replace_fromDB);
                let newArry = prev.map((each) => {
                  if (each._id === prod.replace_fromDB) {
                    each.editProduct = false;
                    each.deleted = true;
                  }
                  return each;
                });
                prod.newPrdt = true;
                return [...newArry, prod];
              }
              if (props.edittingWO && !replace) {
                prod.newPrdt = true;
              }
              if (!replace) {
                return [...prev, prod];
              } else {
                if (prod.fromDB) {
                  prod.editProduct = true;
                }
                let arry = [...prev];
                arry[prod.array_index] = prod;
                // console.log('comparee ', prev, arry, prod)
                return arry;
              }
            });
          }}
        />
      )}
      <table className="table-list pre summary">
        {data.map((vals, index) => {
          let val = { ...vals };
          let price = val.customPrice ? val.customPrice : val.price;
          let sashPrice = val.sashPrice ? val.sashPrice : 0;
          if (val.deleted) {
            return "";
          }
          x++;
          val.array_index = index;
          return loading ? (
            <LoadingDiv height="20px" speed="2" amount={4} />
          ) : (
            <tr key={index}>
              <td>{x}</td>
              <td
                className="touchable golden hover-underline"
                onClick={() => {
                  props.setSelectProduct(true);
                  setSelectedProductPreView(val);
                }}
              >
                {shorten_string(val.product_name) || ""}
              </td>
              <td>
                {"$"}
                {price || 0}
                {" X "}
                {val.amount || 0}
                {val.sashPrice ? " + $" + val.sashPrice : ""}
              </td>
              <td>
                {"$ " +
                  eval(val.amount * Number(price) + Number(sashPrice || 0))}
              </td>
              <td>
                <IconsAI.AiFillDelete
                  className="icon icon-small"
                  onClick={() => {
                    removeFromList(index);
                  }}
                />
              </td>
            </tr>
          );
        })}
      </table>
    </>
  );
}

function CheckWorkOrderComplete(props, date) {
  const [check, setCheck] = useState(false);
  useEffect(() => {
    let url = BASE_SERVER_URL + "/wo/check-complete";
    let data = { id: props._id, type: props.type, date: date };
    axios.post(url, data).then((response) => {
      // console.log('checkerr ',response.data)
      if (response.data.status === "complete") {
        setCheck(true);
      }
    });
  }, []);

  return check ? <IconsMD.MdCheck className="icon mid green" /> : "";
}
// assigned to florist % => printed => work order progress by % => complete for the date =>

function filter_singles(a, b = false) {
  let singles_petals = JSON.parse(a, "[]").filter((each) => {
    return b
      ? each.product_type !== "Singles/Petals"
      : each.product_type === "Singles/Petals";
  });
  // console.log('singlesd',singles_petals)
  return b ? JSON.stringify(singles_petals) : singles_petals;
}

export function filter_products_by_type(a, b, c = false) {
  // console.log("singlesd  ", b);
  let singles_petals = a.filter((each) => {
    return c ? !b.includes(each.product_type) : b.includes(each.product_type);
  });

  return singles_petals;
}

function ChildOrderRow(props) {
  console.log("Child old run states ", props.selectedOldOnces, props.id);
  let data = props.data;
  let selected = props.selectedOnces || {};
  // console.log('loggingg',props)

  return [
    <span hidden={true}></span>,
    <span
      className=" x-small bold-1"
      onClick={() => {
        /*seTtooltip(prev => !prev)*/
      }}
    ></span>,
    <span className="width-50"></span>,
    <span>{data.ordered_by}</span>,
    <span></span>,
    <span className="width-20"></span>,
    <span></span>,
    <span className="width-20"></span>,
    <span></span>,
    <div className="space-between product-card">
      <span className="bold-1 txt">Casket Covers</span>
      <span className={" products"}>
        {MakeTagedProducts(
          shorten_product_list(
            JSON.stringify([
              ...JSON.parse(data.casket_cover_selected || "[]"),
              ...filter_products_by_type(
                [...JSON.parse(data.tributes_selected || "[]")],
                JSON.parse(
                  (props.settings || {}).cc_types || '["Singles/Petals"]'
                )
                // ["Singles/Petals"]
              ),
            ]) || [],
            true,
            true
          ),
          (props.selectedOnces || {}).selected_types,
          (props.selectedOldOnces || {}).selected_types || [],
          (props.selectedOldOnces || {}).type_cues
        )}
      </span>
    </div>,
    <div className="space-between product-card">
      <span className="bold-1 txt">Tributes</span>
      <span className={" products"}>
        {MakeTagedProducts(
          shorten_product_list(
            JSON.stringify(
              filter_products_by_type(
                [
                  // ...JSON.parse(props.data.casket_cover_selected || '[]'),
                  ...JSON.parse(data.tributes_selected || "[]"),
                ],
                JSON.parse(
                  (props.settings || {}).cc_types || '["Singles/Petals"]'
                ),
                // ["Singles/Petals"],
                true
              )
            ) || [],
            true,
            true
          ),
          (props.selectedOnces || {}).selected_types,
          (props.selectedOldOnces || {}).selected_types || [],
          (props.selectedOldOnces || {}).type_cues
        )}
      </span>
    </div>,
  ];
}

function CounterDiv(props) {
  return (
    <div className="counter-div absolute-center">
      {/* <span className='float-absolute-left'>+</span> */}
      <span
        className="font-small bold-1 center-hor"
        style={{ paddingRight: "2px" }}
      >
        +{props.value}
      </span>
    </div>
  );
}

function MakeTagedProducts(products, selected, oldSelects, cue) {
  let themeColor = ThemeColor;
  return (products || []).map((each) => {
    let currentSelected = (selected || []).includes(each.type);
    let oldSelected = (oldSelects || []).indexOf(each.type);
    oldSelected = oldSelected !== -1 ? oldSelected : false;
    let oldCue = null;
    if (oldSelected !== false) {
      oldCue = cue[oldSelected];
    }

    return (
      <span
        className={(currentSelected ? "selected" : "") + " product-tag"}
        style={oldSelected !== false ? { background: themeColor[oldCue] } : {}}
      >
        {each.code}
      </span>
    );
  });
}

function TableRowWorkOrder(props) {
  const [tooltip, seTtooltip] = useState(false);
  const [expand, setExpand] = useState(false);
  const date_format = "DD-MM-YYYY";
  let parent =
    typeof props.data === "object" && props.data.length > 1 ? true : false;
  let row =
    props.orderType === "Funeral" && Array.isArray(props.data)
      ? props.data[0]
      : props.data;
  let allCC = [];
  let allTributes = [];
  let selected = {};
  selected = props.selectedOnces[row._id] ? props.selectedOnces[row._id] : {}; // always for the first of the array
  let selectedOld = {};
  selectedOld =
    props.selectedOldOnces[row._id] && props.makeRunSheet
      ? props.selectedOldOnces[row._id]
      : {}; // always for the first of the array

  if (props.orderType === "Funeral" && Array.isArray(props.data)) {
    props.data.forEach((each) => {
      allCC = [...allCC, ...JSON.parse(each.casket_cover_selected || "[]")];
      allTributes = [
        ...allTributes,
        ...JSON.parse(each.tributes_selected || "[]"),
      ];
    });
  }

  let prods =
    props.orderType === "Funeral" ? (
      <div className="lower font-small center-hor">
        <div style={{ height: "20px", width: "60%", overflow: "hidden" }}>
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: "100%", width: "100%" }}
          >
            <path
              d="M-1.56,131.09 C447.18,131.09 240.31,-2.90 601.56,-5.89 L532.18,186.09 L-2.18,154.09 Z"
              style={{
                stroke: "#f9dfa4",
                strokeWidth: "20px",
                fill: "#fbf8f0",
              }}
            ></path>
          </svg>
        </div>

        <span className="space-between product-card">
          <span className="bold-1 txt">Casket Covers</span>
          {/* <span className={selected['casket cover']? "xlight-lime-bg padding-TL-4-10":""+" products"}>{  */}
          <span className={"products"}>
            {MakeTagedProducts(
              shorten_product_list(
                JSON.stringify([
                  ...allCC,
                  ...filter_products_by_type(
                    [...allTributes],
                    JSON.parse(
                      (props.settings || {}).cc_types || '["Singles/Petals"]'
                    )
                    // ["Singles/Petals"]
                  ),
                ]) || [],
                true,
                true
              ),
              selected.selected_types || [],
              selectedOld.selected_types || ["no"],
              selectedOld.type_cues
            )}
          </span>
        </span>
        <span className="space-between product-card">
          <span className="bold-1 txt">Tributes</span>
          {/* <span className={selected['tributes']? "xlight-lime-bg padding-TL-4-10":""+" products"}> */}
          <span className={"products"}>
            {MakeTagedProducts(
              shorten_product_list(
                JSON.stringify(
                  filter_products_by_type(
                    [...allTributes],
                    JSON.parse(
                      (props.settings || {}).cc_types || '["Singles/Petals"]'
                    ),
                    // ["Singles/Petals"],
                    true
                  )
                ) || [],
                true,
                true
              ),
              selected.selected_types || [],
              selectedOld.selected_types || ["no"],
              selectedOld.type_cues
            )}
          </span>
        </span>
      </div>
    ) : (
      <div className="lower font-small center-hor">
        <div style={{ height: "20px", width: "80%", overflow: "hidden" }}>
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: "100%", width: "100%" }}
          >
            <path
              d="M-1.56,131.09 C447.18,131.09 240.31,-2.90 601.56,-5.89 L532.18,186.09 L-2.18,154.09 Z"
              style={{
                stroke: "#f9dfa4",
                strokeWidth: "20px",
                fill: "#fbf8f0",
              }}
            ></path>
          </svg>
        </div>
        <span className="space-between product-card">
          <span className="bold-1 txt">Selected Flower Arrangement</span>
          {/* <span className={selected['tributes']? "xlight-lime-bg padding-TL-4-10":""+" products"}> */}
          <span className={"products"}>
            {MakeTagedProducts(
              shorten_product_list(props.data.products_selected, false, true),
              selected.selected_types || [],
              selectedOld.selected_types || [],
              selectedOld.type_cues
            )}
          </span>
        </span>
      </div>
    );

  let table_row = {
    Funeral: [
      <span hidden={true}>{row._id}</span>,
      <span
        className="x-small bold-1"
        onClick={() => {
          /*seTtooltip(prev => !prev)*/
        }}
      >
        {props.num}
      </span>,
      <span className="relative width-15">{row.late_person_name}</span>,
      <span className="small width-10">
        {JSON.parse(row.fd_code || '{"code":"N/A"}').code || ""}
      </span>,
      // <span className='small'>CODE</span>,
      <span className="relative">
        {expand ? "" : row.ordered_by}{" "}
        {parent && !expand ? <CounterDiv value={props.data.length - 1} /> : ""}
      </span>,
      <span>{row.casket_cover_delivery_time}</span>,
      // <span>{shorten_string(JSON.parse(row.funeral_location || '{}').name || 'N/A')}</span>,
      <span className="width-20">
        {JSON.parse(row.funeral_location || "{}").name || "N/A"}
      </span>,
      <span>
        {ToReadableDate(row.funeral_date, [], "/", "DD/MM/YYYY", "DD/MM/YYYY")}{" "}
        {row.funeral_time}
      </span>,
      <span className="width-20">
        {JSON.parse(row.casket_cover_delivery_location || "{}").name || "N/A"}
      </span>,
      <span>{CheckWorkOrderComplete(row, null)}</span>,
    ],
    Retail: [
      <span hidden={true}>{props.data._id}</span>,
      <span
        className="x-small"
        onClick={() => {
          seTtooltip((prev) => !prev);
        }}
      >
        {props.num}
      </span>,
      <span className="width-20">{props.data.company_name}</span>,
      <span>{props.data.ordered_by}</span>,
      <span>{props.data.frequency}</span>,
      <span>{props.data.recipient_name}</span>,
      <span>{props.data.recipient_phone}</span>,
      // <span>{shorten_string(JSON.parse(props.data.delivery_location || "{}").name || 'N/A')}</span>,
      <span>{props.data.delivery_area || "N/A"}</span>,
      // <span>{moment(props.data.delivery_date,'DD/MM/YYYY').format(date_format)} {props.data.time_of_delivery}</span>,
      <span>{props.data.time_of_delivery}</span>,
      <span className="width-40">
        {JSON.parse(props.data.delivery_location || "{}").name || "N/A"}
      </span>,
      <span>
        {CheckWorkOrderComplete(props.data, props.data.delivery_date)}
      </span>,
    ],
  };

  return (
    <div
      className={(props.selected ? "active" : "") + " width-100 content-row"}
      onClick={() => {
        if (parent) {
          setExpand((prev) => !prev);
        } else {
          // if(!tooltip){
          props.toggleShow(
            props.orderType === "Funeral" ? row : props.data,
            props.extra
          );
        }
      }}
    >
      <div
        style={{ marginLeft: "0px", fontSize: "12px" }}
        key={props.key}
        className={
          " table-row-content center-hor space-between font-small bold-1 table-row relative"
        }
      >
        {table_row[props.orderType]}
      </div>
      {!(props.makeRunSheet && parent) && !expand && prods}
      {(props.makeRunSheet || expand) && parent
        ? (props.data || []).map((each, index) => {
            // console.log("Parent chileds ", each);
            let selectedOld = {};
            selectedOld =
              props.selectedOldOnces[each._id] && props.makeRunSheet
                ? props.selectedOldOnces[each._id]
                : {};
            return (
              <div
                onClick={() => {
                  props.toggleShow(each);
                }}
                style={{ fontSize: "12px" }}
                className="width-100 table-row table-row-content font-small bold-1 parent-child-detail center-hor"
              >
                <ChildOrderRow
                  id={each._id}
                  selectedOnces={props.selectedOnces[each._id]}
                  selectedOldOnces={props.makeRunSheet ? selectedOld : {}}
                  data={each}
                  settings={props.settings}
                />
              </div>
            );
          })
        : ""}
    </div>
  );
}
export function shorten_product_list(a, b = true, c = false) {
  if (!a || a.length === 0) {
    return "";
  }

  a = typeof a === "string" ? JSON.parse(a || "[]") : a;
  let ob = {};
  let cc = false;
  let ar1 = a.map((val, index) => {
    if (
      (val.product_type || "").toLowerCase() === "casket cover" ||
      (val.product_type || "").toLowerCase() === "singles/petals"
    ) {
      // cc=true
      let type =
        val.product_type.toLowerCase() === "casket cover"
          ? val.code
          : val.product_type;
      ob[type] = ob[type] ? ob[type] + Number(val.amount) : Number(val.amount);
      ob["types"] = ob["types"] ? ob["types"] : {};
      ob["types"][type + "_type"] = val.product_type;
      let ret =
        (index > 0 && false ? " " : " ") +
        "" +
        val.amount +
        " x " +
        ((val.product_type || "").toLowerCase() === "singles/petals"
          ? val.product_type
          : val.code);
      let value = c ? { code: ret, _id: val._id } : ret;
      // console.log('shortening 1 ',value)
      return value;
    } else {
      let type =
        (val.product_type || "").toLowerCase() === "casket cover"
          ? val.code
          : val.product_type;
      ob[val.product_type] = ob[val.product_type]
        ? ob[val.product_type] + Number(val.amount)
        : Number(val.amount);
      ob["types"] = ob["types"] ? ob["types"] : {};
      ob["types"][type + "_type"] = val.product_type;
    }
  });

  if (cc && b) {
    let ret = c
      ? ar1.map((each) => {
          return { code: each };
        })
      : ar1;
    //  console.log('shortening 2 ',ret)
    return ret;
  }

  let lastRet = Object.keys(ob).map((val, index) => {
    if (val !== "types") {
      let value = (index > 0 ? " " : "") + "" + val + " x " + ob[val];
      return c ? { code: value, type: ob["types"][val + "_type"] } : value;
    }
  });
  return lastRet.filter((each) => {
    return each !== undefined;
  });
}
export function TableListInventory(props) {
  let date = "";
  let runs =
    Object.keys(props.data).map((each) => {
      return { amount: props.data[each], flower_name: each };
    }) || [];
  // console.log('passed runs ',runs)
  const head = (
    <div className="inventory-run-row head center-hor width-100 font-medium">
      <span>#</span>
      <span>Flower Name</span>
      <span>Quantity</span>
    </div>
  );
  return (
    <div className="work-order-list width-100">
      {head}
      {props.loading ? (
        <LoadingDiv amount={10} />
      ) : !runs.length > 0 ? (
        <span className="width-100 margin-10 absolute-center font-small">
          Ordered Products not found for the day
        </span>
      ) : (
        runs.map((each, index) => {
          return (
            <InventoryListRow
              listStart={props.listStart}
              key={index}
              num={index + 1}
              data={each}
            />
          );
        })
      )}
    </div>
  );
}

function InventoryListRow(props) {
  return (
    <div className="inventory-run-row center-hor width-100 xlight-box-shadow font-medium">
      <span>{props.num + props.listStart}</span>
      <span>{props.data.flower_name}</span>
      <span>{props.data.amount}</span>
    </div>
  );
}
export function TableList(props) {
  let date = "";
  const head = {
    Funeral: (
      <div
        style={{ fontSize: "12px" }}
        className="table-head light-box-shadow table-row "
      >
        <span className="x-small">#</span>
        <span className="width-15">Late Person`s Name</span>
        <span className="width-10">FD Code</span>
        <span>Ordered By</span>
        <span>CC Delivery Time</span>
        <span className="width-20">Funeral Location</span>
        <span>Funeral Time</span>
        <span className="width-20">CC Delivery</span>
        <span>Tribute</span>
        {/* <span>Selected Casket Cover</span>
                                <span>Selected Tributes</span> */}
      </div>
    ),
    Retail: (
      <div
        style={{ fontSize: "12px" }}
        className="table-head light-box-shadow table-row"
      >
        <span className="x-small">#</span>
        <span className="width-20">Company Name</span>
        <span>Order From</span>
        <span>Frequency</span>
        <span>Recipient Name</span>
        <span>Recipient`s Phone</span>
        <span>Delivery Area</span>
        <span>Delivery Time</span>
        <span className="width-40">Delivery Location</span>
        <span>Complete</span>
        {/* <span style={{width:'15%'}}>Selected Flower Arrangement</span> */}
      </div>
    ),
  };
  const turnType = { Retail: "Funeral", Funeral: "Retail" };

  let datas = !Array.isArray(props.data)
    ? Object.keys(props.data)
    : props.data || [];
  // console.log('table lsit ', props.datasRetail)
  return (
    <div
      className={
        (props.makeRunSheet ? "xlight-gold-bg " : "") +
        " work-order-list width-100"
      }
    >
      {props.makeRunSheet && (
        <div className="order-titles">{props.orderType} Orders</div>
      )}
      {head[props.orderType]}
      {props.loading ? (
        <LoadingDiv amount={13} noDelay={true} speed={2} height={"50px"} />
      ) : props.loadingFailed ? (
        <ReloadOption reload={props.reload} />
      ) : props.data.length === 0 ? (
        // <TableRowSeparator message='No Order Found' />:
        <EmptyList
          message={"No Work Order Found on " + (props.listDate || "")}
        />
      ) : (
        datas.map((ea, num) => {
          // let each = props.orderType === 'Funeral' ? props.data[ea] : ea
          let each = !Array.isArray(props.data) ? props.data[ea] : ea;
          // let d = moment(each.date.split('T')[0], "YYYY-MM-DD").fromNow()
          // console.log("each tyoe of ittreatuon ", props.orderType, [each]);
          const timeDir = { funeral_date: true, date: false };
          let d = CalcTimeDifference(
            each[props.sortBy || "date"],
            null,
            timeDir[props.sortBy || "date"],
            "DD/MM/YYYY",
            true
          );
          let preIds = {};
          let preIdsOld = {};
          // if (props.orderType === "Funeral" || true) {
          (props.orderType === "Retail" ? [each] : each || []).forEach((a) => {
            if (props.selectedForRunSheet[a._id]) {
              preIds[a._id] = props.selectedForRunSheet[a._id];
            }
          });
          (props.orderType === "Retail" ? [each] : each || []).forEach((a) => {
            if (props.oldSelectedTypes[a._id]) {
              preIdsOld[a._id] = props.oldSelectedTypes[a._id];
            }
          });
          // }
          // console.log("each pres comps ", preIds, preIdsOld);
          let rowSelected =
            Boolean(props.selectedWorkOrder) &&
            (props.selectedWorkOrder || {})._id ===
              (props.orderType === "Retail" ? each : each[0] || {})._id;

          return (
            <TableRowWorkOrder
              //   selectedOnces={props.selectedForRunSheet[each._id] || []}
              selected={rowSelected}
              settings={props.settings}
              makeRunSheet={props.makeRunSheet}
              extra={false}
              selectedOnces={preIds || {}}
              selectedOldOnces={preIdsOld || {}}
              key={num}
              num={eval(props.listStart + num + 1)}
              data={each || {}}
              orderType={props.orderType}
              toggleShow={(show) => {
                props.toggleShow(show);
              }}
            />
          );
        })
      )}
      {props.makeRunSheet && [
        <div className="order-titles margin-top-20">
          {turnType[props.orderType]} Orders
        </div>,
        head[turnType[props.orderType]],
      ]}
      {/* {console.log('addtional order list ',props.dataRetails)} */}
      {props.makeRunSheet ? (
        props.runLoading ? (
          <LoadingDiv amount={10} noDelay={true} speed={2} />
        ) : (props.dataRetails || []).length === 0 ? (
          // <TableRowSeparator message='No Order Found' />:
          <EmptyList message="No Order Found" />
        ) : (
          (props.dataRetails || []).map((ea, num) => {
            // let each = props.orderType === 'Funeral' ? props.data[ea] : ea
            let each = !Array.isArray(props.dataRetails)
              ? props.dataRetails[ea]
              : ea;
            // let d = moment(each.date.split('T')[0], "YYYY-MM-DD").fromNow()

            const timeDir = { funeral_date: true, date: false };
            let d = CalcTimeDifference(
              each[props.sortBy || "date"],
              null,
              timeDir[props.sortBy || "date"],
              "DD/MM/YYYY",
              true
            );
            // let d = 'asd'
            let preIds = {};
            let preIdsOld = {};
            preIds[each._id] = props.selectedForRunSheet[each._id];
            preIdsOld[each._id] = props.oldSelectedTypes[each._id];
            return (
              <TableRowWorkOrder
                selected={false}
                extra={true}
                settings={props.settings}
                selectedOnces={preIds || []}
                selectedOldOnces={preIdsOld || []}
                makeRunSheet={props.makeRunSheet}
                key={num}
                num={eval(props.listStart + num + 1)}
                data={each.type === "Funeral" ? [each] : each}
                orderType={each.type}
                toggleShow={(show) => {
                  props.toggleShow(show);
                }}
              />
            );
          })
        )
      ) : (
        ""
      )}
    </div>
  );
}

export function EmptyList(props) {
  return (
    <div
      className="width-100 center-ver-col icon"
      style={{ justifyContent: "center", minHeight: "300px" }}
    >
      <IconsAI.AiOutlineDropbox className="icon XL" />
      {props.message || "Data Not Found"}
    </div>
  );
}

export function CalcTimeDifference(
  a,
  b = null,
  futureTimeDiff = false,
  format = "DD/MM/YYYY",
  plain = false
) {
  if (!a) {
    return "";
  }
  if (plain) {
    return (
      moment(a, format).format("dddd") + " " + moment(a, format).format("LL")
    );
  }
  let now = new Date().toLocaleDateString().split("/");
  let from = a.split("/");
  let days = now[1] - from[1];
  let months = now[0] - from[0];
  let years = now[2] - from[2];
  // console.log('diffs ',years, months,days, futureTimeDiff)

  if (futureTimeDiff && months === 0 && years === 0) {
    if (days === 1) {
      return "Yesterday";
    } else if (days === -1) {
      return "Tomorrow";
    } else if (days === 0) {
      return "Today";
    }
  } else {
    // return moment(a,format).format('LL')
    return (
      moment(a, format).format("dddd") + " " + moment(a, format).format("LL")
    );
  }
  // console.log('time dff ',from,' - ',now)
  if (years > 0) {
    if (months < 0) {
      months = Math.abs(months);
      let s = months > 1 ? "s" : "";
      return months + " Month" + s + " Ago";
    }
    let s = years > 1 ? "s" : "";
    return years + " Year" + s + " Ago";
  } else if (months > 0) {
    if (days < 0) {
      days = Math.abs(days);
      let s = days > 1 ? "s" : "";
      return days === 0 ? "Today" : days + " Day" + s + " Ago";
    }
    let s = months > 1 ? "s" : "";
    return months + " Month" + s + " Ago";
  } else if (days >= 0) {
    let s = days > 1 ? "s" : "";
    return days === 0 ? "Today" : days + " Day" + s + " Ago";
  }
  return (
    moment(a, format).format("dddd") + " " + moment(a, format).format("LL")
  );
  // return  moment(a,format).format('LL')
}

export function TableRowSeparator(props) {
  return (
    <div>
      <div
        className="width-100 absolute-center font-small row-separator link-text"
        onClick={() => {
          return false;
          props.onClick();
        }}
      >
        -------------------------------------------- {props.message}{" "}
        ------------------------------------------
      </div>
    </div>
  );
}
function TableRow(props) {
  return (
    <div
      className="table-row-content center-hor space-around table-row"
      onClick={() => {
        props.onSelect(props.data);
      }}
    >
      <span>1</span>
      {props.data.map((each) => {
        return <span>{each}</span>;
      })}
    </div>
  );
}

export function DatePicker(props) {
  const [value, setValue] = React.useState(null);
  return (
    <div
      className={"date-picker center-hor relative margin-0 " + props.addClass}
    >
      <div className={"width-100 relative"}>
        {props.overLayDisplay && (
          <span className="page-date-display font-medium center-hor">
            <div className="side"></div>
            {moment(props.value, "DD/MM/YYYY").format("dddd") +
              " " +
              moment(props.value, "DD/MM/YYYY").format("LL")}
            {/* <span className='float-absolute-right'>
                                <BsCalendarFill className='icon'/> 
                    </span>  */}
          </span>
        )}
        {props.label && !props.overLayDisplay && (
          <label className="input focused width-50">{props.label}</label>
        )}
        <DateTimePicker
          onChange={(date) => {
            props.onChageSetter({
              name: props.name,
              value: ToReadableDate(date, [], "/"),
            });
            setValue(date);
          }}
          value={
            props.value
              ? // new Date(props.value)
                moment(props.value, "DD/MM/YYYY").format()
              : value
          }
          calendarIcon={<IconsBS.BsCalendarFill className="icon mid" />}
          className={
            (props.disabled && "bold-1") +
            " input-forms text-input border-0 stretch " +
            props.addDirectClass
          }
          calendarClassName="calender xlight-box-shadow"
          disableClock={true}
          disabled={props.disabled}
          format={"dd-MM-y"}
          clearIcon={null}
        />
      </div>
    </div>
  );
}

function flipDateMonth(a) {
  console.log("Flipping ", a);
  let date = (a || "").split("/");
  if (!date.length) {
    return a;
  }
  return date[1] + "/" + date[0] + "/" + date[2];
}

export function DatePickerSmall(props) {
  const [value, setValue] = React.useState();
  useEffect(() => {
    if (props.value) {
      setValue(flipDateMonth(props.value));
    } else {
      setValue(props.value);
    }
  }, [props.value]);
  return (
    <div className=" small-btn date-picker center-hor">
      {props.value && !props.noClearButton && (
        <ButtonWithIcon
          addClass={
            "light-red-bg rad-left width-auto margin-10 stretch-height font-small thin"
          }
          value={"Clear Date"}
          iconWidth="40px"
          labelClass={"color-white"}
          borderRad="6px"
          clickTo={() => {
            props.clearDate();
          }}
          iconClass={"red-bg"}
          icon={<IconsMD.MdCancel className={" icon XL white width-100"} />}
        />
      )}
      <DateTimePicker
        value={props.value ? new Date(flipDateMonth(props.value)) : value}
        onChange={(date) => {
          props.onChageSetter({ name: props.name, value: date });
          setValue(date);
        }}
        calendarIcon={<IconsBS.BsCalendarFill className="icon mid" />}
        className={"xsmall-btn text-input date-picker border-0 stretch"}
        calendarClassName="calender light-box-shadow"
        disableClock={true}
        format={"dd/MM/y"}
        //   format={'EEEE MM,y'}
        clearIcon={null}
      />
    </div>
  );
}

export const DateTimeRangePicker = (props) => {
  const [active, setActive] = useState(false);

  const handleDateChange = (date, name) => {
    props.rangeSetter(date, name);
  };

  // useEffect(() => {
  //   props.rangeSetter({ startDate: startDate, endDate: endDate });
  // }, [startDate, endDate]);

  return (
    <div className="datetime-range-picker xlight-box-shadow relative ">
      <span
        className="absolute-center pick-btn"
        onClick={() => {
          setActive((prev) => !prev);
        }}
      >
        <IconsMD.MdDateRange className="icon mid" />
      </span>
      <div className={"calenders center-hor " + (active ? "active" : "")}>
        {/* <label>Start Date: </label> */}
        <div className="cale-btns">
          <DatePickerSmall
            // maxDate={new Date((props.dateRange || {}).endDate)}
            value={(props.dateRange || {}).startDate}
            noClearButton={true}
            name="start_date"
            onChageSetter={(a) => {
              handleDateChange(ToReadableDate(a.value, [], "/"), "startDate");
            }}
          />
        </div>
        <div className="cale-btns absolute-center">to</div>
        <div className="cale-btns">
          {/* <label>End Date: </label> */}
          <DatePickerSmall
            // minDate={(props.dateRange || {}).startDate}
            value={(props.dateRange || {}).endDate}
            noClearButton={true}
            name="end_date"
            onChageSetter={(a) => {
              handleDateChange(ToReadableDate(a.value, [], "/"), "endDate");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export function DateTime() {
  const [day, setDay] = useState("");
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [amp, setAmp] = useState("");
  useEffect(() => {
    let date = new Date();
    setDay(date.toDateString());
    setHour(date.getHours());
    setMinute(date.getMinutes());
    setAmp(date.toLocaleTimeString().split(" ")[1]);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      let date = new Date();
      setDay(date.toDateString());
      setHour(date.getHours());
      setMinute(date.getMinutes());
      setAmp(date.toLocaleTimeString().split(" ")[1]);
    }, 60000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="time-date bold-1">
      <div className="time absolute-center font-small">
        {toTwoDigit(hour)} : {toTwoDigit(minute)} {amp}
      </div>
      <div className="date absolute-center font-small">{day}</div>
    </div>
  );
}

function toTwoDigit(a) {
  return a <= 9 ? "0" + a : a;
}

export function SelectableListElement2(props) {
  return (
    <div
      className={
        props.selected
          ? "selectable-card  selected center-hor relative"
          : "relative selectable-card center-hor"
      }
      onClick={() => {
        props.toggleSelect();
      }}
    >
      <span>
        <IconsBS.BsFillCheckCircleFill className="icon" />
      </span>
      {/* <img className='product-img' src={flowerImage} /> */}
      {props.prod.image ? (
        <div
          className="product-img width-20"
          style={{
            backgroundImage:
              "url(" + BASE_SERVER_URL + "/" + props.prod.image + ")",
          }}
        ></div>
      ) : props.prod.product_type === "Casket cover" ? (
        <div className="width-20">
          <GiGraveFlowers className="icon product-img XL grey" />
        </div>
      ) : (
        <div className="width-20">
          <IconsBS.BsFlower3 className="icon product-img XL grey" />
        </div>
      )}
      <div className="text width-75">{props.prod.product_name}</div>
      <div className="absolute-center font-small code">
        {props.prod.code || "Code"}
      </div>
      <div className="absolute-center font-small type">
        {props.prod.product_type || "Type Name"}
      </div>
    </div>
  );
}

export function SelectableListElement(props) {
  // let content = Object.keys(JSON.parse(props.content || "")) || []
  // console.log('prod detail ',props.prod)
  return (
    <div
      className={
        props.selected
          ? "selectable-card  selected center-hor relative"
          : "relative selectable-card center-hor"
      }
      onClick={() => {
        props.toggleSelect();
      }}
    >
      {props.prod.image ? (
        <div
          className="product-img list width-25"
          style={{
            backgroundImage:
              "url(" + BASE_SPACE_URL + "" + props.prod.image + ")",
          }}
        ></div>
      ) : props.prod.product_type === "Casket cover" ? (
        <div className="width-20">
          <GiGraveFlowers className="icon product-img XL grey" />
        </div>
      ) : (
        <div className="width-20">
          <IconsBS.BsFlower3 className="icon product-img XL grey" />
        </div>
      )}
      <div className="text width-75">{props.prod.product_name}</div>
      <div className="absolute-center font-small code">
        {props.prod.code || "Code"}
      </div>
      {/* <div className='absolute-center font-small type'>{props.prod.product_type || 'Type Name'}</div> */}
      <div
        className="center-hor flowers float-bottom-right"
        style={{ justifyContent: "end !important" }}
      >
        <Tag message={props.prod.product_type || "N/A"} />
      </div>
      {/* <div className='absolute-center font-small type'>{props.prod.type || 'Type Name'}</div> */}
    </div>
  );
}

export function Image(props) {
  const [loading, setLoading] = useState(true);

  return (
    // [<p className="red-bg" style={{display: !loading? 'none':'block'}}>Loading</p>,
    [
      <LoadingDiv
        speed={2}
        amount={0}
        height={"95px"}
        CoverDivClass={"width-20 margin-0"}
        style={{ display: !loading ? "none" : "block" }}
      />,
      <img
        onLoad={() => {
          setLoading(false);
        }}
        className={props.className}
        style={{ display: loading ? "none" : "block" }}
        src={props.src}
      />,
    ]
  );
}

export function SimpleSelectableListElement(props) {
  return (
    <div
      className={
        props.selected
          ? "selectable-card selected simple center-hor space-between"
          : "selectable-card simple center-hor space-between"
      }
      onClick={props.toggleSelect}
    >
      <span>
        <IconsBS.BsFillCheckCircleFill className="icon" />
      </span>
      <div className="text">{props.details.latePersonsName}</div>
      <div className="text">{props.details.location}</div>
      <div className="text">{props.details.date}</div>
    </div>
  );
}

export function MapMarker() {
  return (
    <div className="map-marker">
      <p className="items absolute-center">1 CC, 2 Wreaths, 1 Heart, 1 Bowl</p>
      <p className="time absolute-center">By 03:50 AM</p>
      <div className="edge">
        <div></div>
      </div>
    </div>
  );
}

export function ButtonBigWithIcon(props) {
  return (
    <button
      className="button large inline-hor center-hor xlight-box-shadow"
      onClick={props.clickTo}
    >
      <span className="icon absolute-center width-10">{props.icon}</span>
      <p className="absolute-center width-90 margin-0 font-medium">
        {props.value}
      </p>
    </button>
  );
}
export function ButtonWithIcon(props) {
  return (
    <div
      onClick={props.clickTo}
      style={{ borderRadius: props.borderRad }}
      className={"button form width-100 inline-hor " + props.addClass}
    >
      {!props.iconSideRight && !props.iconless ? (
        <span
          style={{
            width: props.iconWidth,
            borderRadius: props.borderRad + " 0 0 " + props.borderRad,
          }}
          className={"icon absolute-center " + props.iconClass}
        >
          {props.icon}
        </span>
      ) : (
        ""
      )}
      <p className={"absolute-center width-100 " + props.labelClass}>
        {props.value}
      </p>
      {props.iconSideRight && !props.iconless ? (
        <span className={"icon absolute-center " + props.iconClass}>
          {props.icon}
        </span>
      ) : (
        ""
      )}
    </div>
  );
}

export function LabeledCheckBox(props) {
  return (
    <div className={"relative labeled-checkbox center-hor " + props.addClass}>
      {props.leftLabel ? (
        <p className={"margin-0 float-lef " + (props.font || "font-small")}>
          {props.label}
        </p>
      ) : (
        ""
      )}
      <RoundCheckBox
        addClass={props.leftLabel ? "float-rigt" : "float-righ"}
        labelMark={props.checkBoxIcon}
        condition={props.checked}
        checkChange={() => {
          props.setter();
        }}
      />
      {!props.leftLabel ? (
        <p className={"margin-0 float-righ " + (props.font || "font-small")}>
          {props.label}
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

export function RadioList(props) {
  const rand = Math.random();
  const [selected, setSelected] = React.useState(props.default || 0);
  function changeing(a) {
    //console.log('Changeing radio ',a)
    // setter={(a)=>{set_cc_delivery_loc_choice(a)}}
  }
  return (
    <div>
      {props.list.map((each, index) => {
        return (
          <div className={"form-check " + props.addClass} key={index}>
            <input
              className="form-check-input"
              type="radio"
              onChange={(event) => {
                changeing(event);
              }}
              name={props.name}
              id={rand + "" + index}
              value="option1"
              // checked={props.default===index?true:false}
            />
            <label
              className="form-check-label font-small"
              for={rand + "" + index}
            >
              {each}
            </label>
          </div>
        );
      })}
    </div>
  );
}

export function ProductTypeSelectorList(props) {
  //   const [products, setProducts] = React.useState(data[props.select])
  const [products, setProducts] = React.useState([]);

  const [selectedProductId, setSelectedProductId] = React.useState(0);
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const [size, setSize] = React.useState("");
  const [amount, setAmount] = React.useState(1);
  const [productPrice, setProductPrice] = React.useState(0);
  const [lidType, setLidType] = useState("");

  //filters
  const [productType, setProductType] = React.useState("");
  const [query, setQuery] = React.useState("");
  const [allPage, setAllPage] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [pageLimit, setPageLimit] = useState(12);

  const [isLoading, setIsLoading] = React.useState(false);
  const [activeType, setActiveType] = React.useState(false);

  let arrayIndex = props.edit ? props.preSelected.array_index : null;

  function populate(b) {
    let a = { ...b };
    // console.log('size changed to populating ', a.size)
    setSelectedProduct(a);
    setSize(a.size);
    setAmount(a.amount);
    setProductPrice(a.price);
    setLidType(a.lidType || "");
    setPageLimit(11);
  }

  React.useEffect(() => {
    if (props.edit) {
      populate(props.preSelected);
      // setSelectedProductId(10)
    }
  }, []);

  React.useEffect(() => {
    refreshList();
  }, [productType, page, pageLimit]);
  //   .$text = {$search:data.byQuery}
  function refreshList() {
    setIsLoading(true);
    setSelectedProductId(0);
    let url = BASE_SERVER_URL + "/products/getAllBy";
    let filter = {
      finder: {
        ...(productType ? { product_type: productType } : {}),
        ...(query ? { $text: { $search: query } } : {}),
      },
      page: page,
      pager: {
        limit: pageLimit,
      },
    };
    axios.post(url, filter).then((response) => {
      // console.log('products from db ',response.data.response)
      setProducts(response.data.response);
      setAllPage(Math.ceil(response.data.responseLength / pageLimit));
      setIsLoading(false);
    });
  }

  function prepare(a) {
    let obj = { ...a };
    delete obj.price;
    //   obj.price = Number(JSON.parse(a.price)[size])
    obj.price = JSON.parse(
      products[selectedProductId === 0 ? 0 : selectedProductId - 1].sizes
    )[size];
    obj.size = size;
    obj.amount = 1;
    return obj;
  }

  React.useEffect(() => {
    setSelectedProduct(() => {
      return selectedProductId !== 0
        ? prepare(products[selectedProductId - 1])
        : props.edit
        ? props.preSelected
        : { sash_message: "", amount: 1 };
    });

    if (selectedProductId !== 0 || (selectedProductId === 0 && props.edit)) {
      const theSize = JSON.parse(
        selectedProductId === 0 && props.edit
          ? props.preSelected.sizes
          : products[selectedProductId - 1].sizes || "{}"
      );
      const allSizes = Object.keys(theSize);
      if (size === "") {
        // setSize(allSizes[0])
      }
      setProductPrice(theSize[allSizes[0]]);
    }
  }, [selectedProductId]);

  React.useEffect(() => {
    setSelectedProduct((prev) => {
      let newObj = { ...prev };
      newObj.lidType = lidType;
      return newObj;
    });
  }, [lidType]);

  React.useEffect(() => {
    setSelectedProduct((prev) => {
      let newObj = { ...prev };
      newObj.price = productPrice;
      return newObj;
    });
  }, [productPrice]);

  React.useEffect(() => {
    setProductPrice(
      selectedProductId !== 0
        ? products.length
          ? JSON.parse(
              products[selectedProductId === 0 ? 0 : selectedProductId - 1]
                .sizes
            )[size]
          : 0
        : props.edit
        ? JSON.parse(props.preSelected.sizes || "{}")[size]
        : 0
    );
    setSelectedProduct((prev) => {
      let newObj = { ...prev };
      newObj.size = size;
      return newObj;
    });
  }, [size]);

  function toggleSelection(id) {
    setSelectedProductId((prev) => {
      if (prev === id) {
        return 0;
      } else {
        return id;
      }
    });
  }
  function handleCustomChange(a, b) {
    if (typeof a === "object" && b === "amount") {
      a = a.value > 0 ? a.value : 1;
    }
    setSelectedProduct((prev) => {
      let newObj = { ...prev };
      newObj[b] =
        b === "amount" || b === "price" || b === "customPrice"
          ? Number(a)
          : b === "sash_message"
          ? a.message
          : a;
      if (b === "sash_message") {
        if (a !== "") {
          newObj["sashPrice"] = a.sashPrice;
        } else {
          newObj["sashPrice"] = 0;
        }
      }
      return newObj;
    });
  }

  function validateData(selected) {
    let price = selected.customPrice || selected.price;
    let vals = {
      product: Boolean(selected),
      ["Price or Size"]: Boolean(price),
      amount: Boolean(selected.amount),
    };
    if (selected && price && selected.amount) {
      return { status: true };
    } else {
      return { ...vals, status: false };
    }
  }

  function submitSelectedProduct() {
    let valid = validateData(selectedProduct);
    let missing = [];
    for (const [key, value] of Object.entries(valid)) {
      if (!value && key !== "status") {
        missing.push(toName(key));
      }
      // console.log(`checking valid entire ${key}: ${value} ${missing}`);
    }

    if (valid.status) {
      props.addproduct(
        {
          ...selectedProduct,
          array_index: arrayIndex,
          ...(props.preSelected.fromDB &&
          props.preSelected._id !== selectedProduct._id
            ? { replace_fromDB: props.preSelected._id || false }
            : {}),
        },
        props.edit
      );
      props.hidder();
    } else {
      props.setAlertDetails({
        type: "error",
        title: "Incomplete data submission",
        message: "Please add " + missing.toString(),
      });
    }
  }

  return (
    // <div className="popup fullscreen absolute-center">
    <div className="select-options">
      <div className="title">
        <div className="float-right-top width-auto">
          <IconsMD.MdCancel
            onClick={props.hidder}
            className="icon white large"
          />
        </div>
        <h4 className="stretch absolute-center">Select Product</h4>
      </div>
      <div className="width-100 padding-0">
        <div className="padding-10">
          <div className="row margin-TB-30">
            <div className="col-lg-9 col-md-6 col-sm-12 relative">
              {/* <ButtonFlat */}
              <ButtonWithIcon
                addClass="width-30 margin-0 stretch-height font-small thin"
                value={productType ? productType : "Filter by Type"}
                iconWidth="30%"
                borderRad="6px"
                // icon={<IconsFA.FaFilter className="icon XL white width-50" />}
                iconless={true}
                clickTo={() => {
                  setActiveType((prev) => !prev);
                }}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <SearchField
                searchTrigger={() => {
                  refreshList();
                }}
                setQuery={(a) => {
                  setQuery(a);
                }}
                addClass="stretch"
              />
            </div>
          </div>

          <div
            className={"product-type-select-card width-100 active"}
            hidden={!activeType}
          >
            <ProductTypeSelectionNav
              selected={productType}
              onChange={(a) => {
                setActiveType(false);
                setProductType(a);
              }}
              list={[
                "Casket cover",
                "Heart",
                "Bowl",
                "Wreath",
                "Sprays",
                "Croatian Tributes",
                "Cross",
                "Letters",
                "Singles/Petals",
              ]}
            />
          </div>
          <div className="width-100 product-content selector relative">
            <div className="row">
              {Object.keys(props.preSelected || {}).length > 0 ? (
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <SelectableListElement
                    prod={props.preSelected}
                    selected={selectedProductId === 0}
                    toggleSelect={() => {
                      toggleSelection(0);
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              {isLoading ? (
                <LoadingDiv
                  amount={12}
                  speed={2}
                  CoverDivClass={"col-lg-3 col-md-6 col-sm-12"}
                  height={"95px"}
                />
              ) : (
                Object.keys(products).map((val) => {
                  return (
                    <div key={val} className="col-lg-3 col-md-6 col-sm-12">
                      <SelectableListElement
                        prod={products[val]}
                        selected={Number(val) + 1 === selectedProductId}
                        toggleSelect={() => {
                          toggleSelection(Number(val) + 1);
                        }}
                      />
                    </div>
                  );
                })
              )}
            </div>
            <div className="row width-100 absolute-center">
              <div className="col-lg-4 col-md-8 col-sm-12"></div>
            </div>
            <div
              className="width-100"
              style={{ position: "absolute", bottom: "0" }}
            >
              <Pagenation
                pages={allPage}
                setActivePage={(a) => {
                  setPage(a);
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="row margin-0 width-100 space-between"
          style={{ background: "#f6f6f6", padding: "10px" }}
          hiddene={true || Boolean(Object.keys(selectedProduct).length)}
        >
          <div className="col-lg-3 col-md-4 col-sm-12 flex-start-top">
            <div className="width-100 row gx-1">
              <div className="col-lg col-md col-sm col-xs">
                <TextInputField
                  type="number"
                  value={amount}
                  addClass="number width-100 text-center"
                  onChageSetter={(a) => {
                    // if (a.value >= 1) {
                    setAmount(a.value);
                    // }
                    handleCustomChange(a, "amount");
                  }}
                  placeHolder="Amount"
                />
              </div>
              <div className="col-lg col-md col-sm col-xs">
                <DropDown
                  value={size}
                  addClass="width-100"
                  name="product_size"
                  onChageSetter={(a) => {
                    setSize(a.value);
                  }}
                  options={
                    selectedProductId === 0
                      ? props.edit
                        ? JSON.parse(props.preSelected.sizes || "{}")
                        : []
                      : JSON.parse(products[selectedProductId - 1].sizes)
                  }
                  keyValue={true}
                  mergeKeyValue={true}
                  usePropertyNameAsValue={true}
                  placeHolder="Product Size"
                />
              </div>
            </div>
            <InputWithCheckBoxNumber
              message={"Custom Price ($) "}
              value={selectedProduct.customPrice}
              toggle={false}
              onChange={(a) => {
                handleCustomChange(a, "customPrice");
              }}
            />
            {/* <InputWithCheckBox message='Add Sash Message (+$18.50)' addClass='width-75' value={selectedProduct.sash_message} toggle={true} onChange={(a)=>{handleCustomChange(a,'sash_message')}} /> */}
          </div>
          {selectedProduct.product_type === "Casket cover" && (
            <div className="col-lg-2 col-md-2 col-sm-12 flex-start-top border-left-1">
              <LidTypeSelect
                selectChange={(a) => {
                  setLidType((prev) => {
                    return prev !== "" && prev === a ? "" : a;
                  });
                }}
                selected={lidType}
              />
            </div>
          )}
          <div className="col-lg-4 col-md-4 col-sm-12 flex-start-top border-left-1">
            <SashMessageOption
              placeHolder="Type Sash Message..."
              label={
                "Add Sash Message (+$" +
                Number(selectedProduct.sashPrice || 0).toFixed(2) +
                ")"
              }
              value={{
                sashPrice: selectedProduct.sashPrice,
                sashMessage: selectedProduct.sash_message,
              }}
              rows={2}
              options={(props.settings.sash_prices || "").split(",")}
              onChange={(a) => {
                handleCustomChange(a, "sash_message");
              }}
            />
            <CommentWithCheckBox
              placeHolder="Type Comment..."
              label="Add Comment"
              value={selectedProduct.comment}
              onChange={(a) => {
                handleCustomChange(a, "comment");
              }}
            />
          </div>
          {/* <div className='col-lg-2 col-md-4 col-sm-12 flex-start-top'>   
                    </div> */}
          <div className="col-lg-2 col-md-2 col-sm-12 flex-start-top">
            <div className="margin-top-20"></div>
            <ButtonFlat
              addClass=""
              clickTo={() => {
                submitSelectedProduct();
              }}
              value="Select"
            />
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
  //   </div>
}
function LidTypeSelect(props) {
  const list = [
    "Flat Lid",
    "Single Raised",
    "Double Raised Lid",
    "Triple Raised Lid",
    "Domed Lid",
  ];
  return (
    <div className="option-list">
      <div>Lid Type</div>
      {list.map((each) => {
        return (
          <span
            className={
              (props.selected === each ? "selected" : "") + " center-hor"
            }
            onClick={() => {
              props.selectChange(each);
            }}
          >
            {props.selected === each ? (
              <IconsMD.MdCheckBox className="icon mid width-auto margin-5" />
            ) : (
              <IconsMD.MdCheckBoxOutlineBlank className="icon mid width-auto margin-5" />
            )}
            {each}
          </span>
        );
      })}
    </div>
  );
}
export function ProductParentSelectorList(props) {
  const [parentOrders, setParentOrders] = React.useState({});
  const [selectedParentOrderId, setSelectedParentOrderId] = React.useState("");
  const [selectedParentOrder, setSelectedParentOrder] = React.useState({});

  React.useEffect(() => {
    setSelectedParentOrder(parentOrders[selectedParentOrderId]);
  }, [selectedParentOrderId]);

  function toggleSelection(id) {
    setSelectedParentOrderId((prev) => {
      if (prev === id) {
        return "";
      } else {
        return id;
      }
    });
  }
  return (
    <div className="center-ver-col">
      <div className="blur-overlay"></div>
      <div className="select-options">
        <div className="title">
          <h4 className="stretch absolute-center">Select Product</h4>
        </div>
        <div className="width-100 padding-LR-5">
          <SearchField addClass="stretch" />
          {Object.keys(parentOrders).map((val) => {
            return (
              <SimpleSelectableListElement
                details={parentOrders[val]}
                simple={true}
                key={val}
                prod={parentOrders[val]}
                selected={val === selectedParentOrderId}
                toggleSelect={() => {
                  toggleSelection(val);
                }}
              />
            );
          })}
          <div className="margin-top-20"></div>
          <ButtonFlat
            addClass="float-bottom"
            clickTo={() => {
              props.addParent(selectedParentOrder);
              props.hidder();
            }}
            value="Select"
          />
        </div>
      </div>
    </div>
  );
}

export function SashMessageOption(props) {
  const sashPrices = props.options.map((each) => Number(each).toFixed(2));
  const [checked, setChecked] = React.useState(Boolean(props.value));
  const [sashPrice, setSashPrice] = React.useState(0);
  const [sashMessage, setSashMessage] = React.useState(
    props.value.sashMessage || ""
  );
  // console.log('Sash Price values ',props.value)
  useEffect(() => {
    setChecked(Boolean(props.value.sashMessage || props.value.sashPrice));
  }, [props.value]);
  return (
    <div className="marked-comment">
      <div className="width-100 center-hor">
        <LabeledCheckBox
          addClass="width-60"
          checked={checked}
          setter={() => {
            setChecked((prev) => {
              if (prev) {
                props.onChange({ sashMessage: "", sashPrice: 0 });
              }
              return !prev;
            });
          }}
          label={props.label}
        />
        {checked && (
          <DropDown
            onChageSetter={(a) => {
              setSashPrice(a.value);
              props.onChange({
                message: props.value.sashMessage || "",
                sashPrice: Number(a.value || 0).toFixed(2),
              });
            }}
            value={Number(props.value.sashPrice).toFixed(2)}
            placeHolder="Sash Price"
            displayUnit="$"
            width={"width-40"}
            usePropertyNameAsValue={true}
            options={sashPrices}
          />
        )}
      </div>
      {checked && (
        <textarea
          value={props.value.sashMessage}
          onChange={(event) => {
            setSashMessage(event.target.value);
            props.onChange(
              checked
                ? {
                    sashPrice: Number(props.value.sashPrice).toFixed(2) || 0,
                    message: event.target.value,
                  }
                : ""
            );
          }}
          className="text-input width-100 padding-10"
          rows={props.rows || "5"}
          placeholder={props.placeHolder || "Add Comment"}
        ></textarea>
      )}
    </div>
  );
}

export function CommentWithCheckBox(props) {
  const [checked, setChecked] = React.useState(Boolean(props.value));
  useEffect(() => {
    setChecked(props.value);
  }, [props.value]);
  return (
    <div className="marked-comment">
      <LabeledCheckBox
        checked={checked}
        setter={() => {
          setChecked((prev) => {
            if (prev) {
              props.onChange("");
            }
            return !prev;
          });
        }}
        label={props.label}
      />
      {checked && (
        <textarea
          value={props.value}
          onChange={(event) => {
            props.onChange(checked ? event.target.value : "");
          }}
          className="text-input width-100 padding-10"
          rows={props.rows || "5"}
          placeholder={props.placeHolder || "Add Comment"}
        ></textarea>
      )}
    </div>
  );
}

export function InputWithCheckBox(props) {
  const [checked, setChecked] = React.useState(false);
  return (
    <div className="marked-input center-hor width-100">
      <LabeledCheckBox
        checked={checked}
        addClass="width-25"
        setter={() => {
          setChecked((prev) => {
            if (prev) {
              props.onChange("");
            }
            return !prev;
          });
        }}
        label={props.message}
      />
      {(!props.toggle || checked) && (
        <input
          disabled={!checked}
          onChange={(event) => {
            props.onChange(checked ? event.target.value : "");
          }}
          className={"text-input input-forms small margin-0 " + props.addClass}
          placeholder={props.value}
        />
      )}
    </div>
  );
}

export function InputWithCheckBoxNumber(props) {
  const [checked, setChecked] = React.useState(Boolean(props.value));
  useEffect(() => {
    setChecked(props.value);
  }, [props.value]);
  return (
    <div className="marked-input width-100 row">
      <LabeledCheckBox
        checked={checked}
        addClass="col-md-8 col-sm-8"
        setter={() => {
          setChecked((prev) => {
            if (prev) {
              props.onChange("");
            }
            return !prev;
          });
        }}
        label={props.message}
      />
      {(!props.toggle || checked) && (
        <input
          type="number"
          disabled={!checked}
          onChange={(event) => {
            props.onChange(checked ? event.target.value : "");
          }}
          className={
            "text-input input-forms margin-0 col-md-4 col-sm-4 " +
            props.addClass
          }
          value={props.value}
        />
      )}
    </div>
  );
}

export function PriceTag(props) {
  let delivery_fee = isNaN(Number(props.delivery_fee))
    ? 0
    : Number(props.delivery_fee);
  const gst = Number(props.gstVal) || 0.1;
  let price = Number(props.price.value || 0);
  let price_without_gst = Number((price / (1 + gst)).toFixed(2));

  let price_with_delivery = (price_without_gst || 0) + delivery_fee;

  // let gst_cost = ((price_with_delivery/(1+gst)).toFixed(2) * gst).toFixed(2)
  let gst_cost = Number(price_without_gst * gst).toFixed(2);
  let price_total = Number(
    Number(price || 0) + Number(delivery_fee || 0)
  ).toFixed(2);
  // props.set_GSTPrice(props.gts?(price*(1+gst)).toFixed(2):(price ).toFixed(2) )
  return (
    <div className="price-tag">
      <div className="center-hor width-100">
        <span className="msg">
          {props.gts ? "Price without GST" : "Total Price"}
        </span>
        <span className="price-product price">
          {"$ " + price_without_gst.toFixed(2)}
        </span>
      </div>
      <div className="center-hor width-100">
        <span className="msg">GST ({gst * 100}%)</span>
        <span className="price-tax price">{"$ " + gst_cost}</span>
      </div>
      <div className="center-hor width-100">
        <span className="msg">Gross Total</span>
        <span className="price-product price">
          {"$ " + (price || 0).toFixed(2)}
        </span>
      </div>
      <div className="center-hor width-100">
        <span className="msg">Delivery Fee</span>
        <span className="price-product price">
          {"$ " + (delivery_fee || 0).toFixed(2)}
        </span>
      </div>
      <div className="hor-line"></div>
      <div className="center-hor width-100">
        <span className="msg">{props.price.message}</span>
        <span className="price pricetot">{"$ " + price_total}</span>
      </div>
    </div>
  );
}
export function OverLayInputSummary(props) {
  // console.log('summary props overlay ',props.orderDetail)
  function submit() {
    props.submitHandler();
    // props.toggleDisplay()
  }
  return (
    <div
      className={
        "summary padding-10 center-ver-col overlaid-page light-box-shadow " +
        props.addClass
      }
    >
      <header className="large">Work Order Summary</header>
      <div className="row datas center-ver space-around">
        <div className="col-md-6 col-sm-12 data-row">
          <header>Order</header>
          <SummaryTable data={props.orderDetail.order} />
          <header>Date and Time</header>
          <SummaryTable
            data={props.orderDetail.datetime || props.orderDetail.recipient}
          />
        </div>
        <div className="col-md-6 col-sm-12 data-row">
          <header>Selected Products</header>
          <OrdersSummaryTable
            data={(props.orderDetail.selectedProducts || []).filter((val) => {
              return !val.deleted;
            })}
          />
          {props.orderDetail.selectedTribuites
            ? [
                <header>Selected Tribuites</header>,
                <OrdersSummaryTable
                  data={(props.orderDetail.selectedTribuites || []).filter(
                    (val) => {
                      return !val.deleted;
                    }
                  )}
                />,
                <div className="width-50">
                  <PriceTag
                    delivery_fee={
                      (props.orderDetail.price_detail || {})[
                        (props.orderDetail.price_detail || {}).delivery_fee ===
                        "Custom Delivery Fee"
                          ? "custom_delivery_fee"
                          : "delivery_fee"
                      ]
                    }
                    gts={true}
                    gstVal={(props.orderDetail.price_detail || {}).gst || 0.1}
                    price={{
                      message: "Total Price",
                      value: props.orderDetail.totalPrice,
                    }}
                  />
                </div>,
              ]
            : ""}
        </div>
        <div className="row float-bottom">
          <div className="col-md-2 col-sm-2">
            <ButtonFlat clickTo={props.toggleDisplay} value="Cancel" />
          </div>
          <div className="col-md-8 col-sm-2"></div>
          <div className="col-md-2 col-sm-5">
            <ButtonFlat
              value={props.isEdit ? "Update Order" : "Confirm Order"}
              style={{
                background: "rgb(191, 169, 122)",
                width: "100%",
                height: "50px",
                margin: "0",
              }}
              loading={props.loading}
              clickTo={() => {
                submit();
              }}
              addClass="gold-themed"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function ProductTypeSelectionNav(props) {
  const [list, setList] = useState([]);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let url = BASE_SERVER_URL + "/settings/getAll";
    setLoading(true);
    axios.get(url).then((doc) => {
      setLoading(false);

      if (doc.data.status === "success") {
        setList(JSON.parse(doc.data.response[0].product_types || "[]"));
      }
    });
  }, []);

  return loading ? (
    <LoadingDiv amount={0} height={"25px"} speed={2} noDelay={true} />
  ) : (
    <div className="width-100" style={{ display: "flex", flexWrap: "wrap" }}>
      {["All", ...list].map((each, index) => {
        return (
          <div
            onClick={() => {
              props.onChange(index === 0 ? "" : ["All", ...list][index]);
              setActive(false);
            }}
            className={"typetags"}
            key={index}
          >
            {each === "Casket cover" ? "Casket Cover" : each}
          </div>
        );
      })}
    </div>
  );
}

export function CounterTags(props) {
  return <div className="count-tags"></div>;
}
export function Tag(props) {
  return (
    <div className="tags xlight-box-shadow absolute-center">
      {props.message}
    </div>
  );
}
function capitalizeFirstLetter(string) {
  let c = string.charAt(0).toUpperCase() + string.slice(1);
  // console.log('capitalize ', string,c)
  return c;
}

function format_dates(dat, format = "DD-MM-YYYY") {
  return dat;
  const fields = ["funeral_date"];
  let data = { ...dat };
  fields.forEach((each, index) => {
    if (data[each]) {
      data[each] = moment(data[each]).format(format);
    }
  });
  return data;
}

export function SummaryTable(props) {
  // console.log('summary table input pre',props.data)
  let data = format_dates(props.data) || {};
  // console.log('summary table input ',data)
  let array = typeof data === "object" ? Object.keys(data) : data;

  const dateFormat = function (a) {
    return moment(a, "DD/MM/YYYY").format("LL");
  };

  const handleValues = {
    delivery_day: function (a) {
      return weekDays[a] || a;
    },
    delivery_date: dateFormat,
    funeral_date: dateFormat,
  };

  const classToAdd = {
    payment_option: { value: ["**UNPAID**"], class: "red-text bold-1" },
  };

  return (
    <table className={"table-list table-striped summary " + props.addClass}>
      {props.tableHeader ? (
        <thead>
          <tr>
            {props.numbered ? (
              <th scope="row" style={{ width: "10px" }}>
                #
              </th>
            ) : (
              ""
            )}
            {(props.tableHeader || []).map((header) => {
              return (
                <th scope="col" className="text-left">
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
      ) : (
        ""
      )}
      {array.map((val, index) => {
        let title;
        if (props.nameProperty || props.outOfNameList) {
          title = capitalizeFirstLetter(val);
        } else {
          title = keyToNameList[val];
        }

        let isObject = typeof data[val] === "object";
        let location;
        if (val.indexOf("_location") > -1) {
          location = JSON.parse(data[val] || "{}").name;
        }
        return keyToNameList[val] ||
          props.nameProperty ||
          props.outOfNameList ? (
          <tr key={index}>
            {props.numbered ? (
              <th scope="row" style={{ width: "10px" }}>
                {index + 1}
              </th>
            ) : (
              ""
            )}
            <td>{title}</td>
            {!isObject ? (
              <td
                className={
                  ((classToAdd[val] || {}).value || []).includes(data[val])
                    ? classToAdd[val].class
                    : ""
                }
              >
                {!props.checkBox ? (
                  location ? (
                    location
                  ) : data[val] === "Casket cover" ? (
                    "Casket Cover"
                  ) : Object.keys(handleValues).includes(val) ? (
                    handleValues[val](data[val])
                  ) : (
                    data[val]
                  )
                ) : (
                  <BasicCheckBox
                    name={val}
                    checkSetter={(a) => {
                      props.checkSetter(a);
                    }}
                    key={index}
                    checked={Boolean(data[val])}
                  />
                )}{" "}
              </td>
            ) : (
              <td>
                <SummaryTable
                  nameProperty={true}
                  data={data[val]}
                  addClass="flat margin-0 row-xsmall"
                />
              </td>
            )}
            {props.addActionIcon ? (
              <td className="action-icon">
                <IconsMD.MdCancel
                  className="icon mid red"
                  onClick={() => {
                    props.addActionIcon.action(val);
                  }}
                />
              </td>
            ) : (
              ""
            )}
          </tr>
        ) : (
          false
        );
      })}
    </table>
  );
}
export function SummaryTableCheckOffs(props) {
  let data = props.data || {};
  let array = typeof data === "object" ? Object.keys(data) : data;
  return (
    <table className={"table-list summary " + props.addClass}>
      {array.map((val, index) => {
        let title;
        if (props.nameProperty) {
          title = capitalizeFirstLetter(val);
        } else {
          title = keyToNameList[val];
        }
        let isObject = typeof data[val] === "object";

        return keyToNameList[val] || props.nameProperty ? (
          <tr key={index}>
            {!Boolean(data[val]) ? (
              <td>
                <BasicCheckBox
                  name={val}
                  checkSetter={(a) => {
                    props.checkSetter(a);
                  }}
                  key={index}
                  checked={Boolean(data[val])}
                />{" "}
              </td>
            ) : (
              ""
            )}
            <td>{title}</td>
            {Boolean(data[val]) ? (
              <td className="checker_img relative">
                <img src={profile} />
                <span className="check-mark-btn">
                  <IconsBS.BsCheckAll
                    onClick={() => {
                      props.onIconClick(val);
                    }}
                    className="icon check green large"
                  />
                </span>
              </td>
            ) : (
              ""
            )}
          </tr>
        ) : (
          ""
        );
      })}
    </table>
  );
}
export function ProductDetailSide(props) {
  function deleteProduct(a, name) {
    let url = BASE_SERVER_URL + "/products/delete/" + a;
    axios
      .get(url)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success("Product Deleted", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          props.refresh();
        } else {
          toast.error("Error Deleting Product", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {});
    props.hidder();
  }
  const navigate = useNavigate();
  return (
    <div
      className={
        props.active
          ? "order-side-slide product light-box-shadow active border-rad-top-LR"
          : "light-box-shadow order-side-slide border-rad-top-LR"
      }
    >
      <div className="title thin center-hor justify-end">
        <div className="width-auto center-hor margin-10 float-left">
          <IconsMD.MdDelete
            onClick={() => {
              deleteProduct(props.show._id);
            }}
            className="icon white mid margin-10"
          />
          <a href="">
            <IconsMD.MdEdit
              onClick={() => {
                window.open("/newProducts/" + props.show._id, "_blank");
              }}
              className="icon white mid margin-10"
            />
          </a>
        </div>
        <div className="width-30px margin-10">
          <IconsMD.MdCancel onClick={props.hidder} className="icon white mid" />
        </div>
      </div>
      <div className="xlight-box-shadow center-ver-col relative product-detail width-100">
        <div className="margin-top-50"></div>
        {/* <img src={BASE_SERVER_URL+'/'+props.show.image} /> */}

        {props.show.image ? (
          <img
            className="product-img"
            src={BASE_SPACE_URL + "" + props.show.image}
          />
        ) : props.show.product_type === "Casket cover" ? (
          <div className="width-20">
            <GiGraveFlowers className="icon product-img XL grey" />
          </div>
        ) : (
          <div className="width-20">
            <IconsBS.BsFlower3 className="icon product-img XL grey" />
          </div>
        )}

        <div className="margin-top-50"></div>
        <p>{props.show.product_name}</p>
        <div className="margin-top-50"></div>
        <SummaryTable
          data={{
            description: props.show.description,
            code: props.show.code,
            type: props.show.product_type,
            // content: props.show.content? JSON.parse(props.show.content):'',
            // price: props.show.sizes? JSON.parse(props.show.sizes):''
          }}
          addClass="flat"
        />
        <header>Product Content</header>
        <div className="width-100">
          <ProductPriceContentDisplay
            changeActive={(a) => {}}
            data={{
              content: props.show.content ? JSON.parse(props.show.content) : {},
              price: props.show.sizes ? JSON.parse(props.show.sizes) : {},
            }}
          />
        </div>
      </div>
    </div>
  );
}

export function ProductPriceContentDisplay(props) {
  const [data, setData] = useState(props.data.content || "");
  const [data2, setData2] = useState(props.data.price || "");
  const [activeID, setActiveID] = useState(
    Object.keys(props.data.price)[0] || ""
  );
  const [ID, setID] = useState(0);

  useEffect(() => {
    setData(props.data.content);
    setData2(props.data.price);
    if (!activeID) {
      setActiveID(Object.keys(props.data.price)[0]);
    }
  }, [props.data]);

  // useEffect(()=>{
  //     // console.log('active ID ', activeID)
  // },[activeID])

  return (
    <div className="width-100 size-content center-ver-col">
      <div className="width-100 center-hor head">
        {Object.keys(data2).map((each, index) => {
          return (
            <span
              style={{ width: 100 / Object.keys(data2).length + "%" }}
              className={index === ID ? "active" : ""}
              onClick={() => {
                props.changeActive(each);
                setID(index);
                setActiveID(each);
              }}
              key={index}
            >
              {toName(each)}{" "}
              <span
                onClick={() => {
                  props.changeActive(each);
                  setID(index);
                  setActiveID(each);
                }}
              >
                ${data2[each]}
              </span>
            </span>
          );
        })}
      </div>
      {Object.keys(data[activeID] || {}).length > 0 ? (
        <div className="width-100 center-ver-col padding-10 margin-top-10 contents relative">
          {Object.keys(data[activeID] || {}).map((each, index) => {
            // console.log('checking active ', index , each, activeID, index === activeID, toName(each), data, data2)
            return (
              <span
                className={(each === activeID ? "active" : "") + " width-100"}
                key={index}
              >
                <span>{index + 1}. </span> {toName(each)}{" "}
                <span className="amount">x {data[activeID][each]} Pcs</span>
              </span>
            );
          })}
        </div>
      ) : !props.newOne ? (
        <p className="gold-themed font-small margin-top-10 width-100 absolute-center">
          No product content found
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

export function WorkOrderDetailSide(props) {
  // console.log('Detail side ',props.show)
  const [selectedOrder, setSelectedOrder] = React.useState(props.show);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [productisLoading, setProductisLoading] = React.useState(false);
  const [changeState, setChangeState] = React.useState(false);
  const [scheduleDate, setScheduleDate] = React.useState("");

  const [orderState, setOrderState] = useState(props.show.ids.order_state);
  React.useEffect(() => {
    let url =
      BASE_SERVER_URL + "/wo/getProductsByOrderId/" + props.show.ids._id;
    setProductisLoading(true);
    axios.get(url).then((response) => {
      setSelectedProducts(response.data.response);
      setProductisLoading(false);
    });
    setSelectedOrder(props.show);
    setOrderState(props.show.ids.order_state);
  }, [props.show]);

  const [stateLoading, setStateLoading] = useState("");
  function changeOrderState(a, b) {
    setStateLoading(b);
    let url = BASE_SERVER_URL + "/wo/changeState";
    let postData = { id: props.show.ids._id, updateData: { order_state: a } };
    if (a === "Scheduled Cancel") {
      postData.updateData.scheduled_cancel_date = scheduleDate;
    }
    axios
      .post(url, postData)
      .then((response) => {
        setOrderState(a);
        setChangeState(false);
      })
      .catch((err) => {
        setChangeState(false);
      });
  }
  let btnColor = {
    Active: "green",
    "Scheduled Cancel": "gold-themed",
    Canceled: "red-bg color-white",
  };
  return (
    <div
      className={
        props.active
          ? "order-side-slide light-box-shadow active border-rad-top-LR"
          : "light-box-shadow order-side-slide border-rad-top-LR"
      }
    >
      <div className="title thin center-hor justify-end relative">
        <span className="font-medium padding-10 float-left">
          Serial No
          <span
            style={{
              background: "#504e4e",
              padding: "10px 20px",
              marginLeft: "10px",
            }}
          >
            {props.show.ids.serial_no || "N/A"}
          </span>
        </span>
        <div className="width-30px margin-10">
          <IconsMD.MdPrint
            onClick={() => {
              window.open(
                "/workorder/print/" +
                  props.show.ids._id +
                  "/" +
                  replaceCharacter(
                    props.orderType === "Retail"
                      ? props.show.recipient.delivery_date
                      : props.show.datetime.funeral_date,
                    "/",
                    "-"
                  ),
                "_blank"
              );
            }}
            className="icon white mid"
          />
        </div>
        <div className="width-30px margin-10">
          <IconsMD.MdEdit
            onClick={() => {
              window.open(
                "/editworkorder/" +
                  (props.orderType || "").toLowerCase() +
                  "/" +
                  props.show.ids._id,
                "_blank"
              );
            }}
            className="icon white mid"
          />
        </div>
        <div className="width-30px margin-10">
          <ButtonIcon
            addClass="small white"
            loading={props.deleteLoading}
            icon={
              <IconsMD.MdDelete
                onClick={() => {
                  props.remove(props.show.ids._id);
                }}
                className="icon white mid"
              />
            }
          />
        </div>
        <div className="width-30px margin-10">
          <IconsMD.MdCancel onClick={props.hidder} className="icon white mid" />
        </div>
      </div>
      <div
        className="width-100 center-hor relative"
        style={{ margin: "10px 0px 10px 10px" }}
      >
        {props.orderType === "Retail" && [
          <span className="font-medium bold-1" style={{ marginRight: "10px" }}>
            Order Status{" "}
          </span>,
          <div className="center-hor">
            <ButtonFlat
              clickTo={() => {}}
              value={orderState}
              addClass={
                "font-small width-auto thin-flat paddingLR-10 marginRight-2 " +
                btnColor[orderState || props.show.ids.order_state]
              }
            />
            <ButtonFlat
              clickTo={() => {
                setChangeState((prev) => !prev);
              }}
              value={
                changeState ? (
                  <IconsBS.BsArrowUp className="icon white mid width-100" />
                ) : (
                  <IconsBS.BsArrowDown className="icon white mid width-100" />
                )
              }
              addClass={
                "font-medium width-auto thin-flat " + btnColor[orderState]
              }
            />
            {orderState === "Scheduled Cancel" && (
              <span className="font-medium margin-10">
                Last Delivery on :
                <span className="touchable bold-1">
                  {" "}
                  {props.show.order
                    ? scheduleDate || props.show.order.scheduled_cancel_date
                    : "N/A"}{" "}
                </span>
              </span>
            )}
          </div>,
        ]}
        <div
          className="float-right center-hor font-medium"
          style={{ position: "absolute", right: "20px", top: "-4px" }}
        >
          <span
            className="bold-1"
            style={{ background: "#e2e3e3", padding: "0px 10px" }}
          >
            Received By{" "}
          </span>
          <span
            className="text-center"
            style={{
              minWidth: "100px",
              background: "#f9f9f9",
              padding: "0px 10px",
            }}
          >
            {JSON.parse(props.show.ids.received_by || "{}").full_name || "N/A"}
          </span>
        </div>
      </div>
      {changeState && props.orderType === "Retail" && (
        <div className="collapsable-hor">
          {orderState !== "Active" && (
            <ButtonFlat
              clickTo={() => {
                changeOrderState("Active");
              }}
              value={"Active"}
              addClass={
                "font-small width-auto thin-flat paddingLR-10 margin-10 green"
              }
            />
          )}
          {orderState !== "Scheduled Cancel" && (
            <span className="center-hor space-between margin-10">
              <ButtonFlat
                disabled={!scheduleDate ? "disabled" : ""}
                clickTo={() => {
                  changeOrderState("Scheduled Cancel");
                }}
                value="Schedule Cancel"
                addClass={
                  "font-small width-auto paddingLR-10 thin-flat gold-themed"
                }
              />
              <span className="width-40 center-hor">
                <DatePicker
                  value={scheduleDate || null}
                  onChageSetter={(a) => {
                    setScheduleDate(a.value);
                  }}
                  name="delivery_date"
                  label={null}
                  addClass="width-100 color-black"
                  icon={<IconsBS.BsCalendarFill className="icon" />}
                  placeHolder="Delivery Date"
                />
              </span>
            </span>
          )}
          {orderState !== "Canceled" && (
            <ButtonFlat
              clickTo={() => {
                changeOrderState("Canceled");
              }}
              value="Cancel Order"
              addClass={
                "font-small width-auto thin-flat margin-10 paddingLR-10 color-white red-bg"
              }
            />
          )}
        </div>
      )}
      <div className="row xlight-box-shadow margin-10">
        {/* <div className='card-body center-hor'>
                </div> */}
        <div className="col-md-6 col-sm-12">
          <header>Order</header>
          <SummaryTable data={props.show.order} addClass={"flat"} />
        </div>
        <div className="col-md-6 col-sm-12">
          <header>
            {props.orderType === "Funeral"
              ? "Date, Time and Location"
              : "Recipient"}
          </header>
          <SummaryTable
            data={props.show.datetime || props.show.recipient}
            addClass={"flat"}
          />
        </div>
      </div>
      <div className="xlight-box-shadow margin-0 margin-10">
        <header>Selected Products</header>
        <div className="row xlight-box-shadow margin-0">
          <ProductOrdersDetailTableFlat
            refresh={() => {
              props.refresh();
            }}
            deliveryDate={
              props.show.recipient ? props.show.recipient.delivery_date : ""
            }
            loading={productisLoading}
            data={selectedProducts}
          />
        </div>
      </div>
      <div className="div-footer">
        Order to be discarded on :{" "}
        {moment(props.show.ids.date).add(7, "years").format("ll")}
      </div>
    </div>
  );
}

function copyObject(a) {
  return JSON.parse(JSON.stringify(a));
}

export function orderObject(datas, type) {
  if (!datas) {
    return {};
  }
  let data = copyObject(datas);
  // if(data.frequency === 'Once'){
  //     delete data.delivery_day
  // }else{
  //     delete data.delivery_date
  // }
  const recipient = {
    Retail: [
      "recipient_name",
      "recipient_phone",
      "delivery_location",
      "time_of_delivery",
      "autority_to_leave",
      "frequency",
      "delivery_area",
      "delivery_date",
      "delivery_day",
      "order_start_date",
    ],
  };
  const datetime = {
    Funeral: [
      "funeral_date",
      "funeral_location",
      "funeral_time",
      "tribuite_delivery_location",
      "casket_cover_delivery_location",
      "casket_cover_delivery_time",
    ],
  };
  const orders = {
    Funeral: [
      "_id",
      "type",
      "fd_code",
      "ordered_by",
      "payment_email",
      "payment_option",
      "orderer_phone",
      "late_person_name",
    ],
    Retail: [
      "_id",
      "type",
      "scheduled_cancel_date",
      "company_name",
      "ordered_by",
      "payment_email",
      "payment_option",
      "orderer_phone",
      "card_message",
      "order_comment",
    ],
  };
  const cata = {
    ids: ["_id", "serial_no", "order_state", "date", "received_by"],
    order: orders[type] || [],
    selectedParentOrderId: ["parent_funeral_id"],
  };
  if (type === "Funeral") {
    cata.datetime = datetime[type] || [];
  }
  if (type === "Retail") {
    cata.recipient = recipient[type] || [];
  }
  let result = {};
  // console.log('the data ',data)
  Object.keys(cata).map((val) => {
    result[val] = {};
    cata[val].forEach((x) => {
      // console.log(x,data[x])
      if (x === "fd_code") {
        result[val][x] = JSON.parse(data[x] || "{}").code;
      } else {
        if (
          data[x] === undefined &&
          (x === "delivery_date" || x === "delivery_day")
        ) {
        } else {
          result[val][x] = data[x];
        }
      }
    });
  });
  // console.log("ordering the result ", data, result);
  result.price_detail = { ...data.price_detail } || {};
  return result;
}

function BasicCheckBox(props) {
  const [checked, setChecked] = React.useState(props.checked);
  return !checked ? (
    <IconsMD.MdOutlineCheckBoxOutlineBlank
      onClick={() => {
        setChecked(true);
        props.checkSetter({ [props.name]: true });
      }}
      className="icon mid"
    />
  ) : (
    <IconsMD.MdOutlineCheckBox
      onClick={() => {
        setChecked(false);
        props.checkSetter({ [props.name]: false });
      }}
      className="icon mid color-lime"
    />
  );
}

export function CollapsableProductDetailDiv(props) {
  const {
    size,
    amount,
    product_type,
    order_type,
    sash_message,
    comment,
    lidType,
  } = props.detail;
  let price = props.detail.customPrice
    ? props.detail.customPrice
    : props.detail.price;

  const filterd = {
    size,
    amount,
    product_type,
    order_type,
    price,
    sash_message,
    comment,
    lidType,
  };
  const [active, setActive] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const [orderProgress, setOrderProgress] = React.useState({
    start: props.detail.orderProgress.start || "-",
    ended: props.detail.orderProgress.ended || "-",
  });
  const [orderChecked, setOrderChecked] = React.useState({
    checked_1: props.detail.orderProgress.checked_1,
    checked_2: props.detail.orderProgress.checked_2,
    checked_3: props.detail.orderProgress.checked_3,
  });

  const [orderAssinedTo, setOrderAssignedTo] = React.useState(
    props.detail.order_type === "Retail"
      ? FilterOrderAssignedByDate(
          props.deliveryDate,
          props.detail.orderProgress
        )
      : props.detail.orderProgress
  );

  function FilterOrderAssignedByDate(date, assignmet) {
    // console.log('check filter ',assignmet)
    assignmet = assignmet.filter((each) => {
      return each.delivery_date === date;
    });
    // console.log('check filter after ',assignmet)
    return assignmet;
  }

  function assignOrderToUser(a) {
    // modify order detail with user id in it in orderProgress > assigned_to
    let url = BASE_SERVER_URL + "/wo/assignOrderedProducts";
    const date = new Date();
    let newAssign = {
      assigned_to: { user_id: a._id, full_name: a.full_name, thumb: "" },
      start: date.getTime(),
      ended: "",
      checked_1: "",
      checked_2: "",
      checked_3: "",
    };
    if (props.detail.order_type === "Retail") {
      newAssign.delivery_date = props.deliveryDate;
    }
    axios
      .post(url, { user: newAssign, product_id: props.detail._id })
      .then((response) => {
        if (response.data.status === "success") {
          let assign =
            props.detail.order_type === "Retail"
              ? FilterOrderAssignedByDate(
                  props.deliveryDate,
                  response.data.response.orderProgress
                )
              : response.data.response.orderProgress;
          setOrderAssignedTo(assign);
          setOrderChecked({
            checked_1: Boolean(assign.checked_1),
            checked_2: Boolean(assign.checked_2),
            checked_3: Boolean(assign.checked_3),
          });
          setOrderProgress({
            starSSSt: assign.start || "-",
            ended: assign.ended || "-",
          });
          setSearch(false);
        }
      })
      .catch((err) => {
        alert(err);
      });
  }

  React.useEffect(() => {
    // console.log('check assigned ? ',props.detail.orderProgress)
    setOrderProgress({
      start: props.detail.orderProgress.start || "-",
      ended: props.detail.orderProgress.ended || "-",
    });
    setOrderChecked({
      checked_1: props.detail.orderProgress.checked_1,
      checked_2: props.detail.orderProgress.checked_2,
      checked_3: props.detail.orderProgress.checked_3,
    });
    setOrderAssignedTo(
      !props.detail.orderProgress.length
        ? []
        : props.detail.order_type === "Retail"
        ? FilterOrderAssignedByDate(
            props.deliveryDate,
            props.detail.orderProgress
          )
        : props.detail.orderProgress
    );
  }, [props.detail]);

  function removeAssignedUser(b, a) {
    let url =
      BASE_SERVER_URL +
      "/wo/removeAssignedUser/" +
      a +
      "/" +
      b +
      (props.detail.order_type === "Retail"
        ? "/" + props.deliveryDate
        : "/nn/nn/nn");
    axios.get(url).then((response) => {
      // console.log('remove assigned  response ',response.data.response)
      let dat = response.data.response;
      setOrderProgress({
        start: dat.orderProgress.start || "-",
        ended: dat.orderProgress.ended || "-",
      });
      setOrderAssignedTo(
        props.detail.order_type === "Funeral"
          ? dat.orderProgress
          : dat.orderProgress.filter((a) => {
              return a.delivery_date === props.deliveryDate;
            })
      );
      setOrderChecked({
        checked_1: dat.orderProgress.checked_1,
        checked_2: dat.orderProgress.checked_2,
        checked_3: dat.orderProgress.checked_3,
      });
      setSearch(false);
    });
  }
  return (
    <div className="card collapsing xlight-box-shadow">
      <h5
        className="margin-0 center-hor padding-LR-15 font-medium"
        onClick={() => {
          setActive((prev) => !prev);
        }}
      >
        <div className="row width-100 font-medium relative">
          {/* <div className='float-left'>
                    </div> */}
          <div
            className="col-md-8 center-hor relative"
            style={{ paddingLeft: "70px" }}
          >
            <div
              className="float-left grey-themed"
              style={{ padding: "0px 5px", left: "0px" }}
            >
              {filterd.product_type === "Casket cover" ? (
                <GiGraveFlowers className="icon large white" />
              ) : (
                <IconsBS.BsFlower3 className="icon large white" />
              )}
            </div>
            {props.detail.product_name}
          </div>
          <div className="col-md-4 row center-hor">
            <div className="col-md-4 center-hor">${filterd.price}</div>
            <div className="col-md-4 center-hor">x {props.detail.amount}</div>
            <div className="col-md-4 center-hor font-small">
              {props.detail.sashPrice ? "+ $" + props.detail.sashPrice : ""}
            </div>
          </div>
        </div>
      </h5>
      <div
        className={
          active ? "active card-body collapsing" : "card-body collapsing"
        }
      >
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <SummaryTable data={filterd} addClass={"flat small-row"} />
          </div>
          <div className="col-md-6 col-sm-12 center-ver-col">
            {orderAssinedTo.map((each, index) => {
              // console.log('each product ',each )
              return (
                <WorkAssignedDetailToggle
                  userName={each.assigned_to.full_name}
                  assignmentDetail={each}
                  workOrderId={props.detail._id}
                  removeAssignedUser={() => {
                    removeAssignedUser(props.detail._id, each._id);
                  }}
                  setOrderChecked={(a) => {
                    setOrderAssignedTo((prev) => {
                      let obj = [...prev];
                      obj[index] = { ...prev[index], ...a };
                      return obj;
                    });
                    props.refresh();
                  }}
                />
              );
            })}
            {/* {!orderAssinedTo.full_name && !orderAssinedTo.userName? */}
            {(props.detail.product_type !== "Singles/Petals" &&
              orderAssinedTo.length < props.detail.amount) ||
            (props.detail.product_type === "Singles/Petals" &&
              orderAssinedTo.length < 1) ? (
              search ? (
                <SearchandSelectUser
                  onChageSetter={(a) => {
                    assignOrderToUser(a);
                  }}
                  name="user"
                  addClass="stretch height-small"
                  placeHolder="Search User"
                />
              ) : (
                <div className="width-100 margin-10 absolute-center">
                  <p className="warning-text width-100 font-medium">
                    {orderAssinedTo.length} out of{" "}
                    {props.detail.product_type === "Singles/Petals"
                      ? 1
                      : props.detail.amount}{" "}
                    work assigned !
                  </p>
                  <IconsMD.MdAddCircle
                    onClick={() => {
                      setSearch(true);
                    }}
                    className="icon mid width-20px"
                  />
                </div>
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function WorkAssignedDetailToggle(props) {
  const [active, setActive] = useState(false);
  const [checked, setChecked] = useState("");
  const [userSearchActive, setUserSearchActive] = useState(false);
  function assignWorkToUser(a) {
    setUserSearchActive(true);
  }

  function removeCheckOff(a) {
    // if(prompt('Remove check off from work?')){
    //     return false
    // }
    let url = BASE_SERVER_URL + "/wo/check_off";
    let data = {
      checker: "",
      check_level: a,
      order_product_id: props.workOrderId,
      assignment_id: props.assignmentDetail._id,
      ended: "",
    };
    axios.post(url, data).then((response) => {
      let filtered =
        response.data.response.orderProgress.filter((val, index) => {
          return val._id === props.assignmentDetail._id;
        })[0] || [];
      let check = {
        ended: filtered.ended,
        checked_1: filtered.checked_1,
        checked_2: filtered.checked_2,
        checked_3: filtered.checked_3,
      };
      props.setOrderChecked(check);
    });
  }
  function submitChecked(user_id) {
    // input > product and assignment id , checker user id, check level
    let url = BASE_SERVER_URL + "/wo/check_off";
    let date = new Date();
    let data = {
      checker: user_id,
      check_level: checked,
      order_product_id: props.workOrderId,
      assignment_id: props.assignmentDetail._id,
      ended: date.toLocaleString("en-GB"),
    };
    axios.post(url, data).then((response) => {
      let filtered =
        response.data.response.orderProgress.filter((val, index) => {
          return val._id === props.assignmentDetail._id;
        })[0] || [];
      let check = {
        ended: filtered.ended,
        checked_1: filtered.checked_1,
        checked_2: filtered.checked_2,
        checked_3: filtered.checked_3,
      };
      props.setOrderChecked(check);
    });
  }
  let date = new Date();
  date = date.toLocaleDateString();
  // let d = moment(props.assignmentDetail.start)
  // let e = moment(props.assignmentDetail.ended || date)

  let d = props.assignmentDetail.start / 1000;
  let e = props.assignmentDetail.ended / 1000;
  // let e = moment(props.assignmentDetail.ended || date)
  // let timeDifference = e.diff(d,'minutes') || 0
  let timeDifference = SecondsToReadable(e - d)
    ? SecondsToReadable(e - d)
    : "Work in progress";
  // let timeDifference =  SecondsToReadable(e-d)
  // console.log("the time diff ", d, e, timeDifference);
  return (
    <div className="width-100">
      {userSearchActive ? (
        <PopUpSelectUser
          AcceptUserInfo={(a) => {
            submitChecked(a);
          }}
          returnOnlyId={true}
          hidder={() => {
            setUserSearchActive(false);
          }}
        />
      ) : (
        ""
      )}
      <div
        onClick={() => {
          setActive((prev) => !prev);
        }}
        className="width-100 small-list-card user xlight-box-shadow absolute-center font-medium bold-1 relative"
      >
        <img src={profile} />
        {props.assignmentDetail.assigned_to.full_name}
        <IconsAI.AiOutlineDelete
          onClick={() => {
            props.removeAssignedUser();
          }}
          className="icon mid white float-right width-30px"
        />
      </div>
      <div
        className={!active ? "hidden row margin-top-20" : "row margin-top-20"}
      >
        <div className="col-md-6 col-sm-12">
          <SummaryTable
            data={{
              start: props.assignmentDetail.start
                ? ToReadableDate(
                    new Date(Number(props.assignmentDetail.start)),
                    [],
                    "/",
                    "lll"
                  )
                : "",
              ended: props.assignmentDetail.ended
                ? ToReadableDate(
                    new Date(Number(props.assignmentDetail.ended)),
                    [],
                    "/",
                    "lll"
                  )
                : "",
              // time_difference: (moment(props.assignmentDetail.ended).diff(moment(props.assignmentDetail.start) || '-'))+' minutes'}}
              time_difference: timeDifference,
            }}
            addClass={"flat small-row"}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <SummaryTableCheckOffs
            data={{
              checked_2: Boolean(props.assignmentDetail.checked_2),
              checked_3: Boolean(props.assignmentDetail.checked_3),
            }}
            checkSetter={(a) => {
              setChecked(Object.keys(a)[0]);
              assignWorkToUser(a);
            }}
            checkBox={true}
            addClass={"flat mid-row"}
            onIconClick={(a) => {
              removeCheckOff(a);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export function ProductOrdersDetailTableFlat(props) {
  // console.log('all products making ',props.data)
  let data = props.data || {};
  return (
    <div className="padding-0">
      {props.loading ? (
        <LoadingDiv amount={3} />
      ) : (
        Object.keys(data).map((val, index) => {
          return (
            <CollapsableProductDetailDiv
              refresh={() => {
                props.refresh();
              }}
              deliveryDate={props.deliveryDate}
              key={index}
              detail={data[val]}
              id={index}
            />
          );
        })
      )}
    </div>
  );
}
export function ProductOrdersDetailTable(props) {
  // console.log('all products ',props.data)
  let data = props.data || {};
  return (
    <table className="table-list summary">
      <tr className="bold-1 table-head-bg">
        <td>#</td>
        <td>Product Name</td>
        <td>Sash Message</td>
        <td>Sash Price</td>
        <td>Price of Each</td>
        <td>Amount</td>
        <td>Price Total</td>
      </tr>
      {Object.keys(data).map((val, index) => {
        let price = data[val].customPrice
          ? data[val].customPrice
          : data[val].price;
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{data[val].name}</td>
            <td>{data[val].sash_message || ""}</td>
            <td>
              {"$ "}
              {data[val].sashPrice || 0}
            </td>
            <td>{"$ " + price}</td>
            <td>{"X " + data[val].amount}</td>
            <td>
              {"$ " +
                eval(data[val].amount * price + (data[val].sashPrice || 0))}
            </td>
          </tr>
        );
      })}
    </table>
  );
}

export function SearchandSelectUser(props) {
  const searchResults = [];
  const [users, setUsers] = React.useState([]);
  const input = React.useRef("");
  const [value, setValue] = React.useState(props.value);
  const [relatedList, setRelatedList] = React.useState([]);
  const searchRef = React.useRef(null);
  useOutsideAlerter(searchRef, () => {
    setFocus(false);
  });
  const [focus, setFocus] = React.useState(false);

  React.useEffect(() => {
    let url = BASE_SERVER_URL + "/users/getAll";
    axios.get(url).then((response) => {
      setUsers(response.data.response);
      // console.log('users ',response.data.response)
    });
  }, []);

  function setter(a) {
    setValue(a);
    let x = users.filter((val) => {
      return val.full_name === a;
    });
    props.onChageSetter(x[0]);
  }

  React.useEffect(() => {
    let all = [];
    users.forEach((each) => {
      if (each.userName) {
        all.push(each.full_name);
      }
    });
    setRelatedList([]);
    let filtered = [];
    let txt = value || "";
    if (all.length > 0 && txt !== "") {
      all.filter((each) => {
        if (
          (each || "").toLowerCase().indexOf((txt || "").toLowerCase()) >= 0
        ) {
          filtered.push(each);
        }
      });
      setRelatedList((prev) => {
        return filtered;
      });
    }
  }, [value]);

  return (
    <div className="input relative width-100" ref={searchRef}>
      <input
        ref={input}
        onFocus={() => {
          setFocus(true);
        }}
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        name={props.name}
        className={"input-forms light flat text-input " + props.addClass}
        type={props.type || "text"}
        placeholder={props.placeHolder}
      />
      {focus && (
        <div className="related-results text-only center-ver-col">
          {relatedList.length ? "" : "No Result"}
          {relatedList.map((val) => {
            return (
              <div
                className="width-100 small-list-card user xlight-box-shadow absolute-center font-medium bold-1"
                onClick={() => {
                  setter(val);
                  setFocus(false);
                }}
              >
                <img src={profile} />
                {val}
              </div>
            );
          })}
          {/* <ParentOrderSearchResultList setFocus={setFocus} selectResultVal={props.selectResultVal} data={searchResult}/> */}
        </div>
      )}
      {/* {//console.log(input.current.value)} */}
    </div>
  );
}

export function ToggleRadioWithText(props) {
  const [choice, setChoice] = React.useState(
    props.toggleOptions[props.default] || props.toggleOptions[0]
  );
  React.useEffect(() => {
    props.setToggle(choice);
  }, [choice]);
  return (
    <div className="toggle-radio center-hor xlight-box-shadow">
      <span
        className={
          choice === props.toggleOptions[0]
            ? "absolute-center font-medium active"
            : "absolute-center font-medium"
        }
        onClick={() => {
          setChoice(props.toggleOptions[0]);
        }}
      >
        {props.toggleOptions[0]}
      </span>
      <span
        className={
          choice === props.toggleOptions[1]
            ? "absolute-center font-medium active"
            : "absolute-center font-medium"
        }
        onClick={() => {
          setChoice(props.toggleOptions[1]);
        }}
      >
        {props.toggleOptions[1]}
      </span>
    </div>
  );
}
export function ToggleOrderType(props) {
  return (
    <div className="toggle-radio center-hor xlight-box-shadow">
      <a
        className={
          "Funeral" === props.orderType
            ? "absolute-center font-medium active"
            : "absolute-center font-medium"
        }
        href="/WorkOrderList/funeral"
      >
        Funeral
      </a>
      <a
        className={
          "Retail" === props.orderType
            ? "absolute-center font-medium active"
            : "absolute-center font-medium"
        }
        href="/WorkOrderList/retail"
      >
        Retail
      </a>
    </div>
  );
}

export function PopUpSelectUser(props) {
  const [selectedUser, setSelectedUser] = React.useState({});

  return (
    <div className="popup fullscreen absolute-center">
      <div className="popup-window xlight-box-shadow relative">
        <div className="title thin absolute-center">Select User</div>
        {Object.entries(selectedUser).length ? (
          <div className="margin-top-50 width-100 small-list-card user xlight-box-shadow absolute-center font-medium bold-1 relative">
            <img src={profile} />
            {selectedUser.full_name}
            <IconsMD.MdOutlineCancel
              onClick={() => {
                setSelectedUser({});
              }}
              className="icon mid white float-right width-30px"
            />
          </div>
        ) : (
          <div className="width-100 padding-10 margin-top-10">
            <SearchandSelectUser
              onChageSetter={(a) => {
                setSelectedUser(a);
              }}
              placeHolder="Search Staff"
              addClass="width-100 height-mid"
            />
          </div>
        )}
        <div className="action-btns width-100 center-hor">
          <div className="width-50">
            <ButtonFlat
              value="Cancel"
              clickTo={() => {
                props.hidder();
              }}
            />
          </div>
          <div className="width-50">
            <ButtonFlat
              value="Select"
              addClass="green-themed"
              clickTo={() => {
                props.AcceptUserInfo(
                  props.returnOnlyId ? selectedUser._id : selectedUser
                );
                props.hidder();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function LoadingDiv(props) {
  let speed = props.speed || 1;
  const [pos, setPos] = useState(50);
  const [deg, setDeg] = useState(45);

  function animate() {
    setPos((prev) => {
      if (prev > 150) {
        if (props.rotating) {
          setDeg((pDeg) => {
            return pDeg - 45;
          });
        }
        return 0;
      }
      return prev + speed;
    });
  }
  requestAnimationFrame(animate);
  let color1 = "250, 250, 249,1";
  let color2 = "255,255,255";
  let delay = 8;
  let gap = 0;
  let divs = [];
  let a = 0;
  while (a <= props.amount) {
    let posX = pos + gap;
    if (pos + gap > 150) {
      gap = 0;
    }
    divs.push(
      <div
        className={props.CoverDivClass || ""}
        style={{ height: props.height || "25px", ...(props.style || {}) }}
      >
        <div
          style={{
            ...(props.innerStyle || {}),
            height: props.height || "25px",
            background:
              "linear-gradient(" +
              deg +
              "deg, rgba(" +
              color2 +
              ",1) 0%, rgba(" +
              color2 +
              ",1) " +
              (posX - 20) +
              "%, rgba(" +
              color1 +
              ") " +
              posX +
              "%, rgba(" +
              color2 +
              ",1) " +
              (posX + 20) +
              "%, rgba(" +
              color2 +
              ",1) 100%)",
          }}
          className="loading-div xlight-box-shadow"
        ></div>
      </div>
    );
    a++;
    gap = !props.noDelay ? gap + delay : gap;
  }

  return divs;
}

export function LoadingWait(props) {
  return (
    <div className={"absolute-center " + props.addClass} style={props.addStyle}>
      <div className="loading-wait space-around center-hor">
        <div
          className="wait-box"
          style={{ background: props.dotColor, animationDelay: "0ms" }}
        ></div>
        <div
          className="wait-box"
          style={{ background: props.dotColor, animationDelay: "250ms" }}
        ></div>
        <div
          className="wait-box"
          style={{ background: props.dotColor, animationDelay: "500ms" }}
        ></div>
        <div
          className="wait-box"
          style={{ background: props.dotColor, animationDelay: "750ms" }}
        ></div>
      </div>
    </div>
  );
}

export function ReloadOption(props) {
  return (
    <div className="reload-display">
      <IconsFA.FaRegSadTear className="icon xlarge" />
      <p>
        Failed to load list. Click to reload
        <IconsAI.AiOutlineReload onClick={props.reload} className="icon mid" />
      </p>
    </div>
  );
}

export function RunListRowHead(props) {
  return (
    <div
      className={
        (props.edit ? "font-small" : "") +
        " delivery-run-row head center-hor width-100 " +
        props.addClass
      }
    >
      <span>#</span>
      <span className="width-20">Name</span>
      <span className="width-30">FD Code and Location</span>
      <span>Time</span>
      <span className="width-20">Products</span>
      {(props.edit || props.new) && <span></span>}
    </div>
  );
}

export function RunDeliveriesListRow(props) {
  // console.log('given row data ',props.data)
  let fd =
    props.data.order_type === "Funeral"
      ? JSON.parse(props.data.fd_code || "{}")
      : false;
  // return false
  return (
    <div className="delivery-run-row center-hor width-100 xlight-box-shadow font-medium">
      <span>{props.num}</span>
      <span className="width-20">{props.data.name}</span>
      <span className="width-30">
        <bold>{fd ? fd.code : ""}</bold>{" "}
        {props.data.order_type === "Funeral" ? " and " : ""}
        {JSON.parse(props.data.location || "{}").name}
      </span>
      <span>{props.data.time}</span>
      <span className="width-20">
        {replaceCharacter(
          shorten_product_list(props.data.products || "[]", true).toString(),
          ",,",
          ","
        ) +
          " " +
          delivery_addons(props.data.products || "[]").toString()}
      </span>
      {(props.edit || props.new) && (
        <span>
          <IconsMD.MdCancel className="icon mid" onClick={props.remove} />
        </span>
      )}
    </div>
  );
}

export function delivery_addons(a, b = false) {
  // console.log("delivery addon ", a);

  a = typeof a === "string" ? JSON.parse(a) : a;
  let arr = [];
  // let easelProducts = ["Wreath", "Heart", "Cross", "Sprays", "Letters"];
  let easelProducts = EaselProducts;
  let easelsNum = 0;
  let PedstalsNum = 0;
  let ret = a.map((val, index) => {
    // console.log("checking producttype here ", val.product_type, "adding to ", easelsNum);
    if (val.product_type === "Bowl") {
      PedstalsNum += val.amount;
      // }else if(val.product_type === 'Wreath' || val.product_type === 'Heart' || val.product_type === 'Cross'){
    } else if (easelProducts.includes(val.product_type)) {
      easelsNum += val.amount;
    }
  });
  if (easelsNum > 0) {
    arr.push(" Easels x " + easelsNum);
  }
  if (PedstalsNum > 0) {
    arr.push(" Pedstals x " + PedstalsNum);
  }

  return arr;
}

export function shorten_product_list_2(a, b = false) {
  a = typeof a === "string" ? JSON.parse(a) : a;
  let arr = [];
  // let easelProducts = ["Wreath", "Heart", "Cross", "Spray", "Letters"];
  let easelProducts = EaselProducts;
  let easelsNum = 0;
  let ret = a.map((val, index) => {
    if ((val.product_type || "").toLowerCase() === "casket cover") {
      return (index > 0 ? " " : "") + val.amount + " x " + val.code;
    } else {
      if (val.product_type === "Bowl") {
        arr.push(val.amount + " x Pedstals ");
        // }else if(val.product_type === 'Wreath' || val.product_type === 'Heart' || val.product_type === 'Cross'){
      } else if (easelProducts.includes(val.product_type)) {
        easelsNum += val.amount;
        // console.log("type list ", val.product_type, easelsNum);
      }
      return (index > 0 ? " " : "") + val.amount + " x " + val.product_type;
    }
  });
  if (easelsNum > 0) {
    arr.push(easelsNum + " x Easels ");
  }
  // console.log('type list ',[...ret,...arr],easelsNum,arr,ret)
  // console.log("type list 2 ", easelsNum, arr);
  if (b) {
    return [...ret, ...arr];
  } else {
    return ret;
  }
}

export function RunListRowHeadDetail(props) {
  return (
    <div
      style={props.style || {}}
      className={
        "delivery-run-row detail print bold-1 head center-hor width-100 " +
        props.addClass
      }
    >
      <span style={{ width: "15px" }}>#</span>
      <span className="width-20">Recipient</span>
      <span>FD Code</span>
      <span className="width-30">Delivery Location</span>
      <span>Delivery Time</span>
      <span className="width-25">Products</span>
    </div>
  );
}
export function WOListRowHeadDetail(props) {
  // console.log("wo props ", props);
  let head =
    props.type === "Funeral" ? (
      <div
        style={props.style || {}}
        className={
          "delivery-run-row detail print bold-1 head center-hor width-100 " +
          props.addClass
        }
      >
        <span style={{ width: "15px" }}>#</span>
        {/* <span>Type</span> */}
        <span className="width-10">Recipient</span>
        {/* <span>Funeral Date</span> */}
        <span>FD Code</span>
        <span className="width-30">Delivery Location</span>
        <span>Delivery Time</span>
        <span>CC Delivery</span>
        <span>CC Delivery Time</span>
        <span>Casket Covers</span>
        <span>Tributes</span>
      </div>
    ) : (
      <div
        style={props.style || {}}
        className={
          "delivery-run-row detail print bold-1 head center-hor width-100 " +
          props.addClass
        }
      >
        <span style={{ width: "15px" }}>#</span>
        {/* <span>Type</span> */}
        <span className="width-10">Company Name</span>
        <span className="width-10">Ordered By</span>
        <span className="width-20">Recipient Name</span>
        <span className="width-20">Delivery Location</span>
        {/* <span>Delivery Date</span> */}
        {/* <span className='width-10'>Delivery Time</span> */}
        <span className="width-10">Delivery Time</span>
        <span className="width-20">Selected Products</span>
      </div>
    );

  return head;
}

export function ToDateFormat(date, inFormat) {
  try {
    let day = moment(date, inFormat).format("dddd").toUpperCase();
    let dayN = moment(date, inFormat).format("DD").toUpperCase();
    let month = moment(date, inFormat).format("MMMM").toUpperCase();
    let dat = moment(date, inFormat).format("LL").toUpperCase();
    let year = dat.split(",")[1];
    return `${day} ${month} ${dayN} ${year}`;
  } catch (err) {
    return date;
  }
}

export function WOsListRowDetail(props) {
  let fd = props.type === "Funeral" ? JSON.parse(props.data.fd_code || "") : "";
  // console.log("orders row ", props);
  let row =
    props.type === "Funeral" ? (
      <div className="delivery-run-row detail print center-hor width-100 xlight-box-shadow">
        <span style={{ width: "15px" }}>{props.num}</span>
        <span className="width-10">
          {props.data.type === "Retail"
            ? props.data.recipient_name
            : props.data.late_person_name}
        </span>
        {/* <span>{props.data.type==='Retail'? 'N/A':ToDateFormat(props.data.funeral_date, 'DD/MM/YYYY')}</span>  */}
        <span>{props.data.type === "Retail" ? "N/A" : fd.code}</span>
        <span className="width-30">
          {JSON.parse(props.data.funeral_location || "{}").name}
        </span>
        <span>{props.data.funeral_time}</span>
        <span>
          {JSON.parse(props.data.casket_cover_delivery_location || "[]").name}
        </span>
        <span>{props.data.casket_cover_delivery_time}</span>
        <span>
          {shorten_product_list(
            JSON.stringify([
              ...props.allCC,
              ...filter_products_by_type(
                [...props.allTributes],
                ["Singles/Petals"]
              ),
            ]) || []
          )}
        </span>
        <span>
          {shorten_product_list(
            JSON.stringify(
              filter_products_by_type(
                [...props.allTributes],
                ["Singles/Petals"],
                true
              )
            ) || []
          )}
        </span>
      </div>
    ) : (
      <div className="delivery-run-row detail print center-hor width-100 xlight-box-shadow">
        <span style={{ width: "15px" }}>{props.num}</span>
        <span className="width-10">{props.data.company_name}</span>
        <span className="width-10">{props.data.ordered_by}</span>
        <span className="width-20">{props.data.recipient_name}</span>
        <span className="width-20">
          {JSON.parse(props.data.delivery_location || "{}").name}
        </span>
        <span className="width-10">{props.data.time_of_delivery}</span>
        <span className="width-20">
          {shorten_product_list(props.data.products_selected)}
        </span>
      </div>
    );

  // return false
  return row;
}
export function RunDeliveriesListRowDetail(props) {
  // console.log('given row data ',props.edit)
  let fd =
    props.data.order_type === "Funeral"
      ? JSON.parse(props.data.fd_code || "")
      : "";
  // return false
  return (
    <div className="delivery-run-row detail print center-hor width-100 xlight-box-shadow">
      <span style={{ width: "15px" }}>{props.num}</span>
      {/* <span>{props.data.order_type}</span> */}
      {/* <span>{props.delivery_date}</span> */}
      <span className="width-20">
        {props.data.order_type === "Retail"
          ? props.data.name
          : props.data.late_person_name}
      </span>
      <span>{props.data.order_type === "Retail" ? "N/A" : fd.code}</span>
      {props.data.order_type === "Retail"
        ? [
            <span className="width-30">
              {JSON.parse(props.data.location || "{}").name}
            </span>,
            <span>{props.data.time}</span>,
          ]
        : [
            <span className="width-30">
              {JSON.parse(props.data.location || "{}").name}
            </span>,
            <span>{props.data.time}</span>,
          ]}
      <span className="width-25">
        {shorten_product_list(props.data.products || "[]")}{" "}
        {delivery_addons(props.data.products || "[]")}
      </span>
    </div>
  );
}

export function RunSheetPrintPage(props) {
  const pageStyleRef = React.createRef();
  let deliveries = props.deliveries || [];
  // console.log('rowinf row ', deliveries)

  let ListHeader = props.wos ? (
    <WOListRowHeadDetail
      type={props.type}
      style={{ fontSize: props.font + "px" }}
      new={false}
    />
  ) : (
    <RunListRowHeadDetail style={{ fontSize: props.font + "px" }} new={false} />
  );

  return (
    <div className="the-print-page print-page display-flex">
      {/* <div ref={pageStyleRef} id='someID' style={{ display: 'none' }} /> */}

      <div className="relative width-100 flex-grow">
        <div className="width-100 center-hor">
          <div className="absolute-center" style={{ width: "150px" }}>
            <img src={logo} style={{ width: "100px" }} />
          </div>
          <div className="width-100">
            <div className="head page center-hor relative ">
              <span
                className="absolute-center date light-box-shadow"
                style={{ fontSize: props.font + "px" }}
              >
                {props.date}
              </span>
              <span
                className="font-big absolute-center width-50"
                style={{ marginLeft: "0px" }}
              >
                {props.wos ? "Work Order List" : "Delivery Run Sheet"}
              </span>
            </div>
          </div>
        </div>
        {!props.wos && (
          <div className="row width-100" style={{ marginTop: "20px" }}>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div
                className="run-details font-print xlight-box-shadow"
                style={{ fontSize: props.font + "px" }}
              >
                <span className="text-description width-100 center-hor">
                  <bold className="absolute-center">Vehicle No. Plate</bold>{" "}
                  {props.detail.vehicle_number}{" "}
                </span>
                <div className="width-100 center-hor">
                  <span className="text-description width-60 center-hor">
                    <bold className="absolute-center">Driver's Name</bold>{" "}
                    {props.detail.drivers_name}
                  </span>
                  <span className="text-description width-40 center-hor">
                    <bold className="center-hor padding-10 margin-0 width-100 text-left line-bottom-dashed">
                      Driver Sign
                    </bold>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div
                className="run-details font-print xlight-box-shadow"
                style={{ fontSize: props.font + "px" }}
              >
                <span className="text-description width-100 center-hor">
                  <bold className="absolute-center">Description</bold>{" "}
                  {props.detail.description}{" "}
                </span>
                <span className="text-description width-100 center-hor">
                  <bold className="absolute-center">Comment</bold>{" "}
                  {props.detail.comment}
                </span>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            minHeight: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="padding-10" style={{ fontSize: props.font + "px" }}>
            {ListHeader}
            {props.type !== "Funeral"
              ? deliveries.map((each, index) => {
                  let ret1 = props.wos ? (
                    <WOsListRowDetail
                      type={props.type}
                      num={index + 1}
                      delivery_date={props.detail.delivery_date || ""}
                      key={index}
                      data={each}
                    />
                  ) : (
                    <RunDeliveriesListRowDetail
                      num={index + 1}
                      delivery_date={props.detail.delivery_date || ""}
                      key={index}
                      data={each}
                    />
                  );
                  return ret1;
                })
              : Object.keys(deliveries).map((eache, index) => {
                  let each = deliveries[eache];
                  let allCC = [];
                  let allTributes = [];
                  each.forEach((e) => {
                    allCC = [
                      ...allCC,
                      ...JSON.parse(e.casket_cover_selected || "[]"),
                    ];
                    allTributes = [
                      ...allTributes,
                      ...JSON.parse(e.tributes_selected || "[]"),
                    ];
                  });

                  return (
                    <WOsListRowDetail
                      type={props.type}
                      allCC={allCC}
                      allTributes={allTributes}
                      num={index + 1}
                      delivery_date={props.detail.delivery_date || ""}
                      key={index}
                      data={each[0]}
                    />
                  );
                })}
          </div>
        </div>
      </div>
    </div>
  );
}

export function ToReadableDate(
  date,
  fields = [],
  s = "-",
  aformat = "",
  inFormat = ""
) {
  // return date
  let format = aformat ? aformat : "DD" + s + "MM" + s + "YYYY";
  if (fields.length > 0) {
    let obj = [...date];
    return obj.map((each1) => {
      let newObj = { ...each1 };
      fields.map((each) => {
        newObj[each] = moment(newObj[each], inFormat ? inFormat : null).format(
          format
        );
      });
      return newObj;
    });
  } else {
    // console.log('To readable : ', moment(date,inFormat?inFormat:null).format(format))
    return date
      ? moment(date, inFormat ? inFormat : null).format(format)
      : "N/A";
  }
}

function SecondsToReadable(a) {
  if (a < 0) {
    return false;
  }
  if (a < 60 * 60 * 24) {
    if (a > 3600) {
      return UnitQnt(Math.floor(a / 3600), "hr");
    }
    if (a > 60) {
      return UnitQnt(Math.floor(a / 60), "min");
    }
    if (a < 60) {
      return UnitQnt(Math.floor(a), "sec");
    }
  } else {
    return UnitQnt(Math.floor(a / (60 * 60 * 24)), "day");
  }
}

function UnitQnt(a, b) {
  if (a > 1) {
    return a + " " + b + "s";
  } else {
    return a + " " + b;
  }
}

function ProfileView(props) {
  const [active, setActive] = useState(false);
  // console.log('current user btn ',props.currentUser)
  return (
    <div className="profile-view">
      <span
        onClick={() => {
          setActive((prev) => !prev);
        }}
      >
        {(props.currentUser || {}).access === "Super Admin" ? (
          <IconsFA.FaUserTie className="icon large padding-5 grey" />
        ) : (
          <IconsFA.FaUser className="icon large padding-5 grey" />
        )}
      </span>
      {/* <img src={profile} onClick={()=>{setActive(prev => !prev)}}/> */}
      {active && (
        <div className="profile-detail xlight-box-shadow bold-1 font-medium text-center">
          {(props.currentUser || {}).full_name}
          <ButtonFlat
            value="Log Out"
            clickTo={() => {
              props.logout();
            }}
            addClass="form font-small"
          />
        </div>
      )}
    </div>
  );
}
export function PageLoading(props) {
  return (
    <div className="loading popup fullscreen absolute-center">
      <div style={{ marginTop: "-100px" }}>
        {props.noLogo || (
          <img src={logo} style={{ width: "130px", marginBottom: "40px" }} />
        )}
        {props.spinning ? (
          <div className={"loading-spin large"}></div>
        ) : (
          <LoadingWait dotColor="#b79855" />
        )}
      </div>
    </div>
  );
}

export function ButtonIcon(props) {
  return (
    <div className="absolute-center">
      {props.loading ? (
        <div className={"loading-spin " + props.addClass}></div>
      ) : (
        props.icon
      )}
    </div>
  );
}

export function replaceCharacter(inputString, searchChar, replaceChar) {
  var result = "";
  inputString = inputString ? inputString : "";
  for (var i = 0; i < inputString.length; i++) {
    if (inputString.charAt(i) === searchChar) {
      result += replaceChar;
    } else {
      result += inputString.charAt(i);
    }
  }
  //   console.log('change char ', inputString, searchChar, replaceChar, result)
  return result;
}

export function ImageDisplay(props) {
  const [isLoading, setIsLoading] = useState(true);
  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  return (
    <div className="absolute-center">
      {isLoading && (
        <div
          class="loading-spin large "
          style={{ marginRight: "-80px", zIndex: "99" }}
        ></div>
      )}
      <img
        src={props.src}
        className={props.className + " relative"}
        // style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        onLoad={handleImageLoaded}
      />
    </div>
  );
}

function getDaysInMonthWithDay(year, month) {
  // Create a Date object for the first day of the given month
  const firstDayOfMonth = new Date(year, month - 1, 1);

  // Create a Date object for the first day of the next month
  const firstDayOfNextMonth = new Date(year, month, 1);

  // Calculate the difference between the two dates in milliseconds
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const differenceInMillis = firstDayOfNextMonth - firstDayOfMonth;

  // Calculate the number of days in the given month
  const daysInMonth = Math.ceil(differenceInMillis / millisecondsInADay);

  // Create an array to store the days with their corresponding dates
  const daysArray = [];

  // Loop through the days and add them to the array
  for (let i = 1; i <= daysInMonth; i++) {
    const currentDate = new Date(year, month - 1, i);
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      weekday: "short",
      day: "numeric",
      month: "short",
    });
    daysArray.push(formattedDate);
  }
  return daysArray;
}

export function CalendarPagePicker(props) {
  const [selected, setSelected] = useState(
    new Date().toLocaleDateString("en-US", {
      weekday: "short",
      day: "numeric",
      month: "short",
    })
  );
  let da = props.selectedDate.split("/");
  let today = Number(da[0]) || new Date().getDate();
  let m = Number(da[1]) || new Date().getMonth();
  let y = Number(da[2]) || new Date().getFullYear();

  const [selectedDay, setSelectedDay] = useState(today - 1);
  const [year, setYear] = useState(y);
  const [month, setMonth] = useState(m);
  const [daysInTheMonth, setDaysInTheMonth] = useState(
    getDaysInMonthWithDay(year, month)
  );

  useEffect(() => {
    setDaysInTheMonth(getDaysInMonthWithDay(year, month));
  }, [month, year]);

  useEffect(() => {
    let da = props.selectedDate.split("/");
    let today = Number(da[0]) || new Date().getDate();
    let m = Number(da[1]) || new Date().getMonth();
    let y = Number(da[2]) || new Date().getFullYear();

    setSelectedDay(today - 1);
    setMonth(m);
    setYear(y);
    // console.log('changed date ', props.selectedDate)
  }, [props.selectedDate]);

  let all = 16;
  useEffect(() => {
    if (selectedDay < start) {
      setStart(selectedDay);
      if (selectedDay + all < end) {
        setEnd((prev) => prev - 1);
      }
    } else if (selectedDay > end) {
      setEnd(selectedDay);
      if (selectedDay - all > start) {
        setStart((prev) => prev + 1);
      }
    }
    // setSelected(daysInTheMonth[selectedDay])
    // console.log('start end date: ', selectedDay+'/'+month+'/'+year)
    props.changeDate(
      moment(month + "/" + (selectedDay + 1) + "/" + year).format(
        "DD/MM/YYYY"
      ) || ""
    );
  }, [selectedDay]);

  let a = 9;
  // Example usage:
  const [start, setStart] = useState(
    selectedDay - a + 1 < 0 ? 0 : selectedDay - a + 1
  );
  const [end, setEnd] = useState(
    selectedDay + a > daysInTheMonth.length
      ? daysInTheMonth.length
      : selectedDay + a
  );
  useEffect(() => {
    // console.log('start end changed ', start , end)
  }, [start, end]);
  function SkipSelected(dir) {
    setSelectedDay((prev) => {
      // console.log('start-end-show: months : ',dir, prev, dir? prev+1 : prev-1)
      if ((prev > 0 && !dir) || (prev < daysInTheMonth.length - 1 && dir)) {
        return dir ? prev + 1 : prev - 1; // still in the month
      } else {
        // out of month
        if ((month > 1 && !dir) || (month < 12 && dir)) {
          let months = getDaysInMonthWithDay(year, dir ? month + 1 : month - 1);
          setDaysInTheMonth(months);
          let monthLength = months.length;
          setMonth((prev) => {
            return dir ? prev + 1 : prev - 1;
          });
          if (!dir) {
            setStart(monthLength - all);
            setEnd(monthLength + 1);
          } else {
            setStart(0);
            setEnd(all);
          }
          return dir ? 0 : monthLength - 1;
        } else {
          setMonth(dir ? 1 : 12);
          setYear((prev) => {
            return dir ? prev + 1 : prev - 1;
          });
          let months = getDaysInMonthWithDay(
            dir ? year + 1 : year - 1,
            dir ? 1 : 12
          );
          setDaysInTheMonth(months);

          if (!dir) {
            setStart(months.length - all);
            setEnd(months.length + 1);
          } else {
            setStart(0);
            setEnd(all);
          }
          return dir ? 0 : months.length - 1;
        }
      }
    });
  }

  // console.log('start-end-show: months : ',month,daysInTheMonth)
  return (
    <div className="calendar-page-picker relative center-hor">
      <span className="float-absolute-left center-hor justify-start btns">
        <IoIosArrowBack
          onClick={() => {
            SkipSelected(false);
          }}
          className="disabled icon grey large width-auto"
        />
      </span>
      {
        // Array.from(Array(17).keys()).map((each,index)=>{
        daysInTheMonth.map((each, index) => {
          return (
            <span
              key={index}
              hidden={!(index >= start && index <= end)}
              className={index === selectedDay ? "selected" : ""}
              onClick={() => {
                setSelectedDay(index);
                setSelected(each);
              }}
            >
              {each}
            </span>
          );
        })
      }
      <span className="float-absolute-right center-hor justify-end btns">
        <IoIosArrowForward
          onClick={() => {
            SkipSelected(true);
          }}
          className="disabled icon grey large width-auto"
        />
      </span>
    </div>
  );
}

export function RunsheetCards(props) {
  let themeColor = ThemeColor;

  let color = props.newSheet ? "#55b761" : themeColor[props.num];
  let newOrders = [];
  if (props.newSheet) {
    (props.allDetails || []).forEach((each) => {
      let prds = (
        typeof each.products === "string"
          ? JSON.parse(each.products || "[]")
          : each.products || []
      ).map((each2) => {
        return (
          each2.amount +
          " x " +
          (each2.product_type !== "Casket cover"
            ? each2.product_type
            : each2.code)
        );
      });
      newOrders = [...newOrders, ...prds];
    });
  }

  return (
    <div className="runsheet-card xlight-box-shadow">
      <div className="head font-medium relative" style={{ background: color }}>
        {props.details.drivers_name} | V No : {props.details.vehicle_number}
        <span className="float-right" style={{ width: "30px" }}>
          {(props.details || {}).id ? (
            <IconsMD.MdOutlineOpenInNew
              onClick={() => {
                window.open("/run-sheet/print/" + props.details.id, "_blank");
              }}
              className="icon white"
            />
          ) : (
            ""
          )}
        </span>
      </div>
      <div className="body width-100">
        <table
          className="table table-bordered table-striped"
          style={{ fontSize: "8px" }}
        >
          <tbody className="print-wo">
            <tr>
              <td style={{ padding: "4px" }}>Description</td>
              <td style={{ padding: "4px" }}>{props.details.description}</td>
            </tr>
            <tr>
              <td style={{ padding: "4px" }}>Comment</td>
              <td style={{ padding: "4px" }}>{props.details.comment}</td>
            </tr>
          </tbody>
        </table>

        <span
          className="font-small"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <WidgetTitle title="Products" size={"xsmall"} />
          {/* <span className="width-30 bold-1">Products</span> */}
          {(props.newSheet ? newOrders : (props.details || []).allShort || [])
            .filter((one) => {
              return (
                one.indexOf("Pedstals") === -1 && one.indexOf("Easels") === -1
              );
            })
            .map((each) => {
              return (
                <span
                  className=" product-tag "
                  style={{
                    marginBottom: "2px",
                    background: color,
                  }}
                >
                  {each}
                </span>
              );
            })}
          <br />
          <WidgetTitle title="Extras" size={"xsmall"} />
          {((props.details || []).allShort || [])
            .filter((one) => {
              return (
                one.indexOf("Pedstals") !== -1 || one.indexOf("Easels") !== -1
              );
            })
            .map((each) => {
              return (
                <span
                  className=" product-tag xlight-box-shadow extras"
                  style={{
                    marginBottom: "2px",
                    borderLeft: "5px solid " + color,
                    color: color,
                  }}
                >
                  {each}
                </span>
              );
            })}
        </span>
      </div>
    </div>
  );
}
